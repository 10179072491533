ceregoCommon.service("QuizNameService", [->
  # Type is one of the supported quiz types, multiple_choice and fill_in_the_blank ar ethe most common, but there is also sequence_injection and click_in_the_dark
  # Title is the words that go on top of the quiz modal for all V3 quiz modals
  # Name is the title of the quiz in V2
  # Question is the data type of the question that is shown
  # Distractor is the data type of the distractors in the modal
  # Answer is the data type of the answer
  # Ask is the field for the question
  service =
    quizzes:
      application_question_select:
        type: 'application_question_select'
        name: "js.item_editor.quizzes.application_question_select.name"
        title: "js.item_editor.quizzes.application_question_select.title"
      question_and_answer_select:
        type: 'question_and_answer_select'
        name: "js.item_editor.quizzes.application_question_select.name"
        title: "js.item_editor.quizzes.application_question_select.title"
      multiple_choice_limited:
        name: "js.item_editor.quizzes.multiple_choice_limited.name"
        title: "js.item_editor.quizzes.multiple_choice_limited.title"
        question: "anchor"
        distractor: "association"
        answer: "association"
        type: 'multiple_choice'
        ask: "anchor"
      region_multiple_choice_limited:
        name: "js.item_editor.quizzes.region_multiple_choice_limited.name"
        title: "js.item_editor.quizzes.region_multiple_choice_limited.title"
        question: "anchor"
        distractor: "association"
        answer: "association"
        type: 'multiple_choice'
        ask: "anchor"
      reverse_multiple_choice_limited:
        name: "js.item_editor.quizzes.reverse_multiple_choice_limited.name"
        title: "js.item_editor.quizzes.reverse_multiple_choice_limited.title"
        question: "association"
        distractor: "anchor"
        answer: "anchor"
        type: 'multiple_choice'
        ask: "association"
      region_reverse_multiple_choice:
        name: "js.item_editor.quizzes.region_reverse_multiple_choice.name"
        title: "js.item_editor.quizzes.region_reverse_multiple_choice.title"
        question: "association"
        distractor: "regions"
        answer: "anchor"
        type: 'multiple_choice'
        ask: "association"
      region_reverse_click_in_the_dark:
        name: "js.item_editor.quizzes.region_reverse_click_in_the_dark.name"
        title: "js.item_editor.quizzes.region_reverse_click_in_the_dark.title"
        question: "association"
        distractor: "none"
        answer: "anchor"
        type: 'click_in_the_dark'
        ask: "association"
      cloze_multiple_choice_limited:
        name: "js.item_editor.quizzes.cloze_multiple_choice_limited.name"
        title: "js.item_editor.quizzes.cloze_multiple_choice_limited.title"
        question: "anchor_cloze"
        distractor: "association"
        answer: "association"
        type: 'multiple_choice'
        ask: "anchor"
      cloze_fill_in_the_blank:
        name: "js.item_editor.quizzes.cloze_fill_in_the_blank.name"
        title: "js.item_editor.quizzes.cloze_fill_in_the_blank.title"
        question: "anchor_cloze"
        distractor: "fib"
        answer: "association"
        type: 'fill_in_the_blank'
        ask: "anchor"
      listening_multiple_choice_limited:
        name: "js.item_editor.quizzes.listening_multiple_choice_limited.name"
        title: "js.item_editor.quizzes.listening_multiple_choice_limited.title"
        question: "anchor_audio"
        distractor: "association"
        answer: "association"
        type: 'multiple_choice'
        ask: "anchor"
      region_fill_in_the_blank:
        name: "js.item_editor.quizzes.region_fill_in_the_blank.name"
        title: "js.item_editor.quizzes.region_fill_in_the_blank.title"
        question: "anchor"
        distractor: "fib"
        answer: "association"
        type: 'fill_in_the_blank'
        ask: "anchor"
      fill_in_the_blank:
        name: "js.item_editor.quizzes.fill_in_the_blank.name"
        title: "js.item_editor.quizzes.fill_in_the_blank.title"
        question: "anchor"
        distractor: "fib"
        answer: "association"
        type: 'fill_in_the_blank'
        ask: "anchor"
      reverse_fill_in_the_blank:
        name: "js.item_editor.quizzes.reverse_fill_in_the_blank.name"
        title: "js.item_editor.quizzes.reverse_fill_in_the_blank.title"
        question: "association"
        distractor: "fib"
        answer: "anchor"
        type: 'fill_in_the_blank'
        ask: "association"
      dictation_fill_in_the_blank:
        name: "js.item_editor.quizzes.dictation_fill_in_the_blank.name"
        title: "js.item_editor.quizzes.dictation_fill_in_the_blank.title"
        question: "sentence_audio"
        distractor: "fib"
        answer: "anchor"
        type: 'fill_in_the_blank'
        ask: "association"
      sentence_multiple_choice_limited:
        name: "js.item_editor.quizzes.sentence_multiple_choice_limited.name"
        title: "js.item_editor.quizzes.sentence_multiple_choice_limited.title"
        question: "sentence"
        distractor: "anchor"
        answer: "anchor"
        type: 'multiple_choice'
        ask: "association"
      transliteration_multiple_choice_limited:
        name: "js.item_editor.quizzes.transliteration_multiple_choice_limited.name"
        title: "js.item_editor.quizzes.transliteration_multiple_choice_limited.title"
        question: "anchor"
        distractor: "transliteration"
        answer: "transliteration"
        type: 'multiple_choice'
        ask: "anchor"
      reverse_transliteration_multiple_choice_limited:
        name: "js.item_editor.quizzes.reverse_transliteration_multiple_choice_limited.name"
        title: "js.item_editor.quizzes.reverse_transliteration_multiple_choice_limited.title"
        question: "transliteration"
        distractor: "anchor"
        answer: "anchor"
        type: 'multiple_choice'
        ask: "association"
      sentence_fill_in_the_blank:
        name: "js.item_editor.quizzes.sentence_fill_in_the_blank.name"
        title: "js.item_editor.quizzes.sentence_fill_in_the_blank.title"
        question: "sentence"
        distractor: "fib"
        answer: "anchor"
        type: 'fill_in_the_blank'
        ask: "association"
      transliteration_fill_in_the_blank:
        name: "js.item_editor.quizzes.transliteration_fill_in_the_blank.name"
        title: "js.item_editor.quizzes.transliteration_fill_in_the_blank.title"
        question: "anchor"
        distractor: "fib"
        answer: "transliteration"
        type: 'fill_in_the_blank'
        ask: "anchor"
      reverse_transliteration_fill_in_the_blank:
        name: "js.item_editor.quizzes.reverse_transliteration_fill_in_the_blank.name"
        title: "js.item_editor.quizzes.reverse_transliteration_fill_in_the_blank.title"
        question: "anchor"
        distractor: "fib"
        answer: "transliteration"
        type: 'fill_in_the_blank'
        ask: "anchor"
      sequence_injection:
        name: "js.item_editor.quizzes.sequence_injection.name"
        title: "js.item_editor.quizzes.sequence_injection.title"
        question: "association"
        distractor: "sequence"
        answer: "sequence_node"
        type: 'sequence_injection'
        ask: "association"

  service
])
