ceregoCommon.factory('Oauth2ClientsResource', ['$resource', ($resource) ->
  $resource "", {},
    query:
      method: 'GET'
      isArray: false
      url: APIRoutes.v3_partner_oauth2_clients_path(":partner_id")
      params:
        partner_id: "@partner_id"
    save:
      method: 'POST'
      isArray: false
      url: APIRoutes.v3_partner_oauth2_clients_path(":partner_id")
      params:
        partner_id: "@partner_id"
    delete:
      method: 'DELETE'
      isArray: false
      url: APIRoutes.v3_oauth2_client_path(":id")
      params:
        id: "@id"
])
