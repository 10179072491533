ceregoCommon.directive('cgModuleTiles', [->
  restrict: "E"

  scope:
    modules: "="
    defaultGoalImage: "@"
    defaultUserImage: "@"

  templateUrl: Packs.iKnowRoutes.v2_template_path("module_tiles")

  controller: ['$scope', '$filter', 'DebugHelper', 'SiteNavigationService', ($scope, $filter, DebugHelper, SiteNavigationService) ->
    DebugHelper.register("cgModuleTiles", $scope)

    _.assignIn $scope,
      parent: $scope.$parent
      routes: Packs.iKnowRoutes
      isCreateProduct: SiteNavigationService.isCreate()

      modulePath: (module) ->
        if $scope.isCreateProduct
          if module.module_type == "set"
            Packs.iKnowRoutes.v2_create_set_path(module.id)
          else
            Packs.iKnowRoutes.v2_create_series_path(module.id)
        else
          if module.module_type == "set"
            Packs.iKnowRoutes.v3_learn_set_path(module.id)
          else
            Packs.iKnowRoutes.v3_learn_series_path(module.id)

      parsedDescription: (module) ->
        if $filter('presence')(module.description_html)
          module.description_html
        else
          if module.module_type == 'set'
            "js.module_view.show_set.default_description"
          else
            "js.module_view.show_series.default_description"

      isCurrentSet: (set) ->
        $scope.currentSet == set && !_.isUndefined($scope.currentSet)

      selectSet: (set) ->
        $scope.currentSet = set

      creatorPath: (module) ->
        ""

      precomputeData: ->
        for module in $scope.modules
          _.assignIn module,
            modulePath:  $scope.modulePath(module)
            description: $scope.parsedDescription(module)
            creatorPath: $scope.creatorPath(module)

          if module.sets
            for set in module.sets
              _.assignIn set,
                modulePath:  $scope.modulePath(set)

    $scope.$watch "modules", ->
      if $scope.modules.length == 0
        $scope.selectSet(undefined)
      else
        $scope.precomputeData()
  ]
])
