ceregoCommon.factory('UsersResource', ['$resource', ($resource) ->
  $resource "", {},
    create:
      method: 'POST'
      isArray: false
      url: APIRoutes.v2_users_path()
    save:
      method: 'PUT'
      isArray: false
      url: APIRoutes.v2_user_path(":id")
      params:
        id: "@id"
])
