#Finds indexes for ui-sortable elements ignoring those that aren't yet saved
ceregoCommon.service("SortableIndexingService", ['$rootScope', 'DebugHelper', ($rootScope, DebugHelper) ->
  $scope = $rootScope.$new()

  DebugHelper.register("SortableIndexingService", $scope)

  _.assignIn $scope,
    #returns the index where the sortable should insert into
    #ignores all sortable items that have not been saved
    getSortablePosition: (sortables, startIndex, targetIndex) ->
      otherSortables = _.clone(sortables)
      #create duplicate array with sortables excluding the one being saved
      otherSortables.splice(startIndex, 1)
      #exclude everything after target position
      otherSortables = otherSortables.slice(0, targetIndex)
      #ignore sortables that have not been saved
      targetIndex - _.filter(otherSortables, (sortable) -> _.isUndefined(sortable.id)).length + 1

  $scope
])
