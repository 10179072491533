ceregoCommon.directive("learnButton", [ ->
  restrict: "E"
  scope:
    unstarted: "=?"
    fading: "=?"
    progress: "=?"
    buttonClassPreset: "=?"
    preloadRoute: "=?" # Immediately requests the corresponding study item so no wait is required when clicking the button
    sessionContextType: "=?"
    sessionContextId: "=?"
    scormPackageId: "=?"
    studyItem: "=?" # You can pass in existing study items if you already have one (used by smart learn card)
    returnUrl: "=?"
    studyType: "=" # 'recommended', 'fading_only', or 'force_review'
    buttonText: "=?" # Allows you to overwrite the learn button default text (Used for Smart Learn Card)
    analyticsEventName: "=?"
    embed: "="  # Allow SessionSettings value to presist in the learn app as you study. Good for now modal hard refresh the page when you continue.
    version: "=?"
    goalType: "=?"
    studiableItems: "=?"
    fromEmbed: "=?"

  templateUrl: Packs.iKnowRoutes.common_v3_templates_path("learn_button")

  controller: ['$scope', '$analytics', '$window', '$filter', '$q', '$route', 'DebugHelper', 'ModalService', 'ContextService', 'StudyItemsService', 'ScormPackagesResource', '$location', '$rootElement','setLearnURLService', ($scope, $analytics, $window, $filter, $q, $route, DebugHelper, ModalService, ContextService, StudyItemsService, ScormPackagesResource, $location, $rootElement, setLearnURLService) ->

    DebugHelper.register("learnButton", $scope)

    _.assignIn $scope,
      states:
        unstarted: 'unstarted'
        complete: 'complete'
        in_progress: 'in_progress'
        learn: 'learn'
        review: 'review'
        good_for_now: 'good_for_now'
      uiState:
        shouldDisplay: false
      requestNumber:0 # Used for preventing out of order http responses from affecting us.

      getLaunchLink: () ->
        if !$scope.scormPackageId # should never reach this
          DebugHelper.logError("Launch link request attempted for  " + $scope.sessionContextType + " " + $scope.sessionContextId + " without scorm package id")
          return
        ScormPackagesResource.get_launch_link
          id: $scope.scormPackageId
        , (success) ->
          $window.open(success.response, "_blank")
          $scope.$emit "scorm:launchAttempt"
        , (error) ->
          $scope.$emit "scorm:launchAttempt"
          DebugHelper.logError('Unexpected error ' + error + ' getting launch link')


      getStudySessionRoute: () ->
        $scope.requestNumber += 1
        closuredRequestNumber = $scope.requestNumber
        fromPath = setLearnURLService.getReturnUrl($window.location.pathname, { fromEmbed: $scope.fromEmbed })

        StudyItemsService.loadStudyItem($scope.sessionContextType, $scope.sessionContextId, $scope.studyType, fromPath).then (studyItem) ->
          if closuredRequestNumber == $scope.requestNumber # Don't let a stale response affect us
            $scope.studyItem = studyItem
            $scope.resetLearnPath()
            $scope.updateButton()

      launchStudySession: (event) ->
        event.preventDefault() if event && event.preventDefault
        return if ['assessment', 'survey'].includes($scope.goalType) && $scope.studyItem.state == 'complete'

        if $scope.shouldLaunchGoodForNowModal()
          return ModalService.showModal('good-for-now-modal', _.pick($scope, ['sessionContextType', 'sessionContextId', 'studyItem.learnPath', 'returnUrl', 'studyItem.version']))

        if $scope.analyticsEventName
          $analytics.eventTrack($scope.analyticsEventName)

        if $scope.scormPackageId
          $scope.getLaunchLink()
        else if !$scope.studyItem.learnPath # If this branch doesn't run then the href will take them to where they need to go
          $scope.$emit "preventDismissModal", true
          $scope.getStudySessionRoute().then ->
#            TODO: send tempLoginToken in LTI context
            $scope.launchLearnApp()
        else if $scope.studyItem.version == 4 && $scope.studyItem.learnPath
#            TODO: send tempLoginToken in LTI context
          $scope.launchLearnApp()
        else if $rootElement.attr('ng-app') == 'cerego.learnApp'
          # Use angular.element to parse the path & search out of the URL
          el = angular.element('a').attr("href", $scope.studyItem.learnPath)
          force_reload = el[0].pathname.match($location.path())
          # JS#search returns a string like "?foo=bar", but $location expects "foo=bar", slice out the ?
          $location.path(el[0].pathname).search(el[0].search.slice(1))
          $route.reload() if force_reload # Force a soft reload if we're navigating to the same assignment - ie review_anyways
        else
          $scope.$emit "preventDismissModal", true
          $scope.launchLearnApp()
          return

      launchLearnApp: () ->
        fromPath = setLearnURLService.getReturnUrl($window.location.pathname, { fromEmbed: $scope.fromEmbed })
        learnPath = new URL("#{$window.location.origin}#{$scope.studyItem.learnPath}")
        learnPath.searchParams.append('returnUrl', fromPath)
        $window.open(learnPath.toString(), '_top')
        return

      setStudyText: () ->
        $scope.studyText = if $scope.buttonText
                             $scope.buttonText
                           else if ['assessment', 'survey'].includes($scope.goalType)
                             $filter('translate')("js.learn.learn_button.#{$scope.goalType}.#{$scope.studyItem.state}")
                           else if $scope.scormPackageId
                             $filter('translate')("js.scorm.launch_cta")
                           else
                             $filter('translate')("js.learn.learn_button.#{$scope.studyItem.state}")

      setButtonClass: () ->
        $scope.buttonClass = if $scope.buttonClassPreset
                               $scope.buttonClassPreset
                             else if $scope.scormPackageId
                               'v3-secondary-button button-dark-blue'
                             else if [$scope.states.learn, $scope.states.unstarted].includes($scope.studyItem.state)
                               'v3-secondary-button'
                             else if [$scope.states.review, $scope.states.in_progress].includes($scope.studyItem.state)
                               'v3-secondary-button button-red'
                             else if ['assessment', 'survey'].includes($scope.goalType)
                               'v3-secondary-button button-gray disabled'
                             else  # good_for_now, complete
                               'v3-secondary-button button-gray'

      shouldLaunchGoodForNowModal: () ->
        !$scope.studyItem.skipModal &&
        !$scope.isAssessment &&
        !$scope.isSurvey &&
        !$scope.scormPackageId &&
        $scope.studyItem.unstarted == 0 &&
        $scope.studyItem.fading == 0 &&
        $scope.studyType != 'force_review' &&
        $scope.studyItem.version == 3

      ensureValidStudyItem: () ->
        $scope.studyItem = $scope.studyItem ? {}
        $scope.studyItem.unstarted = $scope.unstarted ? ($scope.studyItem?.unstarted ? 0)
        $scope.studyItem.fading = $scope.fading ? ($scope.studyItem?.fading ? 0)
        $scope.studyItem.version = $scope.version ? ($scope.version ? 3)
        $scope.studyItem.progress = $scope.progress ? ($scope.progress ? 0)

      setStudyItemState: () ->
        $scope.studyItem.state = if ['assessment', 'survey'].includes($scope.goalType)
                                   if $scope.studyItem.progress == 0
                                     $scope.states.unstarted
                                   else if $scope.studyItem.unstarted > 0
                                     $scope.states.in_progress
                                   else
                                     $scope.states.complete
                                 else
                                   if $scope.studyItem.unstarted > 0
                                     $scope.states.learn
                                   else if $scope.studyItem.unstarted == 0 and $scope.studyItem.fading > 0
                                     $scope.states.review
                                   else
                                     $scope.states.good_for_now

      updateButton: () ->
        $scope.setStudyItemState()
        $scope.setButtonClass()
        $scope.setStudyText()
        $scope.uiState.shouldDisplay = (!$scope.preloadRoute || $scope.studyItem.learnPath)

      isAssignmentContext: () ->
        $scope.sessionContextType == 'sets' || $scope.sessionContextType == 'series'

      canShortCircuitStudyItemsLoad: () -> # Don't need to load anything if it is assignment context and we know num unstarted and num fading
        $scope.isAssignmentContext() && $scope.unstarted? && $scope.fading?

      initialize: () ->
        $scope.ensureValidStudyItem()
        fromPath = setLearnURLService.getReturnUrl($window.location.pathname, { fromEmbed: $scope.fromEmbed })

        if $scope.canShortCircuitStudyItemsLoad()
          $scope.studyItem.learnPath = StudyItemsService.getAssignmentLearnPath($scope.sessionContextType, $scope.sessionContextId, $scope.studyType, fromPath, $scope.studyItem.version)
          $scope.resetLearnPath()

        else if $scope.preloadRoute
          $scope.getStudySessionRoute()

        $scope.updateButton()

      updateScormPackageId: () ->
        if $scope.studyItem && $scope.studyItem.scormPackageId
          $scope.scormPackageId = $scope.studyItem.scormPackageId

      resetLearnPath: () ->
        if ($scope.studyItem.version == 4)
          id = parseInt($scope.studyItem.primaryCollection?.id || $scope.sessionContextId)
          $scope.studyItem.learnPath = setLearnURLService.getUrl({id: id, version: 4}, { studyType: $scope.studyType })

        if $scope.embed
          $scope.studyItem.learnPath = $scope.studyItem.learnPath + "&embed=true"

    $scope.$watch "sessionContextId", ->
      $scope.initialize()

    $scope.$watch "studyItem", (oldVal, newVal) ->
      $scope.updateScormPackageId()
      $scope.resetLearnPath()
  ]
])
