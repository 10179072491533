ceregoCommon.service("CombiningMarksService", [->

  # Modified to account for Hebrew + Tibetan from: https://mathiasbynens.be/notes/javascript-unicode#accounting-for-lookalikes
  marks = "[\u0300-\u036F\u0591-\u05BD\u05BF-\u05C7\u0F70-\u0FBC\u1DC0-\u1DFF\u20D0-\u20FF\uFE20-\uFE2F]"

  service =

    splitIntoCombinedLetters: (text) ->
      letters = []
      reSymbolWithCombiningMarks = new RegExp("(.)(#{marks}*)", "g")

      text.replace(reSymbolWithCombiningMarks, ($0, symbol, combiningMarks) ->
        letters.push [symbol,combiningMarks].join("")
      )
      letters

    stripMarks: (text) ->
      re = new RegExp(marks, "g")
      text.replace re, ''

  service
])
