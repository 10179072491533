ceregoCommon.factory('SetCollaboratorsResource', ['$resource', ($resource) ->
  $resource "",{},
    query:
      method: 'GET'
      isArray: false
      url: APIRoutes.v2_set_collaborators_path(":set_id")
      params:
        set_id: "@set_id"
    delete:
      method: 'DELETE'
      isArray: false
      url: APIRoutes.v2_collaborator_path(":user_id")
      params:
        user_id: "@user_id"
    create:
      method: 'POST'
      isArray: false
      url: APIRoutes.v2_collaborators_path()
])
