ceregoCommon.directive('statsCountdown', [->
  restrict: "E"

  templateUrl: Packs.iKnowRoutes.v2_template_path("stats_countdown")
  scope:
    seeNext: "=?"

  controller: ['$scope', '$interval', 'DebugHelper', 'ContentMemoryManager', 'UserManager', 'MemoryStorage', ($scope, $interval, DebugHelper, ContentMemoryManager, UserManager, MemoryStorage) ->
    DebugHelper.register("statsCountdown", $scope)

    COUNTDOWN_TIMER_DELAY_MSEC = 500

    _.assignIn $scope, {
      hiddenStaticString: null

      countdownTimerDelay: ->
        COUNTDOWN_TIMER_DELAY_MSEC

      tick: ->
        if !$scope.seeNextAt
          console.error "Expected $scope.seeNextAt to be set by home or summary controller; instead undefined"
        else
          msec    = _.max([0, $scope.seeNextAt - (new Date())])
          seconds = Math.floor(msec  / 1000)
          minutes = Math.floor(seconds / 60)
          hours   = Math.floor(minutes / 60)
          days    = Math.floor(hours   / 24).toString()

          $scope.counter = $scope.counter || {}
          $scope.counter.seconds = ('0' + (seconds % 60)).slice(-2)
          $scope.counter.minutes = ('0' + (minutes % 60)).slice(-2)
          $scope.counter.hours   = ('0' + (hours % 24)).slice(-2)
          $scope.counter.days    = ('0' + days).slice(_.min([-2, 0 - days.length]))
          $scope.counter.intSeconds = parseInt($scope.counter.seconds)
          $scope.counter.intMinutes = parseInt($scope.counter.minutes)
          $scope.counter.intHours = parseInt($scope.counter.hours)
          $scope.counter.intDays = parseInt($scope.counter.days)

        if _.isNull($scope.hiddenStaticString)
          $scope.hiddenStaticString = $scope.staticStringCoundown()

      seconds: ->
        if $scope.counter
          $scope.counter.seconds
        else '00'

      minutes: ->
        if $scope.counter
          $scope.counter.minutes
        else '00'

      hours: ->
        if $scope.counter
          $scope.counter.hours
        else '00'


      days: ->
        if $scope.counter
          $scope.counter.days
        else '00'

      counterLessThanDay: ->
        $scope.counter && $scope.counter.days == '00'

      staticStringCoundown: ->
        return unless $scope.counter
        string = "Your next review is in "
        if ($scope.counter.intDays || $scope.counter.intHours || $scope.counter.intMinutes || $scope.counter.intSeconds)
          if $scope.counter.intDays > 0
            string = string + $scope.counter.intDays + " days "
          if $scope.counter.intHours > 0
            string = string + $scope.counter.intHours + " hours "
          if $scope.counter.intMinutes > 0
            string = string + $scope.counter.intMinutes + " minutes "
          if $scope.counter.intSeconds > 0
            string = string + $scope.counter.intSeconds + " seconds "
        else
          "Your next review is now"
    }

    if $scope.seeNext
      $scope.seeNextAt = new Date($scope.seeNext)
      $scope.interval = $interval $scope.tick, $scope.countdownTimerDelay()
    else
      UserManager.loadUser().then (user) ->
        ContentMemoryManager.load().then (response) ->
          memoryAggregate = response.memories[0]
          $scope.seeNextAt = new Date(memoryAggregate.see_next_at)
          $scope.interval = $interval $scope.tick, $scope.countdownTimerDelay()

    $scope.$on "$destroy", ->
      $interval.cancel($scope.interval)
  ]
])
