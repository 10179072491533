ceregoCommon.controller("videoUploaderModal", ['$scope', 'DebugHelper', ($scope, DebugHelper) ->
  DebugHelper.register("videoUploaderModal", $scope)
  _.assignIn $scope,
    activeModel: {}
    stateUI:
      processing: false
      populated: false
    uploadStatus:
      complete: 0
      total: 1
      percentage: 0
      active: false

    triggerUpload: ->
      if !$scope.stateUI.processing
        $scope.stateUI.processing = true
        $scope.$broadcast 'videoUploaderModal:uploadMedia'

    insertVideo: ->
      _.assign($scope.model, $scope.activeModel)
      $scope.stateUI.processing = false
      $scope.closeModal()
    
    showProgress: (e, uploaderStatus) ->
      _.assign($scope.uploadStatus, uploaderStatus)

  $scope.model = $scope.modalOptions.model
  $scope.activeModel = _.cloneDeep($scope.model) #TODO: refactor model handling.

  $scope.$watch "activeModel.data", (newVal, oldVal) ->
    if newVal != oldVal
      $scope.stateUI.populated = true

  $scope.$on 'videoUploader:uploadComplete', $scope.insertVideo
  $scope.$on 'videoUploader:uploadProgress', $scope.showProgress
])
