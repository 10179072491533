ceregoCommon.directive("clickToEdit", [->
  restrict: "E"
  templateUrl: Packs.iKnowRoutes.common_v3_templates_path("_click_to_edit")

  scope:
    value: "=?"
    placeholderText: "@"
    saveFunction: "&?"
    bindAsHtml: "=?"
    editorHelpTemplate: "=?"
    startEditingFunction: "=?"
    finishEditingFunction: "=?"
    ellipsisLength: "=?"

  controller: ['$scope', '$timeout', '$element', 'DebugHelper', '$filter', ($scope, $timeout, $element, DebugHelper, $filter) ->
    DebugHelper.register("clickToEdit", $scope)

    _.assignIn $scope,
      stateUI:
        editorEnabled: false

      startEditing: (event) ->
        if $scope.startEditingFunction
          $scope.value = $scope.startEditingFunction($scope.value)
        $scope.stateUI.editorEnabled = true
        $timeout () ->
          $element.children("textarea")[0].focus()
        event.preventDefault()
        event.stopPropagation()

      finishEditing: () ->
        if $scope.finishEditingFunction
          $scope.value = $scope.finishEditingFunction($scope.value)
        $scope.stateUI.editorEnabled = false
        if $scope.saveFunction
          $scope.saveFunction(updatedValue: $scope.value)

      setDisplayText: () ->
        if $scope.ellipsisLength
          $scope.displayText = ($filter('ellipsis')($scope.value, $scope.ellipsisLength))
        else
          $scope.displayText = $scope.value

  ]

  link: ($scope, element, attrs) ->
    $scope.setDisplayText()

    $scope.$watch "value", (newVal, oldVal) ->
      if newVal != oldVal
        $scope.setDisplayText()

    element.on 'blur', 'textarea', (event) ->
      $scope.finishEditing()
])
