ceregoCommon.directive("cgSearchText", [->
  restrict: "E"
  templateUrl: Packs.iKnowRoutes.sets_v2_templates_path("module_search")
  transclude: true
  scope:
    callback: '@'
    searchText: '='

  controller: ['$scope', '$timeout', 'DebugHelper', ($scope, $timeout, DebugHelper) ->
    DebugHelper.register("cgSearchText", $scope)

    _.assignIn $scope, {
      callCallBack: ->
        $timeout ->
          $scope.$parent.$eval($scope.callback)
    }
  ]
])
