import * as angular from 'angular';
import * as _ from 'lodash';

export interface IFilterCore extends ng.IFilterService {
  (name: 'transcode'): (url: string, size: string) => string;
  (name: 'randomTranslateStringArray'): (key: string) => string;
}

angular.module('cerego.core').filter('transcode', [
  '$window',
  ($window: ng.IWindowService) => (image, size) => {
    return $window.getTranscodedURL(image, size);
  }
]);

angular
  .module('cerego.core')
  .filter('randomTranslateStringArray', [
    'translateEn',
    translateEn => key => key + '.' + _.random(0, _.get(translateEn.default.en, key).length - 1)
  ]);

angular.module('cerego.core').filter('jsonStringify', [() => object => JSON.stringify(object)]);
