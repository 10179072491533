/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
ceregoCommon.controller('addToSeriesModal', ['$scope', 'DebugHelper', 'myPartnersService', 'MySeriesResource', 'PartnersResource', function($scope, DebugHelper, myPartnersService, MySeriesResource, PartnersResource) {
  DebugHelper.register("addToSeriesModal", $scope);

  _.assignIn($scope, {
    seriesName: "",
    routes: Packs.iKnowRoutes,

    fetchMySeries() {
      return myPartnersService.initialize().then(function() {
        $scope.partner = myPartnersService.currentProductPartner;
        if ($scope.partner) {
          return PartnersResource.series({ partner_id: $scope.partner.id }
          , success => $scope.myseries = success.response.series);
        } else {
          return MySeriesResource.query({ }
          , success => $scope.myseries = success.response.series);
        }
      });
    },

    chooseSeries(e, module) {
      e.preventDefault();

      const resource = $scope.setAlreadyInSeries(module) ? "removeSet" : "addSet";

      return MySeriesResource[resource]({
        id: module.id,
        set_id: $scope.set.id
      }
      , success => module.sets = success.response.sets);
    },

    setAlreadyInSeries(series) {
      return _.indexOf(_.map(series.sets, 'id'), $scope.set.id) > -1;
    },

    initialize() {
      $scope.set = $scope.modalOptions.module;
      return $scope.fetchMySeries();
    }
  });

  return $scope.initialize();
}

]);
