import * as angular from 'angular';
import * as angulartics from 'angulartics';

import { IDebugHelper } from 'v2/modules/core/debug-helper';

export interface IModalService extends ng.IScope {
  modalActive: boolean;
  modalOptions: object[];
  showModal: (name: string, options: object) => boolean;
  cleanupModal: () => void;
}

angular.module('cerego.core').service('ModalService', [
  '$rootScope',
  '$analytics',
  '$compile',
  'DebugHelper',
  'hotkeys',
  (
    $rootScope: ng.IRootScopeService,
    $analytics: angulartics.IAnalyticsService,
    $compile: ng.ICompileService,
    DebugHelper: IDebugHelper,
    hotkeys
  ) => {
    const $scope = $rootScope.$new() as IModalService;
    DebugHelper.register('ModalService', $scope);

    $scope.showModal = (name, options) => {
      $analytics.eventTrack(name, { category: 'modal_shown' });
      // ModalService is a singleton, but it needs to be able to handle multiple modals without overwriting the attributes for previous modals
      if (!$scope.modalOptions) {
        $scope.modalOptions = [];
      }
      $scope.modalOptions.push(options);
      const indexToDisplay = $scope.modalOptions.length - 1;
      const modal = angular
        .element(document.createElement('v3-modal'))
        .attr('modal-name', name)
        .attr('modal-options', `modalOptions[${indexToDisplay}]`);
      const el = $compile(modal)($scope);
      angular.element(document.body).append(el);
      $scope.modalActive = true;
      hotkeys.pause();
      return false;
    };

    $scope.cleanupModal = () => {
      $scope.modalActive = false;
      hotkeys.unpause();
    };

    return $scope;
  }
]);
