import * as angular from 'angular';
import * as _ from 'lodash';
import { IDebugHelper } from '../../../../v2/modules/core/debug-helper';

export interface IAmplitudeService extends ng.IScope {
  trackPage: (pageName: string) => void;
  logEvent: (eventName: string, data: any) => void;
  identify: (identity: any) => void;
  setUserId: (id: number) => void;
  regenerateDeviceId: () => void;
}

angular.module('cerego.common').service('AmplitudeService', [
  '$rootScope',
  'DebugHelper',
  ($rootScope: ng.IRootScopeService, DebugHelper: IDebugHelper) => {
    const $scope = $rootScope.$new() as IAmplitudeService;

    DebugHelper.register('AmplitudeService', $scope);

    $scope.logEvent = (eventName, data) => {
      // don't track url loading in development so we don't spam amplitude each time the file is saved
      if (!_.includes(process.env.RAILS_ENV, 'govcloud')) {
        amplitude.getInstance().logEvent(eventName, data);
      }
    };

    $scope.identify = identity => {
      if (!_.includes(process.env.RAILS_ENV, 'govcloud')) {
        amplitude.getInstance()?.identify(identity);
      }
    };

    $scope.setUserId = id => {
      if (!_.includes(process.env.RAILS_ENV, 'govcloud')) {
        amplitude.getInstance().setUserId(id);
      }
    };

    $scope.regenerateDeviceId = () => {
      if (!_.includes(process.env.RAILS_ENV, 'govcloud')) {
        amplitude.getInstance().regenerateDeviceId();
      }
    };

    return $scope;
  }
]);
