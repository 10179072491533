import * as angular from 'angular';
import { IDebugHelper } from 'v2/modules/core/debug-helper';

interface IModule {
  id: number;
  version: number;
}

export interface ISetLearnURLService extends ng.IScope {
  getReturnUrl: (path: string, params) => string;
  getUrl: (Module, params) => string;
}

export interface IStudyPathParams {
  studyType: 'force_review' | 'recommended' | 'fading_only' | 'fading';
  preview?: boolean;
  debug?: boolean;
  returnURL?: string;
}

angular.module('cerego.common').service('setLearnURLService', [
  '$rootScope',
  'DebugHelper',
  'SiteNavigationService',
  ($rootScope: ng.IRootScopeService, DebugHelper: IDebugHelper, SiteNavigationService) => {
    const $scope = $rootScope.$new() as ISetLearnURLService;

    DebugHelper.register('setLearnURLService', $scope);

    $scope.getReturnUrl = (path, options) => {
      let returnUrl = path;
      if (options.fromEmbed) {
        returnUrl += '?embed=true';
      }
      if (SiteNavigationService.isV4Embed()) {
        returnUrl = `/app/nav${returnUrl}`;
      }
      return returnUrl;
    };

    $scope.getUrl = (Module: IModule, params: IStudyPathParams) => {
      if (Module.version === 4) {
        if (params.preview) {
          return Packs.iKnowRoutes.v3_react_learn_set_path(Module.id, params);
        } else {
          delete params.studyType;
          return Packs.iKnowRoutes.v3_react_user_journey_path(Module.id, params);
        }
      } else {
        return Packs.iKnowRoutes.quiz_v2_set_learn_path(Module.id, params);
      }
    };

    return $scope;
  }
]);
