import * as angular from 'angular';

export interface IConversionAnalyticsService {
  trackConversion: (name: string) => void;
}

interface IConversionAnalyticsWindow extends ng.IWindowService {
  ceregoTrackConversion?: (name: string) => void;
}

angular.module('cerego.core').service('ConversionAnalyticsService', [
  '$window',
  ($window: IConversionAnalyticsWindow) => {
    const service: IConversionAnalyticsService = {
      trackConversion(name) {
        if ($window.ceregoTrackConversion) {
          $window.ceregoTrackConversion(name);
        }
      }
    };

    return service;
  }
]);
