ceregoCommon.factory('V3SetClonesResource', ['$resource', ($resource) ->
  $resource "", {},
    create:
      method: 'POST'
      isArray: false
      url: APIRoutes.v3_set_set_clones_path(":set_id")
      params:
        set_id: "@set_id"
    get:
      method: 'GET'
      isArray: false
      url: APIRoutes.v3_set_clone_path(":id")
      params:
        id: "@id"

])
