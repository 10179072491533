ceregoCommon.factory('FlickrResource', ['$resource', ($resource) ->
  $resource "",
    api_key: "2762233cd33035b0c687f0bee06b92b0"
    format: "json"
  ,
    search:
      method: 'JSONP'
      isArray: false
      url: "https://api.flickr.com/services/rest/"
      params:
        method: "flickr.photos.search"
        sort: "relevance"
        extras: "owner_name,url_m,url_q,license"
        license: "1,2,3,4,5,6,7,8"
        jsoncallback: "JSON_CALLBACK"
])
