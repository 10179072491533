ceregoCommon.directive('v3VideoBox', [ ->
  restrict: "E"
  scope:
    model: "="
    noteId: "=?"

  templateUrl: (element, $scope) ->
    Packs.iKnowRoutes.common_v3_templates_path("video_box")

  controller: ['$scope', 'DebugHelper', 'ModalService', ($scope, DebugHelper, ModalService) ->
    DebugHelper.register("v3VideoBox", $scope)

    _.assignIn $scope,
      launchModal: () ->
        ModalService.showModal('video-uploader-modal', { model: $scope.model, description: 'Upload your video below.' })

      resetModel: () ->
        $scope.model = {}

    $scope.$on "launchNoteEditModal", (event, opt) ->
      $scope.launchModal() if opt.id == $scope.noteId
  ]
])
