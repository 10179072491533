import { Animator } from '../../../vendor/assets/javascripts/soundmanager2/script/berniecode-animator';
window.Animator = Animator; // This is used in 360player.cerego for some reason???
require('../../../vendor/assets/javascripts/soundmanager2/script/360player.cerego');

// This is the worst. When 360player plays, it needs to know about the state of sound_manager
// so it can pause the sound
// This is more worse than previously imagined
window.isSoundPlaying = false;

(function($) {
  var DEBUG_SOUND = false;

  var sounds = {};
  var currentVolume;
  var currentEffectVolume;

  $.playSoundId = function(id, options) {
    if (DEBUG_SOUND) {
      console.log('$.playSoundId', id);
    }

    soundManager.play(id);
  };

  ($.playVisibleSoundInPlayer = function(url, options) {
    options = options || {};
    var callback = options.finished || function() {};

    if (!url) {
      callback(url, false);
      return;
    }

    var soundNode = _.find($('a', '.ui360:visible'), function(element) {
      return element.href == url || element.href == window.location.protocol + url;
    });

    if (!soundNode) {
      return;
    }

    soundNode.click(); // bind another event in 360player instead of triggering click

    $(document).on('360PlayerFinished', function() {
      callback(url, true);
    });
  }),
    ($.playSound = function(url, options) {
      if (DEBUG_SOUND) {
        console.log('$.playSound', url);
      }

      options = options || {};
      var callback = options.finished || function() {};

      if (!url) {
        callback(url, false);
        return;
      }

      var mySound = soundManager.createSound({
        url: url,
        volume: currentVolume,
        onfinish: function() {
          window.isSoundPlaying = false;
          callback(url, true);
        }
      });

      if (!mySound) {
        callback(url, false);
        return;
      }

      window.isSoundPlaying = true;
      mySound.play(options);
    });

  $.stopSounds = function() {
    if (DEBUG_SOUND) {
      console.log('$.stopSounds');
    }

    _.each(threeSixtyPlayer.sounds, function(sound) {
      threeSixtyPlayer.stopSound(sound);
      threeSixtyPlayer.fanIn(sound);
    });

    var oSound;
    for (oSound in soundManager.sounds) {
      var sound = soundManager.sounds[oSound];
      sound.stop();
      sound.unload();
    }
  };

  $.pauseSound = function(sound) {
    if (DEBUG_SOUND) {
      console.log('$.pauseSound', sound.url);
    }

    if (sound) {
      sound.pause();
    }
  };

  $.resumeSound = function(sound) {
    if (DEBUG_SOUND) {
      console.log('$.resumeSound', url);
    }

    if (sound) {
      sound.resume();
    }
  };

  $.loadSound = function(url, options) {
    if (DEBUG_SOUND) {
      console.log('$.loadSound', url);
    }

    options = options || {};

    if (!url) {
      return;
    }

    var loadedCallback = options.loaded || function() {};

    var sound = soundManager.createSound({
      url: url
    });

    if (!sound) {
      loadedCallback(url, false);
    } else {
      loadedCallback(url, true);
    }
  };

  $.loadSounds = function(urls, callback, completedCallback) {
    if (DEBUG_SOUND) {
      console.log('$.loadSounds', urls);
    }

    var loadedURLs = [];
    var loadCompleted = function(url, success) {
      loadedURLs.push(url);
      callback.call(this, url, success);
      if (loadedURLs.length >= urls.length) {
        // Note that when urls are cached the callback will be synchronous,
        // so you need to clear the global callback FIRST, or risk getting caught in an infinite loop.
        completedCallback.call(this, loadedURLs);
      }
    };
    for (var i = 0; i < urls.length; i++) {
      var url = urls[i];
      $.loadSound(url, { loaded: loadCompleted });
    }
  };

  $.setVolume = function(effectVolume) {
    soundManager.setVolume(effectVolume);
  };

  /////////////
  // Private //
  /////////////

  function initialize() {
    if (DEBUG_SOUND) {
      window.DEBUG = {};
      window.DEBUG.sounds = sounds;
      console.log('Sound manager initialized');
    }

    soundManager.setup({
      url: 'soundmanager2/swf/soundmanager2',
      flashVersion: 9
    });
  }

  $(document).ready(initialize);
})(jQuery);
