ceregoCommon.factory("NpsService", ['$rootScope', '$routeParams', '$q', '$window', 'DebugHelper', 'UserManager', ($rootScope, $routeParams, $q, $window, DebugHelper, UserManager) ->
  $scope = $rootScope.$new()

  DebugHelper.register("NpsService", $scope)

  _.assignIn $scope,
    displayNPS: (user) ->
      segmentation = UserManager.getSegmentation(user)

      delighted.survey(
        email: user.attributes["email"], # this value determines whether or not the user has received a survey or not
        name: user.attributes["name"],
        createdAt: user.attributes["created-at"],  # this value will be considered when showing the initial survey.
        initialDelay: 259200, # 3 days
        properties:
          user_id: user.id,
          locale: user.attributes["locale"]
          stage: segmentation.stage || "unknown"
          is_paid: segmentation.is_paid == null ? "unknown" : segmentation.is_paid
          segment: segmentation.segment || "unknown"
          vertical: segmentation.vertical || "unknown"
          is_mooc: segmentation.is_mooc == null ? "unknown" : segmentation.is_mooc
          is_courseware: segmentation.is_courseware == null ? "unknown" : segmentation.is_courseware
          size: segmentation.size || "unknown"
          learn_version: segmentation.learn_version || "unknown"
          role: UserManager.getRole(user)
          platform: "web"
      )

  $scope
])
