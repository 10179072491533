import * as angular from 'angular';
import UserCookie from 'javascripts/v2/modules/core/user-cookie-helper';
import * as _ from 'lodash';
import 'ng-idle';
import { IDebugHelper } from 'v2/modules/core/debug-helper';
import { IModalService } from 'v2/modules/core/modal-service';

const SESSION_REFRESH_LENGTH = 30 * 60; // 30 minutes - make sure this matches SESSION_REFRESH_LENGTH in the backend
const IDLE_START = SESSION_REFRESH_LENGTH / 2; // Start tracking when within 15 minutes of expiry
const KEEPALIVE_INTERVAL = 10 * 60; // As long as user is active, refresh session every 10 minutes
const IDLE_WARN = 60; // Show modal when within 1 minute of expiry - make sure this matches SECONDS_TO_COUNTDOWN in the modal

/*
 * The flow for these events is a bit confusing, but looks something like this:
 * wait until within 30 minutes of expiry -> watch() -> wait 15 minutes (Idle.idle) -> IdleStart ->
 * if active -> Keepalive & start over by calling watch()
 * if inactive -> wait 14 minutes (Idle.timeout) -> IdleTimeout -> showModal
 * if modal is dismissed -> Keepalive & start over by calling watch()
 * if modal is ignored -> logout since session has expired
 */

angular
  .module('cerego.timeout', ['cerego.core', 'ngIdle'])
  .config([
    'IdleProvider',
    'KeepaliveProvider',
    'TitleProvider',
    (
      IdleProvider: ng.idle.IIdleProvider,
      KeepaliveProvider: ng.idle.IKeepAliveProvider,
      TitleProvider: ng.idle.ITitleProvider
    ) => {
      // in seconds, send Keepalive if user is active, otherwise IdleStart event
      IdleProvider.idle(IDLE_START);
      // in seconds, send IdleTimout if still inactive after this long
      IdleProvider.timeout(SESSION_REFRESH_LENGTH - IDLE_START - IDLE_WARN);
      // in seconds, how frequently to trigger Keepalive while idling if activity present
      KeepaliveProvider.interval(KEEPALIVE_INTERVAL);
      // Disable the tab title from idle provider
      TitleProvider.enabled(false);
    }
  ])
  .run([
    '$cookies',
    '$timeout',
    '$rootScope',
    'DebugHelper',
    'Idle',
    'Keepalive',
    'ModalService',
    'V3SessionResource',
    (
      $cookies: ng.cookies.ICookiesService,
      $timeout: ng.ITimeoutService,
      $rootScope: ng.IRootScopeService,
      DebugHelper: IDebugHelper,
      Idle: ng.idle.IIdleService,
      Keepalive: ng.idle.IKeepAliveService,
      ModalService: IModalService,
      V3SessionResource: v3.api.IResource<v3.resource.ISession>
    ) => {
      $rootScope.$on('IdleTimeout', () => {
        ModalService.showModal('timeout-warning-modal', { noCloseButton: true });
      });

      $rootScope.$on('Keepalive', () => {
        V3SessionResource.update(
          {},
          success => {
            if (!Idle.running()) {
              Idle.watch();
            }
          },
          DebugHelper.logError
        );
      });

      const cookie = new UserCookie($cookies);

      if (cookie.sessionExpiresAt) {
        const delayMsec = cookie.sessionExpiresAt.getTime() - new Date().getTime() - SESSION_REFRESH_LENGTH * 1000;
        if (delayMsec < 0) {
          // We are already within SESSION_REFRESH_LENGTH, so go ahead and trigger an immediate refresh
          Idle.interrupt();
          Keepalive.ping();
        } else {
          // Delay idle logic until we are within SESSION_REFRESH_LENGTH of session expiry
          // This makes sure none of this garbage even runs 99% of the time
          $timeout(Idle.watch, delayMsec);
        }
      }
    }
  ]);
