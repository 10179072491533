ceregoCommon.directive('cgMediaChange', [->
  link: ($scope, element, attrs) ->
    element.bind "change", (event) ->
      if element[0].files.length > 0
        $scope.$eval(attrs.cgMediaChange, { $file: element[0].files[0], $event: event })
])

ceregoCommon.service('V3MediaUploader', ['$http', ($http) ->
  this.upload = (data, media_type, progressHandler, options = {}) ->
    if media_type == "image"
      url = APIRoutes.v3_images_path()
    else if media_type == "pdf"
      url = APIRoutes.v3_documents_path()
    else if media_type == "user_csv"
      url = APIRoutes.v3_partner_user_csv_uploads_path(options.partner_id)
    else if media_type == "scorm_zip"
      url = APIRoutes.v3_scorm_packages_path()
    else if media_type == "video"
      url = APIRoutes.v3_videos_path()
    else
      url = APIRoutes.v3_sounds_path()

    if data.file
      $http(
        method: 'POST'
        url: url
        headers:
          'Content-Type': undefined
        data: data
        transformRequest: (data, headersGetter, status) ->
          fd = new FormData()
          for k,v of data
            fd.append(k, v)
          fd
        cache: false
        uploadEventHandlers:
          progress: progressHandler
      )
    else
      $http(
        method: 'POST'
        url: url
        headers:
          'Content-Type': 'application/json'
        data: data
        cache: false
        eventHandlers:
          progress: progressHandler
      )
])

ceregoCommon.service('MediaUploader', ['$http', '$cookies', ($http, $cookies) ->
  this.upload = (data, media_type, progressHandler, fileName) ->
    fd = new FormData()
    for key, file of data
      if fileName || file.name
        fd.append(key, file, fileName || file.name)
      else
        fd.append(key, file)

    if media_type == "image"
      url = APIRoutes.v2_images_path()
    else if media_type == "pdf"
      url = APIRoutes.v3_documents_path()
    else
      url = APIRoutes.v2_sounds_path()

    headers = {
      'X-CSRF-Token': $cookies.get('csrf_token')
    }
    if CeregoAPI.token
      headers['Authorization'] = "Bearer " + CeregoAPI.token

    # TODO: get this working with $http instead of jquery
    $.ajax
      url: url
      method: "POST"
      data: fd
      dataType: "JSON"
      headers: headers
      xhr: ->
        myXhr = $.ajaxSettings.xhr()
        if myXhr.upload && progressHandler
          myXhr.upload.addEventListener "progress", progressHandler
        myXhr
      cache: false
      contentType: false
      processData: false
])
