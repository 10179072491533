ceregoCommon.factory('V3CourseUsersResource', ['$resource', ($resource) ->
  $resource "", {},
    get:
      method: 'GET'
      isArray: false
      url: APIRoutes.v3_course_users_path(":course_id")
      params:
        course_id: "@course_id"
    create:
      method: 'POST'
      isArray: false
      url: APIRoutes.v3_course_users_path(":course_id")
      params:
        course_id: "@course_id"
    show:
      method: 'GET'
      isArray: false
      url: APIRoutes.v3_course_user_path(":course_id", ":id")
      params:
        course_id: "@course_id"
        id: "@id"
    update:
      method: 'PATCH'
      isArray: false
      url: APIRoutes.v3_course_user_path(":course_id", ":id")
      params:
        course_id: "@course_id"
        id: "@id"
    delete:
      method: 'DELETE'
      isArray: false
      url: APIRoutes.v3_course_user_path(":course_id", ":id")
      params:
        course_id: "@course_id"
        id: "@id"
    bulk_destroy_instructors:
      method: 'DELETE'
      isArray: false
      url: APIRoutes.instructors_v3_course_users_path(":course_id")
      params:
        course_id: "@course_id"
    bulk_destroy_students:
      method: 'DELETE'
      isArray: false
      url: APIRoutes.students_v3_course_users_path(":course_id")
      params:
        course_id: "@course_id"
])
