ceregoCommon.factory('TipsResource', ['$resource', ($resource) ->
  $resource "",{},
    index:
      method: 'GET'
      isArray: false
      url: APIRoutes.v3_my_tips_path()
    update:
      method: 'PUT'
      isArray: false
      url: APIRoutes.v3_my_tip_path(":tip_id")
      params:
        tip_id: "@tip_id"
])
