ceregoCommon.directive("v3Modal", ['$compile', '$timeout', '$rootScope', 'LtiService', ($compile, $timeout, $rootScope, LtiService) ->
  restrict: "E"
  templateUrl: Packs.iKnowRoutes.common_v3_templates_path("v3_modal")

  scope:
    modalName: "@?"
    modalOptions: "=?"

  controller: ['$scope', 'ModalService', 'DebugHelper', 'LtiService', ($scope, ModalService, DebugHelper, LtiService) ->
    DebugHelper.register("V3Modal", $scope)

    _.assignIn $scope,
      LtiService: LtiService
      uiState: {}

      modalTypes:
        'transparent':
          class: 'v3-transparent-layout'
        'default':
          class: 'v3-body-layout'

      closeModal: () ->
        if ModalService.modalOptions?.length > 0
          options = ModalService.modalOptions.pop()
          if options.closeCallback
            options.closeCallback()
        ModalService.cleanupModal()
        $scope.$destroy()
        $scope.element.remove()
        $scope.focusedElementBeforeOpen.focus() if $scope.focusedElementBeforeOpen && $scope.focusedElementBeforeOpen.focus
        false

      isDefault: ->
        $scope.modalOptions?.modalType == 'default' || !$scope.modalOptions?.modalType # If no modal type then use the default modal

      isTransparent: ->
        $scope.modalOptions?.modalType == 'transparent'

      focusableElementList: () ->
        $scope.element.find('input:not([disabled]), a[href], area[href], select:not([disabled]), textarea:not([disabled]), button:not([disabled]), [tabindex="0"]')

      handleBackwardTab: (e) ->
        if $scope.tabableElements && (document.activeElement == $scope.tabableElements.first()[0])
          $scope.tabableElements.last()[0].focus()
          event.preventDefault(e)

      handleForwardTab: (e) ->
        if $scope.tabableElements && (document.activeElement == $scope.tabableElements.last()[0])
          $scope.tabableElements.first()[0].focus()
          event.preventDefault(e)

      handleTabbing: (event) ->
        $scope.tabableElements = $scope.focusableElementList()
        if event.shiftKey
          $scope.handleBackwardTab(event)
        else
          $scope.handleForwardTab(event)

    $timeout () ->
      $scope.element.focus()

    $scope.modalTemplatePath = Packs.iKnowRoutes.common_v3_templates_path(_.snakeCase($scope.modalName))

    $scope.$on "preventDismissModal", (e, flag) ->
      $scope.preventDismissModal = flag

    $scope.$on "allowRouteChangeFromModal", (e, flag) ->
      $scope.allowRouteChangeFromModal = flag

    globalListener = $rootScope.$on '$routeChangeStart', ($event, current, previous) ->
      if !$scope.preventDismissModal
        $scope.closeModal()
      if !$scope.allowRouteChangeFromModal
        $event.preventDefault()

    $scope.$on "$destroy", ->
      globalListener()
  ]

  link: ($scope, element, attrs) ->
    $scope.uiState.isBBC = LtiService.isBBC
    $scope.element = element
    $scope.focusedElementBeforeOpen = document.activeElement

    dialogDescription = $scope.modalOptions?.description || ""
    $scope.element.attr("tabindex", "0").attr("role","dialog").attr("aria-label", "Dialog opened." + dialogDescription)

    # Gotta timeout so template is loaded before controller
    $timeout () ->
      el = element.find(".layout-container").
        attr("ng-include", "modalTemplatePath")

      unless $scope.modalOptions?.excludeController
        el.attr("ng-controller", _.camelCase($scope.modalName))

      $compile(el)($scope)

    modalClass = $scope.modalTypes[$scope.modalOptions?.modalType || 'default'].class
    element.addClass(modalClass)

    angular.element("body").keydown (e) ->
      # escape key
      if e.keyCode == 27 && !$scope.modalOptions?.noCloseButton
        $scope.closeModal()
      else if e.keyCode == 9
        $scope.handleTabbing(e)

])
