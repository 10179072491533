/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
ceregoCommon.controller('timeoutWarningModal', [
  '$scope',
  '$interval',
  '$timeout',
  '$window',
  'DebugHelper',
  'Keepalive',
  'V3SessionResource',
  'LtiService',
  function ($scope, $interval, $timeout, $window, DebugHelper, Keepalive, V3SessionResource, LtiService) {
    DebugHelper.register('timeoutWarningModal', $scope);

    const SECONDS_TO_COUNTDOWN = 60;
    const SECOND_TO_MS = 1000;
    const TIMEOUT_MSEC = SECONDS_TO_COUNTDOWN * SECOND_TO_MS; // 60 seconds

    _.assignIn($scope, {
      stateUI: {
        count: SECONDS_TO_COUNTDOWN,
      },

      countDown() {
        if ($scope.stateUI.count < 1) {
          return;
        }
        return ($scope.stateUI.count = $scope.stateUI.count - 1);
      },

      startTimeOut() {
        $interval($scope.countDown, SECOND_TO_MS);
        return ($scope.timer = $timeout(() => $scope.signOutUser(), TIMEOUT_MSEC));
      },

      signOutUser() {
        return V3SessionResource.destroy(
          {},
          function (success) {
            if (LtiService.ltiClientId) {
              return ($window.location.href = Packs.iKnowRoutes.lti_inactivity_logout_v3_static_path());
            } else {
              return ($window.location.href = Packs.iKnowRoutes.v3_signin_path());
            }
          },

          (failure) => console.error(failure)
        );
      },

      continueSession() {
        Keepalive.ping();
        $timeout.cancel($scope.timer);
        return $scope.closeModal();
      },
    });

    return $scope.startTimeOut();
  },
]);
