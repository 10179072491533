angular.module("cerego.translate").constant "translateD3",
  fr:
    decimal: ","
    thousands: "."
    grouping: [ 3 ]
    currency: [ "$", "" ]
    dateTime: "%a %b %e %X %Y"
    date: "%d/%m/%Y"
    time: "%H:%M:%S"
    periods: [ "mat.", "ap.m." ]
    days: [ "dimanche", "lundi", "mardi", "mercredi", "jeudi", "vendredi", "samedi" ]
    shortDays: [ "dim", "lun", "mar", "mer", "jeu", "ven", "sam" ]
    months: [ "janvier", "février", "mars", "avril", "mai", "juin", "juillet", "août", "septembre", "octobre", "novembre", "décembre" ]
    shortMonths: [ "janv", "févr", "mars", "avr", "mai", "juin", "juil", "août", "sept", "oct", "nov", "déc" ]
  ar:
    decimal: "."
    thousands: ","
    grouping: [ 3 ]
    currency: [ "$", "" ]
    dateTime: "%a %b %e %X %Y"
    date: "%d/%m/%Y"
    time: "%H:%M:%S"
    periods: [ "فجرا", "ظهرًا" ]
    days: [ "الأحد", "الاثنين", "الثلاثاء", "الأربعاء", "الخميس", "الجمعة", "السبت" ]
    shortDays: [ "الأحد", "الاثنين", "الثلاثاء", "الأربعاء", "الخميس", "الجمعة", "السبت" ]
    months: [ "يناير", "فبراير", "مارس", "أبريل", "مايو", "يونيو", "يوليو", "أغسطس", "سبتمبر", "أكتوبر", "نوفمبر", "ديسمبر" ]
    shortMonths: [ "يناير", "فبراير", "مارس", "أبريل", "مايو", "يونيو", "يوليو", "أغسطس", "سبتمبر", "أكتوبر", "نوفمبر", "ديسمبر" ]
  ja:
    decimal: "."
    thousands: ","
    grouping: [ 3 ]
    currency: [ "$", "" ]
    dateTime: "%a %b %e %X %Y"
    date: "%Y/%m/%d"
    time: "%H:%M:%S"
    periods: [ "午前", "午後" ]
    days: [ "日曜日", "月曜日", "火曜日", "水曜日", "木曜日", "金曜日", "土曜日" ]
    shortDays: [ "日", "月", "火", "水", "木", "金", "土" ]
    months: [ "1月", "2月", "3月", "4月", "5月", "6月", "7月", "8月", "9月", "10月", "11月", "12月" ]
    shortMonths: [ "1月", "2月", "3月", "4月", "5月", "6月", "7月", "8月", "9月", "10月", "11月", "12月" ]
  en:
    decimal: "."
    thousands: ","
    grouping: [ 3 ]
    currency: [ "$", "" ]
    dateTime: "%a %b %e %X %Y"
    date: "%m/%d/%Y"
    time: "%H:%M:%S"
    periods: [ "AM", "PM" ]
    days: [ "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday" ]
    shortDays: [ "Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat" ]
    months: [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ]
    shortMonths: [ "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec" ]
  es:
    decimal: "."
    thousands: ","
    grouping: [ 3 ]
    currency: [ "$", "" ]
    dateTime: "%x, %X",
    date: "%d/%m/%Y",
    time: "%-I:%M:%S %p"
    periods: ["AM", "PM"]
    days: ["domingo", "lunes", "martes", "miércoles", "jueves", "viernes", "sábado"]
    shortDays: ["dom", "lun", "mar", "mié", "jue", "vie", "sáb"]
    months: ["enero", "febrero", "marzo", "abril", "mayo", "junio", "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"]
    shortMonths: ["ene", "feb", "mar", "abr", "may", "jun", "jul", "ago", "sep", "oct", "nov", "dic"]
  de:
    decimal: ","
    thousands: "."
    grouping: [ 3 ]
    currency: [ "$", "" ]
    dateTime: "%a %b %e %X %Y"
    date: "%d/%m/%Y"
    time: "%H:%M:%S"
    periods: [ "AM", "PM" ]
    days: [ "Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag" ]
    shortDays: [ "So", "Mo", "Di", "Mi", "Do", "Fr", "Sa" ]
    months: [ "Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember" ]
    shortMonths: [ "Jan", "Feb", "März", "Apr", "Mai", "Juni", "Juli", "Aug", "Sept", "Okt", "Nov", "Dez" ]
  'zh-CN': # TODO: Update this as appropriate
    decimal: "."
    thousands: ","
    grouping: [ 3 ]
    currency: [ "$", "" ]
    dateTime: "%a %b %e %X %Y"
    date: "%m/%d/%Y"
    time: "%H:%M:%S"
    periods: [ "AM", "PM" ]
    days: [ "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday" ]
    shortDays: [ "Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat" ]
    months: [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ]
    shortMonths: [ "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec" ]
  'zh-TW': # TODO: Update this as appropriate
    decimal: "."
    thousands: ","
    grouping: [ 3 ]
    currency: [ "$", "" ]
    dateTime: "%a %b %e %X %Y"
    date: "%m/%d/%Y"
    time: "%H:%M:%S"
    periods: [ "AM", "PM" ]
    days: [ "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday" ]
    shortDays: [ "Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat" ]
    months: [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ]
    shortMonths: [ "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec" ]
