ceregoCommon.service("timeHelper", ['$rootScope', '$filter', ($rootScope, $filter) ->
  $scope = $rootScope.$new()

  window.debug = window.debug || {}
  window.debug["timeHelper"] = $scope

  secondsPerMinute = 60
  secondsPerHour   = secondsPerMinute * 60
  secondsPerDay    = secondsPerHour * 24
  secondsPerYear   = secondsPerDay * 365

  _.assignIn $scope,
    secToString: (sec) ->
      $scope.msecToString(sec*1000)

    msecToString: (msec) ->
      duration = moment.duration(msec, "ms")
      if duration.years()
        out = moment.duration(duration.years(), "year").humanize()
        if duration.months() > 1
          out += ' ' + moment.duration(duration.months(), "month").humanize()
      else if duration.months()
        out = moment.duration(duration.months(), "month").humanize()
        if duration.days() > 1
          out += ' ' + moment.duration(duration.days(), "day").humanize()
      else if duration.days()
        out = moment.duration(duration.days(), "day").humanize()
        if duration.hours() > 1
          out += ' ' + moment.duration(duration.hours(), "hour").humanize()
      else if duration.hours()
        out = moment.duration(duration.hours(), "hour").humanize()
        if duration.minutes() > 1
          out += ' ' + moment.duration(duration.minutes(), "minute").humanize()
      else if duration.minutes()
        out = moment.duration(duration.minutes(), "minute").humanize()
      else if duration.seconds()
        out = moment.duration(duration.seconds(), "seconds").humanize()
      else
        out = $filter('translate')("js.memory_bank.less_than_a_second")
      out

    round: (msec) ->
      roundTo = (n, k) ->
        Math.round(n / k) * k

      duration = moment.duration(Math.floor(msec), "ms")
      if duration.years()
        (duration.years() * 365 + roundTo(duration.days(), 365)) * secondsPerDay * 1000
      else if duration.days()
        (duration.days() * 24 + roundTo(duration.hours(), 24)) * secondsPerHour * 1000
      else if duration.hours()
        (duration.hours() * 60 + roundTo(duration.minutes(), 60)) * secondsPerMinute * 1000
      else if duration.minutes()
        (duration.minutes() * 60 + roundTo(duration.seconds(), 60)) * 1000
      else if duration.seconds()
        duration.seconds() * 1000
      else
        1000
])
