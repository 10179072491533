ceregoCommon.factory('APIUsersResource', ['$resource', ($resource) ->
  $resource "", {},
    query:
      method: 'GET'
      isArray: false
      url: APIRoutes.v4_partner_api_users_path(":partner_id")
      params:
        partner_id: "@partner_id"
    save:
      method: 'POST'
      isArray: false
      url: APIRoutes.v4_partner_api_users_path(":partner_id")
      params:
        partner_id: "@partner_id"
    delete:
      method: 'DELETE'
      isArray: false
      url: APIRoutes.v4_api_user_path(":id")
      params:
        id: "@id"
])
