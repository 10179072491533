ceregoCommon.factory("moduleContextService", ['$rootScope', '$q', '$routeParams', 'DebugHelper', 'SetsResource', 'SeriesResource', ($rootScope, $q, $routeParams, DebugHelper, SetsResource, SeriesResource) ->
  $scope = $rootScope.$new()

  DebugHelper.register("moduleContextService", $scope)

  _.assignIn $scope,
    module: {}

    initialize: ->
      if $routeParams.hasOwnProperty("series_id")
        $scope.fetchModule($routeParams.series_id, "series")
      else if $routeParams.hasOwnProperty("set_id")
        $scope.fetchModule($routeParams.set_id, "set")
      else
        # we should never get in this state
        DebugHelper.logError("Module Context Service requires set or series param")

    fetchModule: (id, module_type) ->
      if !_.isEmpty($scope.module) && ($scope.module.id != parseInt(id))
        $scope.module = {}
        $scope.deferred = null

      unless $scope.deferred
        $scope.deferred = $q.defer()

        if module_type == 'set'
          resource = SetsResource
        else
          resource = SeriesResource

        $scope.deferred = $q.defer()

        if _.isEmpty($scope.module)
          resource.get id: id
          ,
            include_unstudiable: true
          , (success) ->
            $scope.module = success.response
            $scope.deferred.resolve($scope.module)
        else
          $scope.deferred.resolve($scope.module)

      $scope.deferred.promise

    reset: () ->
      $scope.module = {}
])
