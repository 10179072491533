ceregoCommon.directive("v3Toggle", ['$timeout', ($timeout) ->
  restrict: "E"
  templateUrl: Packs.iKnowRoutes.common_v3_templates_path("v3_toggle")

  scope:
    model: "="
    field: "="
    name: "=?"
    disabled: "=?"
    updateFn: "&?"

  controller: ['$scope', 'DebugHelper', ($scope, DebugHelper) ->
    DebugHelper.register("V3Toggle", $scope)

    _.assignIn $scope,
      toggle: ->
        unless $scope.disabled
          $scope.updateFn && $scope.updateFn()

    $scope.$watch "model.enabled", (newVal, oldVal)->
      if newVal && oldVal && newVal != oldVal
        $scope.updateFn && $scope.updateFn()
  ]

  link: ($scope, element, attrs) ->
    $scope.element = element
])
