ceregoCommon.factory('V3SetItemsResource', ['$resource', ($resource) ->
  $resource "", {},
    query:
      method: 'GET'
      isArray: false
      url: APIRoutes.v3_set_items_path(":set_id")
      params:
        set_id: "@set_id"
    delete:
      method: 'DELETE'
      isArray: true
      url: APIRoutes.v3_item_path(":id")
      params:
        id: "@id"
    create:
      method: 'POST'
      isArray: false
      url: APIRoutes.v3_set_items_path(":set_id")
      params:
        set_id: "@set_id"
])
