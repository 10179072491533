ceregoCommon.directive("layoutHeadingTabs", [->
  restrict: "E"
  templateUrl: Packs.iKnowRoutes.common_v3_templates_path("_layout_heading_tabs")

  scope:
    activeTab: "=?"
    tabs: "=?"

  controller: ['$scope', 'DebugHelper', ($scope, DebugHelper) ->
    DebugHelper.register("layoutHeadingTabs", $scope)

    _.assignIn $scope,
      setActiveTab: (tab) ->
        $scope.activeTab = tab
  ]
])
