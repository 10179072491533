/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__, or convert again using --optional-chaining
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
ceregoCommon.controller("createScormModal", ['$scope', '$location', '$q', '$filter', '$timeout', '$routeParams', 'DebugHelper', 'myPartnersService', 'UploaderService', 'toastHelper', 'V3SetsResource', 'reactNavigationService', 'V3CourseSetsResource', function($scope, $location, $q, $filter, $timeout, $routeParams, DebugHelper, myPartnersService, UploaderService, toastHelper, V3SetsResource, reactNavigationService, V3CourseSetsResource) {
  DebugHelper.register("createScormModal", $scope);

  _.assignIn($scope, {
    UploaderService,
    set: {},
    courseId: $routeParams.courseId,

    setIsValid() {
      return (($scope.set.name != null ? $scope.set.name.length : undefined) > 0) && UploaderService.scormUploader.uiState.uploadCompleted;
    },

    createSet() {
      // move this to success callback after we're actually creating
      const params = {
        name: $scope.set.name,
        description: $scope.set.description,
        scorm_package_id: UploaderService.scormUploader.scorm_package_id,
        icon_image_id: __guard__(__guard__(UploaderService.imageUploader != null ? UploaderService.imageUploader.model : undefined, x1 => x1.data), x => x.id)
      };

      if (myPartnersService.currentProductPartner || $routeParams.partnerId) {
        params.partner_id = (myPartnersService.currentProductPartner != null ? myPartnersService.currentProductPartner.id : undefined) || $routeParams.partnerId;
      }

      V3SetsResource.create(params
      , function(success) {
        if ($scope.courseId) {
          // create corresponding course_set record
          V3CourseSetsResource.save({
            course_id: $scope.courseId,
            id: success.data.id
          });
          return reactNavigationService.navigateToReactCourseDetailPage($scope.courseId);
        } else {
          return $location.path(Packs.iKnowRoutes.edit_details_v2_create_set_path(success.data.id));
        }
      }
      , error => DebugHelper.logError(error, "ScormSetCreationError"));

      if (!$scope.courseId) {
        $scope.closeModal();
        return toastHelper.showToast('simpleToast', {message: $filter("translate")("js.scorm.confirmation_toast")});
      }
    },

    cancel() {
      if (!$scope.courseId) {
        return $scope.closeModal();
      } else {
        return reactNavigationService.navigateToReactCourseDetailPage($scope.courseId);
      }
    },

    hideError() {
      return $scope.UploaderService.scormUploader.hideError();
    }
  }
  );

  UploaderService.$watch("scormUploader.fileName", newVal => $scope.set.name || ($scope.set.name = newVal));

  return $scope.$on('$destroy', function() {
    UploaderService.scormUploader.reset();
    return $scope.set = {};
});
}
]);

function __guard__(value, transform) {
  return (typeof value !== 'undefined' && value !== null) ? transform(value) : undefined;
}