ceregoCommon.factory('TagTypeResource', ['$resource', ($resource) ->
  $resource "", {},
    get:
      method: 'GET'
      isArray: false
      url: APIRoutes.v3_partner_tag_types_path(":partner_id")
      params:
        partner_id: "@partner_id"

])
