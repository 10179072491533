ceregoCommon.service("paginationService", [->
  service =
    getClassAndPage: (currentPage, totalPages) ->
      pages = []

      if currentPage == 1
        pages.push {number: 1, class: 'active'}
        _.times _.min([totalPages - 1, 2]), (index) ->
          pages.push {number: currentPage + index + 1, class: ''}
      else if currentPage == totalPages
        _.times _.min([totalPages - 1, 2]), (index) ->
          pages.unshift {number: currentPage - index - 1, class: ''}
        pages.push {number: totalPages, class: 'active'}
      else # in this case we definitely have 3 pages or more, and the currentPage is somewhere in the middle
        pages = [
          {number: currentPage - 1, class: ''},
          {number: currentPage, class: 'active'},
          {number: currentPage + 1, class: ''}
        ]

      pages

  service
])
