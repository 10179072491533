ceregoCommon.factory('V3CoursesResource', ['$resource', ($resource) ->
  $resource "", {},
    clone:
      method: 'POST'
      isArray: false
      url: APIRoutes.clone_v3_course_path(":id")
      params:
        id: "@id"
    get:
      method: 'GET'
      isArray: false
      url: APIRoutes.v3_course_path(":id")
      params:
        id: "@id"
    update:
      method: 'PUT'
      isArray: false
      url: APIRoutes.v3_course_path(":id")
      params:
        id: "@id"
    query:
      method: 'GET'
      isArray: false
      url: APIRoutes.v3_courses_path()
    save:
      method: 'POST'
      isArray: false
      url: APIRoutes.v3_courses_path()
    delete:
      method: 'DELETE'
      isArray: false
      url: APIRoutes.v3_course_path(":id")
      params:
        id: "@id"
    bulk_destroy:
      method: 'DELETE'
      isArray: false
      url: APIRoutes.v3_partner_courses_path(":partner_id")
      params:
        partner_id: "@partner_id"
    bulk_update:
      method: 'PUT'
      isArray: false
      url: APIRoutes.v3_partner_courses_path(":partner_id")
      params:
        partner_id: "@partner_id"
])
