ceregoCommon.controller("soundUploaderModal", ['$scope', 'DebugHelper', ($scope, DebugHelper) ->
  DebugHelper.register("soundUploaderModal", $scope)
  _.assignIn $scope,
    activeModel: {}
    stateUI:
      processing: false
      populated: false
    uploadStatus:
      complete: 0
      total: 1
      percentage: 0
      active: false
    
    triggerUpload: ->
      if !$scope.stateUI.processing
        $scope.stateUI.processing = true
        $scope.$broadcast 'soundUploaderModal:uploadMedia'

    insertSound: ->
      _.assign($scope.model, $scope.activeModel)
      $scope.stateUI.processing = false
      $scope.closeModal()

    showProgress: (e, uploaderStatus) ->
      _.assign($scope.uploadStatus, uploaderStatus)

  $scope.model = $scope.modalOptions.model
  $scope.activeModel = _.cloneDeep($scope.model)

  $scope.$watch "activeModel.data", (newVal, oldVal) ->
    if newVal != oldVal
      $scope.stateUI.populated = true
  $scope.$on 'soundUploader:uploadComplete', $scope.insertSound
  $scope.$on 'soundUploader:uploadProgress', $scope.showProgress
])
