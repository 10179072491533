import * as angular from 'angular';
import { IDebugHelper } from '../../../../v2/modules/core/debug-helper';
import { IAmplitudeService } from './amplitude-service';

export interface IPageTrackingService extends ng.IScope {
  trackPage: (pageName: string) => void;
  trackedVisitItemEditor: boolean;
  trackedVisitPartnerDashboard: boolean;
}

angular.module('cerego.common').service('PageTrackingService', [
  '$rootScope',
  'DebugHelper',
  'AmplitudeService',
  ($rootScope: ng.IRootScopeService, DebugHelper: IDebugHelper, AmplitudeService: IAmplitudeService) => {
    const $scope = $rootScope.$new() as IPageTrackingService;

    DebugHelper.register('PageTrackingService', $scope);

    $scope.trackedVisitItemEditor = false;
    $scope.trackedVisitPartnerDashboard = false;

    $scope.trackPage = pageName => {
      // don't track url loading in development so we don't spam amplitude each time the file is saved
      if (process.env.RAILS_ENV !== 'development') {
        AmplitudeService.logEvent('visit_url', { visited_url: pageName });
      }
    };

    return $scope;
  }
]);
