import * as angular from 'angular';
import * as angulartics from 'angulartics';

declare const CeregoAPI: {
  version: number;
  token: string;
  server: string;
  api_version: string;
  key: string;
};

const coreDependencies = [
  'ngAnimate',
  'ngCookies',
  'ngResource',
  'LocalStorageModule',
  'angulartics',
  'angulartics.google.analytics',
  'cfp.hotkeys',
];

angular.module('cerego.core', coreDependencies).config([
  '$analyticsProvider',
  '$locationProvider',
  '$provide',
  '$httpProvider',
  (
    $analyticsProvider: v3.IAnalyticsProvider,
    $locationProvider: ng.ILocationProvider,
    $provide,
    $httpProvider: ng.IHttpProvider
  ) => {
    $locationProvider.html5Mode({
      enabled: true,
      requireBase: false,
    });

    $analyticsProvider.settings.ga.additionalAccountNames = ['wwwAnalytics'];

    $provide.factory('HttpAuthorizationInterceptor', [
      '$q',
      '$cookies',
      '$window',
      ($q: ng.IQService, $cookies: ng.cookies.ICookiesService, $window: ng.IWindowService) => ({
        request(config) {
          if (
            config &&
            config.url.match(/^\/|cerego|localhost|iknow/) &&
            !config.url.match(/nlp\.cerego\.com|localhost:5000|\/templates\//)
          ) {
            if (!config.params) {
              config.params = {};
            }
            if (CeregoAPI.token) {
              config.headers.Authorization = `Bearer ${CeregoAPI.token}`;
            }
          }
          return config || $q.when(config);
        },
      }),
    ]);

    $httpProvider.interceptors.push('HttpAuthorizationInterceptor');

    // Use rails default of 'csrf_token' instead of angular 'XSRF-TOKEN'
    $httpProvider.defaults.xsrfCookieName = 'csrf_token';
    // Use rails default of 'X-CSRF-Token' instead of angular 'X-XSRF-TOKEN'
    $httpProvider.defaults.xsrfHeaderName = 'X-CSRF-Token';
  },
]);

require('./core/conversion-analytics-service.ts');
require('./core/debug-helper.ts');
require('./core/filters.ts');
require('./core/modal-service.ts');
require('./core/user-cookie-helper.ts');
require('./core/v3-model-helper.ts');

// angular-hotkeys is no longer maintained and has been heavily modified by us - try to get rid of it
require('../../../../../vendor/assets/javascripts/v2/angular-hotkeys.js');
