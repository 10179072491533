import * as angular from 'angular';
import * as d3 from 'd3';
import UserCookie from 'javascripts/v2/modules/core/user-cookie-helper';
import * as _ from 'lodash';

declare global {
  // tslint:disable-next-line:interface-name
  interface Window {
    d3Formatter: any;
  }
}

function toUpperCaseAfterDash(str) {
  return str.replace(/(-.+)/, (match) => match.toUpperCase());
}

function getNormalizedBrowserLanguage(browserLanguage) {
  const language = browserLanguage.toLowerCase().replace('-hant', '-tw').replace('-hans', '-cn');

  if (['zh-cn', 'zh-tw'].includes(language.slice(0, 5))) {
    return toUpperCaseAfterDash(language.slice(0, 5));
  } else if (language.startsWith('zh')) {
    return 'zh-CN';
  } else {
    return language.slice(0, 2);
  }
}

function getLanguage() {
  const cookie = new UserCookie(angular.injector(['ngCookies']).get('$cookies') as ng.cookies.ICookiesService);
  const allowedLanguages = ['en', 'fr', 'ar', 'ja', 'de', 'es', 'zh-CN', 'zh-TW'];
  const desiredLanguage = cookie.locale || getNormalizedBrowserLanguage(navigator.language);
  return allowedLanguages.includes(desiredLanguage) ? desiredLanguage : 'en';
}

angular
  .module('cerego.translate', ['pascalprecht.translate', 'ngCookies'])
  .factory('$missingTranslationHandler', [
    '$log',
    ($log) => {
      return (translationId, $uses) => {
        // do not log error if it is not a valid i18n key. (e.g. "js.learn.assignments.advanced")
        if (
          translationId.match(/^\./) ||
          translationId.match(/\.$/) ||
          translationId.match(/\.\./) ||
          !translationId.match(/^js/)
        ) {
          return;
        }
      };
    },
  ])
  .config([
    '$translateProvider',
    'translateD3',
    'translateEn',
    'translateFr',
    'translateAr',
    'translateJa',
    'translateDe',
    'translateEs',
    'translateZhCN',
    'translateZhTW',
    (
      $translateProvider,
      translateD3,
      translateEn,
      translateFr,
      translateAr,
      translateJa,
      translateDe,
      translateEs,
      translateZhCN,
      translateZhTW
    ) => {
      const preferredLanguage = getLanguage();

      $translateProvider
        .translations('en', translateEn.default.en)
        .translations('fr', translateFr.default.fr)
        .translations('ar', translateAr.default.ar)
        .translations('ja', translateJa.default.ja)
        .translations('de', translateDe.default.de)
        .translations('es', translateEs.default.es)
        .translations('zh-CN', translateZhCN.default['zh-CN'])
        .translations('zh-TW', translateZhTW.default['zh-TW'])
        .preferredLanguage(preferredLanguage)
        .useMissingTranslationHandler('$missingTranslationHandler');

      const d3Formatter = d3.locale(translateD3[preferredLanguage]);

      d3.time.format = d3Formatter.timeFormat;
      d3.format = d3Formatter.numberFormat;

      window.d3Formatter = d3Formatter;
    },
  ]);

// replace this with explicit import
const req = (require as any).context('./translate', false);
req.keys().forEach(req);
