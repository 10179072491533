ceregoCommon.service("ItemUploader", ['$rootScope', '$q', '$timeout', 'SetsResource', 'ItemUploadResource', ($rootScope, $q, $timeout, SetsResource, ItemUploadResource) ->
  $scope = $rootScope.$new()

  window.debug = window.debug || {}
  window.debug["ItemUploader"] = $scope
  SET_MAX_ITEMS_COUNT = 500

  _.assignIn $scope,
    upload: (set, items) ->
      deferred = $q.defer()

      if (set.items_count + items.length) > SET_MAX_ITEMS_COUNT
        alert("For the best experience, Sets may have no more than " + SET_MAX_ITEMS_COUNT + " items.  To create new content, create more sets and add them to a series.")
        deferred.reject
      else
        itemUpload = {}

        onSuccess = (success) ->
          itemUpload = success.response
          if itemUpload.status == "completed"
            deferred.resolve(itemUpload)
          else
            deferred.notify(itemUpload)
            $timeout ->
              ItemUploadResource.get
                id: itemUpload.id
              , onSuccess
              , deferred.reject
            , 2000

        SetsResource.itemUploads
          set_id: set.id
          items: items
        , onSuccess
        , deferred.reject

      deferred.promise
])
