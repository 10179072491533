ceregoCommon.service("RegionHighlightingService", [ ->
  (facets, dimensions) ->
    service = {}

    ratio = 4 / 3

    if dimensions.width / dimensions.height > ratio
      service.width = dimensions.height * ratio
      service.height = dimensions.height
    else
      service.width = dimensions.width
      service.height = dimensions.width / ratio

    _.assignIn service,
      regions: []
      marker_rectangle: {}

    for facet in facets
      points = _.map facet.anchor.image_map_node.region_polygon.split(", "), (point) ->
        x: Math.min(Math.max(parseFloat(point.split(" ")[0]), 0.0), 1.0) * service.width
        y: Math.min(Math.max(parseFloat(point.split(" ")[1]), 0.0), 1.0) * service.height
      polygon = _.map(points, (p) -> p.x + " " + p.y).join(", ")

      service.regions.push
        points: points
        polygon: polygon
        facet: facet

    service
])

ceregoCommon.directive("cgRegion", ['$window', 'RegionHighlightingService', ($window, RegionHighlightingService) ->
  restrict: "E"
  scope:
    facets: "="
    image: "="
    size: "@"
    type: "@"
    dash: "=?"
    model: "="
    allowTooltip: "=?"
    disableclick: "@" # TODO: remove this once v1 learn app is gone
    onLoad: "&?"

  templateUrl: Packs.iKnowRoutes.regions_v2_templates_path("svg")

  controller: ['$scope', '$timeout', 'DebugHelper', ($scope, $timeout, DebugHelper) ->
    DebugHelper.register("cgRegion", $scope)

    model = $scope.model || {}

    _.assignIn $scope, {
      tooltipVisible: false

      selectRegion: ($event, region) ->
        if $event.offsetX
          offsets = { x: $event.offsetX , y: $event.offsetY }
        else if $event.originalEvent #firefox
          pageOffset = $($event.target).position()
          offsets = { x: $event.originalEvent.layerX - pageOffset.left , y: $event.originalEvent.layerY - pageOffset.top }

        scaledOffsets = { x: offsets.x/$scope.service.width, y: offsets.y/$scope.service.height }

        if $scope.allowTooltip && region
          $scope.toggleRegionTooltip(region)
        if !$scope.disableclick
          $scope.$emit "regionClicked", region, scaledOffsets

      toggleRegionTooltip: (region) ->
        rightMostPoint = _.max region.points, (point) -> point.x
        topMostPoint = _.max region.points, (point) -> point.y
        bottomMostPoint = _.min region.points, (point) -> point.y

        if $scope.selectedRegion == region
          $scope.tooltipVisible = !$scope.tooltipVisible
        else
          $scope.tooltipVisible = true
        $scope.selectedRegion = region

        $scope.tooltip = {
          left: rightMostPoint.x + "px"
          top: (topMostPoint.y/2 + bottomMostPoint.y/2)  + "px"
        }

      calculateSize: ->
        dimensions = {}

        $el = $($window)

        if $scope.size == "auto" && $scope.element
          $scope.element.hide()
          dimensions.width  = $scope.element.parent().width()
          dimensions.height = $scope.element.parent().height()
          $scope.element.show()
        else if $scope.size == "small"
          dimensions.width = 200
          dimensions.height = 150
        else if $scope.size == "tiny"
          dimensions.width = 133
          dimensions.height = 100
        else
          if $scope.size == "zoom"
            scaleFactor = .9
          else
            scaleFactor = .6

          dimensions.width = $el.width() * scaleFactor
          dimensions.height = $el.height() * scaleFactor

        $scope.service = RegionHighlightingService($scope.region_facets, dimensions)

        model.regions = $scope.service.regions
        model.width   = $scope.service.width
        model.height  = $scope.service.height
    }

    angular.element($window).bind "resize", ->
      $scope.$apply ->
        $scope.calculateSize()

    $scope.$on '$destroy', ->
      angular.element($window).unbind('resize')

    $scope.$watch "facets", ->
      if $scope.facets
        $scope.region_facets = _.filter([].concat($scope.facets), (facet) -> facet.anchor.image_map_node )
        $timeout $scope.calculateSize

    $scope.$on("addWrongMarker", (event, scaledOffsets) ->
      if $scope.service && scaledOffsets
        $timeout ->
          $scope.calculateSize()
          newHeight = $scope.service.height
          newWidth  = $scope.service.width
          $scope.service.marker_rectangle =
            x1: scaledOffsets.x * newWidth  - 10
            x2: scaledOffsets.x * newWidth + 10
            y1: scaledOffsets.y * newHeight - 10
            y2: scaledOffsets.y * newHeight + 10
    )
  ]

  link: ($scope, element, attrs) ->
    $scope.element = element
    if $scope.onLoad
      $scope.onLoad(element)
])
