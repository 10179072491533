ceregoCommon.directive('flexibleImage', [->
  restrict: "A"

  scope:
    url: "@flexibleImage"
    noZoom: "="

  templateUrl: Packs.iKnowRoutes.v2_template_path("flexible_image")

  controller: ['$scope', ($scope) ->
    $scope.enableZoom = ->
      $scope.zoom = true

    $scope.disableZoom = ->
      $scope.zoom = false
  ]

  link: ($scope, element, attr) ->
    element.addClass("flexible-image")

    $scope.$watch "url", ->
      element.toggleClass("ng-hide", !$scope.url)
      element.css("background-image", "url(" + $scope.url + ")") if $scope.url
])
