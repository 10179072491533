import * as angular from 'angular';
import * as _ from 'lodash';

import { IDebugHelper } from 'v2/modules/core/debug-helper';

export interface IV3ModelHelper extends ng.IScope {
  parseArray: <T extends v3.resource.IBase>(success: v3.api.IResponse<T>) => v3.api.IResponseArray<T>;
  parseObject: <T extends v3.resource.IBase>(success: v3.api.IResponse<T>) => v3.api.IResponseObject<T>;
  humanizeV3Error: <T extends v3.resource.IBase>(response: v3.api.IResponse<T>) => string;
}

interface IV3ModelHelperPrivate extends IV3ModelHelper {
  parseIncluded: <T extends v3.resource.IBase>(success: v3.api.IResponse<T>) => v3.api.IResponse<T>;
  parse: <T extends v3.resource.IBase>(success: v3.api.IResponse<T>, options?: any) => any;
}

angular.module('cerego.core').service('V3ModelHelper', [
  'DebugHelper',
  '$rootScope',
  (DebugHelper: IDebugHelper, $rootScope: ng.IRootScopeService) => {
    const $scope = $rootScope.$new() as IV3ModelHelperPrivate;

    DebugHelper.register('V3ModelHelper', $scope);

    // feeling lazy and just want my damn concepts, TODO come back and actually do this recursively, needs to support arbitrary nesting
    $scope.parseIncluded = success => {
      const included = Array.isArray(success.included) ? success.included : Array.of(success.included);
      for (const resource of included) {
        if (resource !== null && typeof resource !== 'undefined' && resource && resource.relationships) {
          for (const key of Object.keys(resource.relationships || {})) {
            const value = resource.relationships[key];
            const data = Array.isArray(value.data) ? value.data : Array.of(value.data);
            for (const relationship of data as v3.resource.IBase[]) {
              if (relationship !== null && typeof relationship !== 'undefined') {
                _.assignIn(
                  relationship,
                  _.find(success.included, { id: relationship.id, type: relationship.type } as any) || {}
                );
                relationship.id = parseInt(relationship.id.toString(), 10);
              }
            }
          }
        }
      }

      return success;
    };

    $scope.humanizeV3Error = response => {
      return _.map(response.errors, e => _.capitalize(e.source) + ' ' + e.title).join(', ');
    };

    // Legacy - don't use going forward, but keep for backwards compatibility
    $scope.parse = (success, options) => {
      if (options == null) {
        options = {};
      }

      let result;

      if (Array.isArray(success.data)) {
        result = $scope.parseArray(success);
      } else {
        result = $scope.parseObject(success);
      }

      if (options.includeMeta) {
        return result;
      } else {
        return result.data;
      }
    };

    const parseDataArray = (resources, included) => {
      for (const resource of resources as v3.resource.IBase[]) {
        const relationships = resource !== null && typeof resource !== 'undefined' ? resource.relationships : null;
        for (const key of Object.keys(relationships || {})) {
          const value = (resource !== null && typeof resource !== 'undefined' ? resource.relationships : null)[
            key
          ] as v3.resource.IRelationship;
          const data = Array.isArray(value.data) ? value.data : Array.of(value.data);
          for (const relationship of data) {
            if (relationship != null && typeof relationship !== 'undefined' && relationship.id) {
              _.assignIn(relationship, _.find(included, { id: relationship.id, type: relationship.type }) || {});
              relationship.id = parseInt(relationship.id.toString(), 10);
            }
          }
        }
        resource.id = parseInt(resource.id.toString(), 10);
      }

      return resources;
    };

    $scope.parseArray = <T extends v3.resource.IBase>(success) => {
      const parsed = $scope.parseIncluded(success);

      parseDataArray(success.data, parsed.included);
      return success as v3.api.IResponseArray<T>;
    };

    $scope.parseObject = <T extends v3.resource.IBase>(success) => {
      success.data.id = parseInt(success.data.id.toString(), 10);

      const parsed = $scope.parseIncluded(success);
      parseDataArray(Array.of(success.data), parsed.included);
      return success as v3.api.IResponseObject<T>;
    };

    return $scope;
  }
]);
