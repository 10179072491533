# http://odetocode.com/blogs/scott/archive/2013/07/03/building-a-filereader-service-for-angularjs-the-service.aspx

ceregoCommon.service("FileReader", ['$rootScope', '$q', 'DebugHelper', ($rootScope, $q, DebugHelper) ->
  $scope = $rootScope.$new()

  DebugHelper.register("FileReader", $scope)

  read = (method, file) ->
    deferred = $q.defer()

    reader = new FileReader()
    reader.onload = ->
      $scope.$apply ->
        deferred.resolve(reader.result)
    reader.onerror = ->
      $scope.$apply ->
        deferred.reject(reader.result)
    reader.onabort = ->
      $scope.$apply ->
        deferred.reject(reader.result)
    reader.onprogress = (event) ->
      $scope.$apply ->
        deferred.notify(event)

    reader[method](file)
    deferred.promise

  _.assignIn $scope,
    readAsArrayBuffer: (file) ->
      read("readAsArrayBuffer", file)
    readAsBinaryString: (file) ->
      read("readAsBinaryString", file)
    readAsDataURL: (file) ->
      read("readAsDataURL", file)
    readAsText: (file) ->
      read("readAsText", file)
])
