ceregoCommon.directive('pdfUploader', [
  'MediaUploader',
  'V3MediaUploader',
  '$timeout',
  function (MediaUploader, V3MediaUploader, $timeout) {
    return {
      restrict: 'E',
      scope: {
        model: '=',
        version: '=',
      },
      templateUrl: function (_element, $scope) {
        if ($scope.version === '3') {
          return Packs.iKnowRoutes.common_v3_templates_path('pdf_uploader');
        } else {
          return Packs.iKnowRoutes.v2_template_path('pdf_uploader');
        }
      },
      controller: [
        '$scope',
        '$filter',
        'DebugHelper',
        function ($scope, $filter, DebugHelper) {
          DebugHelper.register('pdfUploaderScope', $scope);

          $scope.$parent.pdfUploaders = $scope.$parent.pdfUploaders || [];
          $scope.$parent.pdfUploaders.push($scope);

          _.assignIn($scope, {
            pdfUpload: {
              mode: 'default',
              percentage: 0,
              active: false,
            },
            onPdfUploadProgress: function (progress) {
              $scope.$apply(function () {
                $scope.pdfUpload.percentage = Math.round((100 * progress.loaded) / progress.total);
              });
            },
            uploadPdfFromFile: function ($file) {
              $scope.$apply(function () {
                $scope.uploadPdf({
                  file: $file,
                });
              });
            },
            uploadPdfFromUrl: function ($event, $url) {
              $event.preventDefault();
              $scope.uploadPdf({
                url: $url || $scope.pdfUpload.url,
              });
            },
            uploadPdf: function ($file) {
              if ($file.type === 'application/pdf') {
                if ($scope.version === 3) {
                  $scope.V3PDFUpload({ file: $file });
                } else {
                  $scope.V2PDFUpload({ file: $file });
                }
              }
            },
            V3PDFUpload: function (params) {
              $scope.pdfUpload.active = true;
              V3MediaUploader(params, 'pdf', $scope.onPdfUploadProgress)
                .then(function (response) {
                  $scope.model.document.data = response.data.data;
                  var reader = new FileReader();
                  reader.addEventListener('load', function () {
                    $scope.$apply(function () {
                      angular.element('iframe', $scope.el)[0].src = reader.result;
                    });
                  });
                  reader.readAsDataURL(params.file);
                })
                .finally(function (_result) {
                  $scope.pdfUpload.active = false;
                });
            },
            V2PDFUpload: function (params) {
              $scope.pdfUpload.active = true;
              MediaUploader(params, 'pdf', $scope.onPdfUploadProgress)
                .success(function (json) {
                  $scope.$apply(function () {
                    $scope.model.document = json.data;
                    $scope.pdfUpload.active = false;
                    var reader = new FileReader();
                    reader.addEventListener('load', function () {
                      $scope.$apply(function () {
                        angular.element('iframe', $scope.el)[0].src = reader.result;
                      });
                    });
                    reader.readAsDataURL(params.file);
                  });
                })
                .error(function (_error) {
                  $scope.$apply(function () {
                    $scope.pdfUpload.active = false;
                  });
                });
            },
            deletePdf: function () {
              $scope.model.document = { data: null };
            },
            handleDragLeave: function (_$event) {
              $timeout(function () {
                $scope.dragover = false;
              });
            },
            handleDragOver: function (_$event) {
              $timeout(function () {
                $scope.dragover = true;
              });
            },
            displayExistingPdf: function () {
              if (
                ($scope.version !== 3 && $scope.model.document) ||
                ($scope.version === 3 && $scope.model && $scope.model.document && $scope.model.document.data)
              ) {
                var url =
                  $scope.model.document.url ||
                  ($scope.model.document.attributes && $scope.model.document.attributes.url) ||
                  ($scope.model.document.data &&
                    $scope.model.document.data.attributes &&
                    $scope.model.document.data.attributes.url);
                angular.element('iframe', $scope.el)[0].src = $filter('trustedAsResourceUrl')(url);
              }
            },
          });
        },
      ],
      link: function ($scope, element, _attrs) {
        $scope.el = element;

        element[0].addEventListener('dragleave', $scope.handleDragLeave, false);
        element[0].addEventListener('dragover', $scope.handleDragOver, false);

        $scope.displayExistingPdf();
      },
    };
  },
]);
