import * as angular from 'angular';

export interface IDebugHelper {
  register: (name: string, scope: ng.IScope) => void;
  registerController: (name: string, $ctrl: ng.IController) => string;
  unregister: (id: string) => void;
  logError: (error: IAPIError, message?: string) => void;
}

interface IDebugWindow extends ng.IWindowService {
  debug: {
    counter: number;
  };
}

interface IAPIError extends Error {
  config: {
    headers: {
      Authorization: string;
    };
  };
}

angular.module('cerego.core').service('DebugHelper', [
  '$window',
  ($window: IDebugWindow) => {
    $window.debug = $window.debug || {
      counter: 1,
    };

    const service: IDebugHelper = {
      register(name, $scope) {
        const id = name + $scope.$id;
        $window.debug[id] = $scope;
        $scope.$on('$destroy', () => delete $window.debug[id]);
      },

      // returns id the controller was registered with. Must pass id into unregister on destroy
      registerController(name, $ctrl) {
        const id = name + $window.debug.counter;
        $window.debug.counter += 1;
        $window.debug[id] = $ctrl;
        return id;
      },

      unregister(id) {
        delete $window.debug[id];
      },

      logError(error, message) {
        if (message == null) {
          message = '';
        }
        if (error && error.config && error.config.headers && error.config.headers.Authorization) {
          delete error.config.headers.Authorization;
        }
      },
    };

    return service;
  },
]);
