ceregoCommon.factory('PartnerUsersResource', ['$resource', ($resource) ->
  $resource "", {},
    index:
      method: 'GET'
      url: APIRoutes.v3_partner_users_path(":partner_id")
      params:
        partner_id: "@partner_id"
    create:
      method: 'POST'
      isArray: false
      url: APIRoutes.v3_partner_users_path(":partner_id")
      params:
        partner_id: "@partner_id"
    get:
      method: 'GET'
      isArray: false
      url: APIRoutes.v3_partner_user_path(":partner_id", ":id")
      params:
        partner_id: "@partner_id"
        id: "@id"
    update:
      method: 'PUT'
      url: APIRoutes.v3_partner_user_path(":partner_id", ":id")
      params:
        partner_id: "@partner_id"
        id: "@id"
    delete:
      method: 'DELETE'
      url: APIRoutes.v3_partner_user_path(":partner_id", ":id")
      params:
        partner_id: "@partner_id"
        id: "@id"
])
