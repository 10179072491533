ceregoCommon.filter('hardNewlines', [->
  (text) ->
    (text || "").replace(/\n/g, "<br />")
])

ceregoCommon.filter('htmlSafe', ['$sce', '$filter', ($sce, $filter) ->
  (text) ->
    $sce.trustAsHtml($filter('hardNewlines')(text).toString())
])

ceregoCommon.filter('stripNewLines', [->
  (text) ->
    (text || "").replace(/\n|\r/g, '')
])

ceregoCommon.filter('stripHTML', [->
  (text) ->
    $("<div/>").html(text || "").text()
])

ceregoCommon.filter('unstripHTML', [->
  (text) ->
    $("<div/>").text(text || "").html()
])

ceregoCommon.filter('setCreateDimensions', [->
  (text) ->
    object = $(text || "")
    object.attr('width', '100%')
    object.attr('height', '400px')
    (object[0]?.outerHTML ? text) ? ''
])

ceregoCommon.filter('trustedAsUrl', ['$sce', ($sce) ->
  (url) ->
    $sce.trustAsUrl(url)
])

ceregoCommon.filter('trustedAsResourceUrl', ['$sce', ($sce) ->
  (url) ->
    $sce.trustAsResourceUrl(url)
])

ceregoCommon.filter('encodeURIComponent', ['$window', ($window) ->
  (input) ->
    $window.encodeURIComponent(input)
])

ceregoCommon.filter('inBatches', [->
  (input, batchSize) ->
    filtered = []
    for element in input by batchSize
      filtered.push element
    filtered
])

ceregoCommon.filter('without', [->
  (input, element) ->
    _.reject input, (el) -> el == element
])

ceregoCommon.filter('imagePresence', [->
  (image, defaultImage) ->
    image?.url || image?.data?.attributes?.url || defaultImage
])

ceregoCommon.filter('centsToDollars', [->
  (cost) ->
    (cost/100).toFixed(2)
])

ceregoCommon.filter('convertToBoolean', [->
  (bool) ->
    if bool == "false" || bool == "0"
      false
    else
      !!bool
])

ceregoCommon.filter('levelRoundDown', [->
  (number) ->
    if number <= 0 then (0).toFixed(1) else Math.max(0.1, (Math.floor(number * 10) / 10)).toFixed(1)
])

ceregoCommon.filter('convertProgressToInteger', [->
  (number) ->
    return 1 if number > 0 && number < 0.01 # If there is some progress always show at least 1%
    _.floor(number * 100)
])

ceregoCommon.filter('decimalToPercentage', [->
  (decimal) ->
    Math.floor(decimal * 100).toFixed(0)
])

ceregoCommon.filter('ellipsis', [->
  (text, maxLength) ->
    if text && text.length > maxLength
      text.substring(0, maxLength) + "..."
    else
      text
])

ceregoCommon.filter('presence', [->
  (text) ->
    _.isString(text) && text.length > 0
])

ceregoCommon.filter('sortedForSequence', [->
  (facets) ->
    _.sortBy(facets, (facet) -> facet.anchor.sequence_node.position)
])

ceregoCommon.filter('isEmpty', [->
  (object) ->
    _.isEmpty(object)
])

ceregoCommon.filter('localizeDateFormat', [ ->
  (dateText) ->
    locale = angular.element("body").data("locale") || "en"
    date = new Date(dateText)
    date.toLocaleDateString(locale, {month: "long", day: "numeric", year: "numeric"})
])

ceregoCommon.filter('localizeDateWithOutYear', [ ->
  (dateText) ->
    locale = angular.element("body").data("locale") || "en"
    date = new Date(dateText)
    date.toLocaleDateString(locale, {month: "long", day: "numeric"})
])

ceregoCommon.filter('textShrinkClasses', [->
  (text) ->
    classes = []
    if text
      classes.push("study--concept-long") if text.length > 900
      classes.push("study--concept-left-align") if text.length > 400
    return classes
])

ceregoCommon.filter('filterItemsText', ['$filter', ($filter) ->
  (input, text) ->
    check = (field) ->
      !_.isEmpty(field) && $filter('filter')([field.toLowerCase()], text.toLowerCase()).length > 0

    if _.isEmpty(text)
      input
    else
      _.filter(input, (item) ->
        template = item[item.template_type]
        check(template?.title) || check(item.text) || check(template?.concept?.text_html) || _.some(template.facets, (facet) ->
          check(facet.association_label) ||
            check(facet.anchor.concept?.text_html) ||
            check(facet.association.concept?.text_html)
        ) || _.some(item.annotations, (annotation) ->
          check(annotation.text_html) ||
            check(annotation.caption)
        ) || _.some(item.sentences, (sentence) ->
          check(sentence.text_html) ||
            check(sentence.transliteration_html) ||
            check(sentence.translation_html)
        )
      )
])

ceregoCommon.filter('filterName', ['$filter', ($filter) ->
  (input, text) ->
    check = (field) ->
      !_.isEmpty(field) && $filter('filter')([field.toLowerCase()], text.toLowerCase()).length > 0

    if _.isEmpty(text)
      input
    else
      _.filter(input, (module) ->
        name = module?.attributes?.name
        check(name)
      )
])

ceregoCommon.filter('nameInitialHtml', ['$filter', ($filter) ->
  (name) ->
    names = name.split(/\s+/)
    if names.length == 1
      if names[0][0] == '(' # "(anonymous)" users
        "<span class='far fa-user'></span>"
      else
        names[0][0]
    else if names.length == 3 #hi ASL
      names[0][0] + names[1][0] + names[2][0]
    else
      first_last = [names[0], names.slice(-1)[0]]
      first_last[0][0] + first_last[1][0]
])

ceregoCommon.filter('dig', [->
  (object, keys) ->
    _.get(object, keys)
])
