ceregoCommon.directive('cgPaginateTiles', ['$window', ($window) ->
  restrict: "E"

  scope:
    limit: "="
    modulesCount: "="
    offset: "="
    collection: "@"

  templateUrl: Packs.iKnowRoutes.v2_template_path("paginate_select")

  controller: ['$scope', 'DebugHelper', ($scope, DebugHelper) ->
    DebugHelper.register("cgPaginateTiles", $scope)

    _.assignIn $scope, {
      currentPage: 1
      count: 0
      lastPage: 0
      pageArray: []

      currentMinShown: ->
        (($scope.currentPage - 1) * $scope.limit) + 1

      currentMaxShown: ->
        Math.min($scope.currentPage * $scope.limit, $scope.modulesCount)

      decrementPage: (num) ->
        $scope.currentPage = $scope.currentPage - num
        $scope.notifyPageChanged()

      incrementPage: (num) ->
        $scope.currentPage = $scope.currentPage + num
        $scope.notifyPageChanged()

      goToPage: (page) ->
        $scope.currentPage = page
        $scope.notifyPageChanged()

      notifyPageChanged: ->
        $scope.$emit "pagination:Pagechanged", $scope.currentPage, $scope.collection
        angular.element("body").scrollTop(0);
        $scope.prepPageArray()

      prepPageArray: ->
        if angular.element($window).width() < 768 # mobile
          if $scope.lastPage == 3
            $scope.pageArray = _.range(1, 3)
          else
            $scope.pageArray = [$scope.currentPage]
        else # desktop
          totalPage = Math.ceil($scope.modulesCount / $scope.limit)
          $scope.pageArray = _.range(Math.max(1, $scope.currentPage - 5), Math.min($scope.currentPage + 5, totalPage) + 1)
    }

    $scope.$watch("offset", ->
      if $scope.offset == 0
        $scope.currentPage = 1
        $scope.prepPageArray()
    )

    $scope.$watch("modulesCount", ->
      return if !$scope.modulesCount
      $scope.lastPage = Math.ceil($scope.modulesCount/$scope.limit)
      $scope.prepPageArray()
    )
  ]
])
