import * as angular from 'angular';
import { IDebugHelper } from 'v2/modules/core/debug-helper';

export interface IReactNavigationService extends ng.IScope {
  getBaseReactUrl: () => void;
  navigateToReactCourseDetailPage: (courseId) => void;
  navigateToReactPage: (path) => void;
  getCourseId: () => string;
  getV4CourseUrl: () => string;
  getReturnUrl: () => string;
  navigateToUserProfile: (userId: string) => void;
}

angular.module('cerego.common').service('reactNavigationService', [
  '$rootScope',
  '$window',
  '$routeParams',
  'DebugHelper',
  'setLearnURLService',
  ($rootScope: ng.IRootScopeService, $window, $routeParams, DebugHelper: IDebugHelper, setLearnURLService) => {
    const $scope = $rootScope.$new() as IReactNavigationService;

    DebugHelper.register('reactNavigationService', $scope);

    $scope.getBaseReactUrl = () => {
      const developmentUrl = process.env.APP_HOST || 'http://localhost:3002';
      return process.env.NODE_ENV === 'development' ? developmentUrl : `${window.location.origin}`;
    };

    const baseReactUrl = $scope.getBaseReactUrl();

    $scope.navigateToReactCourseDetailPage = courseId => {
      const url = baseReactUrl + setLearnURLService.getReturnUrl('/courses/' + courseId, { fromEmbed: false });
      $window.open(url, '_parent');
    };

    $scope.navigateToReactPage = path => {
      window.open(`${baseReactUrl}/app/nav${path}`, '_parent');
    };

    $scope.getReturnUrl = () => {
      const urlParams = new URLSearchParams($window.location.search);
      const returnUrl = urlParams.get('returnUrl');
      return returnUrl ? `${baseReactUrl}${returnUrl}` : null;
    };

    $scope.getCourseId = () => {
      return $routeParams.courseId;
    };

    $scope.getV4CourseUrl = () => {
      return `${baseReactUrl}/app/nav/courses/${$scope.getCourseId()}`;
    };

    $scope.navigateToUserProfile = (userId: string) => {
      const url = `${baseReactUrl}/app/nav/user/${userId}`;
      $window.open(url, '_parent');
    };

    return $scope;
  }
]);
