ceregoCommon.factory('V3CourseSetsResource', ['$resource', ($resource) ->
  $resource "", {},
    save:
      method: 'POST'
      isArray: false
      url: APIRoutes.v3_course_sets_path(":course_id")
      params:
        course_id: "@course_id"
    update:
      method: 'PATCH'
      isArray: false
      url: APIRoutes.v3_course_set_path(":course_id", ":id")
      params:
        course_id: "@course_id"
        id: "@id"
    remove:
      method: 'DELETE'
      isArray: true
      url: APIRoutes.v3_course_set_path(":course_id", ":id")
      params:
        course_id: "@course_id"
        id: "@id"
    get:
      method: 'GET'
      isArray: false
      url: APIRoutes.v3_course_set_path(":course_id", ":id")
      params:
        course_id: "@course_id"
        id: "@id"
])
