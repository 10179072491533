import * as angular from 'angular';
import * as $ from 'jquery';
import RecordRTC from 'recordrtc';

interface IMediaProcessorService extends ng.IScope {
  setCallback: (cbk: () => void) => void;
  initRecording(media: 'audio' | 'video', player: $);
  beginRecording();
  endRecording();
  displayRecording();
  streamFailed(err: object);
  compatibleBrowser();
}

angular.module('cerego.common').service('MediaProcessorService', [
  '$rootScope',
  '$window',
  'DebugHelper',
  ($rootScope: ng.IRootScopeService, $window, DebugHelper: any) => {
    const $scope = $rootScope.$new() as IMediaProcessorService;
    let stream;
    let recorder;
    let playerElem;
    let constraints;
    let callback;

    DebugHelper.register('mediaProcessorService', $scope);

    $scope.initRecording = async (media, player) => {
      constraints = {
        audio: true,
        video: media === 'video'
      };
      playerElem = player;

      stream = await $window.navigator.mediaDevices.getUserMedia(constraints);
      if (recorder) {
        recorder.reset();
      }
      if (media === 'video' && playerElem) {
        playerElem.muted = true;
        playerElem.srcObject = stream;
        playerElem.play();
      }
      $scope.beginRecording();
    };

    $scope.endRecording = () => {
      recorder.stopRecording($scope.displayRecording);
      stream.getTracks().forEach(track => track.stop());
      if (playerElem) {
        playerElem.srcObject = null;
      }
    };

    $scope.beginRecording = () => {
      const isVideo = constraints.video;
      const maxDuration = 30 * 60 * 1000;
      const options = {
        type: isVideo ? 'video' : 'audio',
        mimeType: isVideo ? 'video/webm;codecs=vp8' : 'audio/wav',
        recorderType: isVideo ? RecordRTC.MediaStreamRecorder : RecordRTC.StereoAudioRecorder,
        timeSlice: 1000
      };

      recorder = new RecordRTC(stream, options);
      recorder.setRecordingDuration(maxDuration).onRecordingStopped($scope.endRecording);
      recorder.startRecording();
    };

    $scope.streamFailed = (err: object) => {
      console.error(err);
    };

    $scope.displayRecording = () => {
      const isVideo = constraints.video;
      const blob = recorder.getBlob();
      const url = recorder.toURL();
      const extension = isVideo ? 'webm' : 'wav';
      const mimeType = isVideo ? 'video/webm;codecs=vp8' : 'audio/wav';
      const file = new File([blob], `recording.${extension}`, { type: mimeType, lastModified: Date.now() });

      if (callback) {
        callback({ url, blob, file });
      }
    };

    $scope.setCallback = cbk => {
      callback = cbk;
    };

    $scope.compatibleBrowser = () => {
      const ua = $window.navigator.userAgent.toLowerCase();
      const isIE = !($window.navigator.mediaDevices && $window.navigator.mediaDevices.getUserMedia);
      const isEdge = /edge/.test(ua);
      const isSafari = ua.indexOf('safari') >= 0 && ua.indexOf('chrome') < 0;

      return !(isIE || isEdge || isSafari);
    };

    return $scope;
  }
]);
