ceregoCommon.factory("LtiService", ['$rootScope', '$routeParams', '$location', '$q', 'DebugHelper', 'V3CoursesResource', ($rootScope, $routeParams, $location, $q, DebugHelper, V3CoursesResource) ->
  $scope = $rootScope.$new()
  DebugHelper.register("LtiService", $scope)

  try
    if $routeParams.ltiOutcomeHash
      ltiOutcomeHash = JSON.parse($routeParams.ltiOutcomeHash)
    else if $location.search().lti_outcome_hash
      ltiOutcomeHash = JSON.parse($location.search().lti_outcome_hash)
  catch e
    console.log("ltiOutcomeHash provided but invalid: " + e)

  ltiClientId = parseInt($location.search().lti_client_id) || null
  ltiClientName = $location.search().lti_client_name || null
  ltiGroupId = parseInt($location.search().group_id) || null

  _.assignIn $scope,
    ltiOutcomeHash: ltiOutcomeHash
    ltiClientId: ltiClientId
    ltiClientName: ltiClientName
    ltiGroupId: ltiGroupId
    isBBC: ltiClientId == 183 || ltiClientId == 198 || $routeParams.isBBC == "true"

    isLtiOutcomeRequest: ltiOutcomeHash && ltiOutcomeHash.lis_outcome_service_url && ltiOutcomeHash.lis_result_sourcedid && ltiGroupId

    loadGroup: ->
      unless $scope.deferred
        $scope.deferred = $q.defer()
        if ltiGroupId
          V3CoursesResource.get id: ltiGroupId
          , (success) ->
            $scope.ltiGroup = success.data
            $scope.paymentRequired = $scope.ltiGroup.meta['payment-required']
            $scope.deferred.resolve($scope.ltiGroup)
          , (failure) ->  # THIS SHOULDN'T HAPPEN
            $scope.deferred.reject
        else
          $scope.deferred.resolve({}) #noop, initialize
      $scope.deferred.promise


  $scope.$on '$routeChangeSuccess', ($event, current, previous) ->
    if $routeParams.isBBC
      $scope.isBBC = true

  $scope
])
