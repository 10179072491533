angular.forEach([
  ngAttrName: 'ngXlinkHref'
  attrName: 'xlink:href'
,
  ngAttrName: 'ngWidth'
  attrName: 'width'
,
  ngAttrName: 'ngHeight'
  attrName: 'height'
,
  ngAttrName: 'ngPoints'
  attrName: 'points'
,
  ngAttrName: 'ngCx'
  attrName: 'cx'
,
  ngAttrName: 'ngCy'
  attrName: 'cy'
,
  ngAttrName: 'ngCr'
  attrName: 'r'
,
  ngAttrName: 'ngX1'
  attrName: 'x1'
,
  ngAttrName: 'ngX2'
  attrName: 'x2'
,
  ngAttrName: 'ngY1'
  attrName: 'y1'
,
  ngAttrName: 'ngY2'
  attrName: 'y2'
,
  ngAttrName: 'ngX'
  attrName: 'x'
,
  ngAttrName: 'ngY'
  attrName: 'y'

], (pair) ->
  ngAttrName = pair.ngAttrName
  attrName = pair.attrName

  ceregoCommon.directive(ngAttrName, [->
    priority: 99,

    link: (scope, element, attrs) ->
      attrs.$observe ngAttrName, (value) ->
        return if !value
        attrs.$set(attrName, value)
  ])
)
