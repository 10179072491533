ceregoCommon.directive("datepicker", [->
  restrict: "A"

  scope:
    model: "=datepicker"
    field: "@?"
    future: "@?"
    past: "@?"
    defaultDate: "=?"
    minDate: "=?"
    maxDate: "=?"
    setToEndOfDay: "=?"
    setToStartOfDay: "=?"

  controller: ['$scope', 'DebugHelper', ($scope, DebugHelper) ->
    DebugHelper.register("datepicker", $scope)
  ]

  link: ($scope, element, attrs) ->
    $scope.element = $(element)

    field = $scope.field || "date"
    options = {}
    if $scope.future && $scope.past # Don't disable any past or future date selection
      options.yearRange = '-1:+1'
    else if $scope.future
      options.minDate = new Date()
      options.yearRange = '-0:+3'
    else
      options.defaultDate = "+0"
      options.yearRange = '-3:+0'
      options.maxDate = new Date()

    if $scope.defaultDate
      options.defaultDate = new Date($scope.defaultDate)

    $scope.element.datepicker _.assignIn options,
      changeMonth: true
      changeYear: true
#      datepickerShowing: $scope.datepickerShowing
      dateFormat: 'M d, yy'
      onSelect: (selectedDate) ->
        $scope.$apply ->
          if $scope.setToEndOfDay
            endOfDayTime = new Date(selectedDate).setHours(23,59,59)
            selectedDate = new Date(endOfDayTime)
          else if $scope.setToStartOfDay
            startOfDayTime = new Date(selectedDate).setHours(0,0,0)
            selectedDate = new Date(startOfDayTime)

          $scope.model[field] = if angular.isDate(selectedDate) then selectedDate.toISOString() else selectedDate

    date = if $scope.model[field] then new Date($scope.model[field]) else null
    $scope.element.datepicker "setDate", date

    $scope.$watch "minDate", (newVal, oldVal) ->
      $scope.element.datepicker("option", "minDate", if $scope.minDate then new Date($scope.minDate) else null)

    $scope.$watch "maxDate", (newVal, oldVal) ->
      $scope.element.datepicker("option", "maxDate", if $scope.maxDate then new Date($scope.maxDate) else null)

    $scope.$watch "model." + field, (newVal, oldVal) ->
      unless newVal
        $scope.element.datepicker "setDate", null
])
