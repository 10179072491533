ceregoCommon.directive('cgFlickrSearch', ['FlickrResource', (FlickrResource) ->
  scope:
    cgFlickrSearch: "@"
    version: "="

  templateUrl: (element, $scope) ->
    if $scope.version == "3" then Packs.iKnowRoutes.common_v3_templates_path("flickr") else Packs.iKnowRoutes.v2_template_path("flickr")

  controller: ['$scope', 'DebugHelper','paginationService', ($scope, DebugHelper, paginationService) ->
    DebugHelper.register("cgFlickrSearch", $scope)

    _.assignIn $scope,
      active: false
      page: 1
      perPage: 10
      pages: []
      FlickrResource: FlickrResource
      doSearch: ->
        return if !$scope.query

        $scope.dirty = true
        $scope.active = true
        FlickrResource.search
          text: $scope.query
          page: $scope.page
          per_page: $scope.perPage
        , (result) ->
          $scope.dirty = false
          $scope.numPages = result.photos.pages
          $scope.photos = result.photos.photo
          if $scope.photos.length < $scope.perPage
            $scope.paddedRange = _.range($scope.photos.length, $scope.perPage)
          $scope.totalCount = parseInt(result.photos.total)
          $scope.pages = paginationService.getClassAndPage($scope.page, $scope.numPages)
        , (error) ->
          console.error error

      goToPage: (event, num) ->
        event.preventDefault()
        $scope.page = num
        $scope.doSearch()

      close: ->
        $scope.active = false

      pickPhoto: ($event, photo) ->
        $scope.active = false
        if _.isUndefined(photo.ownername) || _.isNull(photo.ownername)
          photo.ownername = "unknown"

        $scope.$parent.$eval $scope.cgFlickrSearch,
          $event: $event
          $orig_owner: photo.ownername
          $url: photo.url_m
          $license_id: photo.license

      onInput: (event) ->
        event.preventDefault()
        $scope.page = 1
        $scope.query = $scope.element.find("input[type=text]").val()
        $scope.doSearch()
  ]

  link: ($scope, element, attrs) ->
    $scope.element = element
    if $scope.version == 3
      $scope.perPage =  20
])
