ceregoCommon.service('StudyItemsService', ['$rootScope', '$q', 'V3MyStudyItemsResource', 'DebugHelper', 'LtiService', 'setLearnURLService', ($rootScope, $q, V3MyStudyItemsResource, DebugHelper, LtiService, setLearnURLService) ->
  $scope = $rootScope.$new()

  DebugHelper.register("StudyItemsService", $scope)

  _.assignIn $scope,
    ###*
     * Loads the study items
     *
     * @param {sessionContextType} The context in which learning sessions are scoped to (For example: If you start learning from a course everything you learn in that session will be from that course)
     * @param {sessionContextId} The ID of the context that corresponds to the sessionContextType
     * @param {studyType} "recommended", "fading_only", or "force_review"
     * @param {fromPath} The path which you want to return the user to when they click the exit button or the take a break button inside the learn app
     ###
    loadStudyItem: (sessionContextType, sessionContextId, studyType, fromPath) ->
      deferred = $q.defer()
      params = {}

      if sessionContextType == 'sets' # Set assignment page
        params.set_id = sessionContextId
      else if sessionContextType == 'series' # Series assignment page
        params.series_id = sessionContextId
      else if sessionContextType == 'courses' # Course page
        params.group_id = sessionContextId
      else # Dashboard page
        params = {}

      # Don't pass filter[status] if study type is "force_review"
      if studyType == "recommended"
        params['filter[status]'] = 'recommended'
        params['include_scorm'] = true
      else if studyType == "fading_only"
        params['filter[status]'] = 'fading'

      params.include = "collection"

      V3MyStudyItemsResource.query params
      , (success) ->

        studyItem = {}
        #Extract information from study items resource
        success.collections = _.filter(success.included, (obj) ->
          obj.type == "sets" || obj.type == "series"
        )
        studyItem.collectionNames = _.map(success.collections, (obj) ->
          obj.attributes.name
        ).join(', ')
        studyItem.setIds = _.map(_.filter(success.collections, (collection) ->
          collection.type == "sets"
        ), "id")
        studyItem.seriesIds = _.map(_.filter(success.collections, (collection) ->
          collection.type == "series"
        ), "id")

        studyItem.collectionIds = _.uniq(_.map(success.collections, "id"))
        studyItem.unstarted = success.meta["unstarted-items-count"]
        studyItem.fading = success.meta["eligible-items-count"]
        studyItem.showFading = success.meta["show-fading-tab"]
        studyItem.primaryCollection = success.collections[0]
        studyItem.version = success.meta["version"]
        studyItem.bodyTextHtml = success.meta['body-text-html']
        studyItem.buttonText = success.meta['button-text']
        studyItem.result = studyItem.data
        studyItem.valid = (studyItem.fading + studyItem.unstarted) > 0
        studyItem.studySessionId = success.meta["study-session-id"]
        studyItem.sessionLengthSec = success.meta["session-length-seconds"]

        if success.data[0] && success.data[0].relationships["scorm-package"]
          studyItem.scormPackage = success.data[0].relationships["scorm-package"].data

        studyItem.loaded = true
        learnPathId = studyItem.primaryCollection?.id ? sessionContextId
        collectionType = studyItem.primaryCollection?.type ? sessionContextType

        if studyItem.primaryCollection
          studyItem.version = studyItem.primaryCollection.attributes["learn-version"]

        #Fetch a learn path based on the study resource
        if studyItem.scormPackage
          studyItem.learnPath = "#"
        else
          studyItem.learnPath = $scope.getLearnPath(studyItem.collectionIds, studyItem.setIds, collectionType, learnPathId, sessionContextType, sessionContextId, studyType, studyItem.studySessionId, fromPath, studyItem.version)

        deferred.resolve(studyItem)
      deferred.promise

    getRouteType: (hasSingleCollection, collectionType) ->
      routeType = Packs.iKnowRoutes.v2_learn_path
      if hasSingleCollection
        if (collectionType == 'sets' || collectionType == 'courses')
          routeType = Packs.iKnowRoutes.quiz_v2_set_learn_path
        else if collectionType == 'series'
          routeType = Packs.iKnowRoutes.quiz_v2_series_learn_path
      routeType

    ###*
     * Function to calculate the smart learn study path
     * @param {collectionIds} List of all collectionIds that those guids belong to
     * @param {setIds} Subset of collectionIds that are specifically sets - needed for the after study session card which can currently only handle sets
     * @param {collectionType} This is the collection type of the first collectionId in collectionIds, it is only required if there is exactly 1 collectionId or the sessionContextType if no primary collection exists
     * @param {learnPathId} This is the collection id of the first collectionId in collectionIds or the sessionContextId in the case where no primary collection exists
     * @param {sessionContextType} The context in which learning sessions are scoped to (For example: If you start learning from a course everything you learn in that session will be from that course)
     * @param {sessionContextId} The ID of the context that corresponds to the sessionContextType
     * @param {studySessionId} The ID of the study session (for loading the exact saved study session)
     * @param {fromPath} The path which you want to return the user to when they click the exit button or the take a break button inside the learn app
     * @param {studyType} The type of study to perform
     ###
    getLearnPath: (collectionIds, setIds, collectionType, learnPathId, sessionContextType, sessionContextId, studyType, studySessionId, fromPath, version) ->
      hasSingleCollection = collectionIds.length == 1
      routeType = $scope.getRouteType(hasSingleCollection, collectionType)

      params = { contextId: sessionContextId, contextType: sessionContextType, v3StudyType: studyType, returnURL: fromPath, setIds: setIds, skipSessionSelect: true, studySessionId: studySessionId }
      if LtiService.isBBC
        params['isBBC'] = true
      if hasSingleCollection && version == 4
        setLearnURLService.getUrl({id: parseInt(learnPathId), version: 4}, { studyType: studyType })
      else if hasSingleCollection
        routeType(learnPathId, params)
      else
        if sessionContextType == 'courses'
          params['groupId'] = sessionContextId
        routeType(params)

    # Smart learn paths for an assignment context are fixed, and don't require fetching anything to know what the path should be
    getAssignmentLearnPath: (assignmentType, assignmentId, studyType, fromPath, version) ->
      setIds = if assignmentType == 'sets' then [assignmentId] else []
      $scope.getLearnPath([assignmentId], setIds, assignmentType, assignmentId, assignmentType, assignmentId, studyType, null, fromPath, version)

  $scope
])
