angular.module('cerego.common').factory('PartnerAccountResource', [
  '$resource',
  ($resource: ng.resource.IResourceService) =>
    $resource(
      '',
      {},
      {
        create: {
          method: 'POST',
          isArray: false,
          url: APIRoutes.v3_partner_account_path(':partner_id'),
          params: {
            partner_id: '@partner_id'
          }
        },
        get: {
          method: 'GET',
          isArray: false,
          url: APIRoutes.v3_partner_account_path(':partner_id'),
          params: {
            partner_id: '@partner_id',
            id: '@id'
          }
        },
        delete: {
          method: 'DELETE',
          url: APIRoutes.v3_partner_account_path(':partner_id'),
          params: {
            partner_id: '@partner_id',
            id: '@id'
          }
        }
      }
    )
]);
