ceregoCommon.factory('SubscriptionsResource', ['$resource', ($resource) ->
  $resource "", {},
    query:
      method: 'GET'
      isArray: false
      url: APIRoutes.v2_subscriptions_path()
    update:
      method: 'PUT'
      isArray: false
      url: APIRoutes.v2_subscriptions_path()
    delete:
      method: 'DELETE'
      isArray: false
      url: APIRoutes.v2_subscriptions_path()
])
