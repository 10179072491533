ceregoCommon.factory('MySeriesResource', ['$resource', ($resource) ->
  $resource "", {},
    query:
      method: 'GET'
      isArray: false
      url: APIRoutes.v2_my_series_index_path()
    create:
      method: 'POST'
      isArray: false
      url: APIRoutes.v2_series_index_path()
    addSet:
      method: 'POST'
      isArray: false
      url: APIRoutes.add_set_v2_my_series_path(":id")
      params:
        id: "@id"
    removeSet:
      method: 'DELETE'
      isArray: false
      url: APIRoutes.remove_set_v2_my_series_path(":id")
      params:
        id: "@id"
    addToSchedule:
      method: 'POST'
      isArray: false
      url: APIRoutes.add_to_memory_bank_v2_my_series_path(":id")
      params:
        id: "@id"
    removeFromSchedule:
      method: 'DELETE'
      isArray: false
      url: APIRoutes.remove_from_memory_bank_v2_my_series_path(":id")
      params:
        id: "@id"
    addToLibrary:
      method: 'POST'
      isArray: false
      url: APIRoutes.add_to_library_v2_my_series_path(":id")
      params:
        id: "@id"
    removeFromLibrary:
      method: 'DELETE'
      isArray: false
      url: APIRoutes.remove_from_library_v2_my_series_path(":id")
      params:
        id: "@id"
])
