ceregoCommon.directive("privacyTypeSelector", [->
  restrict: "E"
  scope:
    module: "="

  templateUrl: Packs.iKnowRoutes.v2_template_path("privacy_type_selector")

  controller: ['$scope', 'DebugHelper', 'myPartnersService', 'UserManager', ($scope, DebugHelper, myPartnersService, UserManager) ->
    DebugHelper.register("privacyTypeSelector", $scope)

    _.assignIn $scope,
      setPrivacy: (privacy) ->
        switch privacy
          when "Public"
            $scope.requireCaptcha = true
            return
          when "Private"
            privacy_type_id = 1
          when "Course Only"
            privacy_type_id = 4
        $scope.module.privacy_type =
          id: privacy_type_id
          name: privacy

      shouldShowCantGoPublicMessage: ->
        $scope.module.module_type == 'set' && $scope.module.memories_count < 5 && ($scope.module.permissions && $scope.module.permissions.public_toggleable)

      cantGoPublic: ->
        (UserManager.currentUser?.attributes?["profile-overrides"]?["public-user-content-disabled"]) ||
          ($scope.module.module_type == 'set' && ($scope.module.studiable_memories_count < 5 || ($scope.module.permissions && !$scope.module.permissions.public_toggleable)))

      onCaptchaSuccess: (response) ->
        $scope.requireCaptcha = false
        $scope.module.privacy_type =
          id: 3
          name: "Public"

    myPartnersService.initialize().then () ->
      $scope.canSetGroup = myPartnersService.currentProductPartner?.meta?["can-manage-content"]
  ]

  link: ($scope, element, attrs) ->
    element.addClass("radio-toggle--container")
])
