ceregoCommon.factory('V3SetsResource', ['$resource', ($resource) ->
  $resource "", {},
    create:
      method: 'POST'
      isArray: false
      url: APIRoutes.v3_sets_path()
    get:
      method: 'GET'
      isArray: false
      url: APIRoutes.v3_set_path(":id")
      params:
        group_id: "@id"
    update:
      method: 'PUT'
      isArray: false
      url: APIRoutes.v3_set_path(":id")
      params:
        id: "@id"
    destroy:
      method: 'DELETE'
      isArray: false
      url: APIRoutes.v3_set_path(":id")
      params:
        id: "@id"

])
