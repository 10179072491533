ceregoCommon.directive('scormUploader', ['V3MediaUploader', (V3MediaUploader) ->
  restrict: "E"
  scope:
    model: "="
    version: "="
  templateUrl: Packs.iKnowRoutes.common_v3_templates_path("scorm_uploader")

  controller: ['$scope', 'DebugHelper', 'UploaderService', '$filter', '$timeout', 'ScormPackagesResource', ($scope, DebugHelper, UploaderService, $filter, $timeout, ScormPackagesResource) ->
    DebugHelper.register("scormUploaderScope", $scope)

    UploaderService.scormUploader = $scope

    _.assignIn $scope,
      importJobStatus: {
        SUCCESS:      "COMPLETE",
        IN_PROGRESS:  "RUNNING",
        ERROR:        "ERROR"
      }

      uiState: {}

      getFileName: (file) ->
        extension_index = file.name.lastIndexOf('.')
        if extension_index != -1
          return file.name.slice(0, extension_index)
        else
          return file.name

      onScormUploadProgress: (progress) ->
        $scope.$apply ->
          $scope.uiState.uploadPercentage = Math.round(100 * progress.loaded / progress.total)

      uploadScorm: ($file) ->
        if $file.type != "application/zip" && $file.type != "application/x-zip-compressed"
          $scope.dragover = false
          $scope.uiState.uploadError = true
          return
        else
          $scope.uiState.uploadError = false
          $scope.uiState.uploadInProgress = true
          $timeout ->
            $scope.fileName = $scope.getFileName($file)
            $scope.file = $file
            params = {file: $file}
            V3MediaUploader(params, "scorm_zip", $scope.onScormUploadProgress)
            # logic of uploading
            .then (response) ->
              $scope.scormPackage = response.data.data
              $scope.checkImportJobUntilComplete($scope.scormPackage.id)
              UploaderService.scormUploader.scorm_package_id = $scope.scormPackage.id # do I still need this
            .finally (result) ->
              $scope.uiState.uploadInProgress = false
            .catch (error) ->
              # Error on our side posting to AWS
              $scope.uiState.uploadError = true
              DebugHelper.logError(error, 'Error trying to upload file to AWS from SCORM uploader')

      checkImportJobUntilComplete: (package_id) ->
        ScormPackagesResource.check_import_job
          id: package_id
        , (success) ->
          switch success.response.status
            when $scope.importJobStatus.SUCCESS
              $scope.uiState.uploadInProgress = false
              $scope.uiState.uploadCompleted = true
            when $scope.importJobStatus.IN_PROGRESS
              $timeout($scope.checkImportJobUntilComplete($scope.scormPackage.id), 5000) # TODO exponential backoff
            when $scope.importJobStatus.ERROR
              # Successfully uploaded to AWS and asked API to check import, but error in SCORM engine import
              $scope.uiState.uploadInProgress = false
              $scope.uiState.uploadError = true # TODO different error messages
            else
              DebugHelper.logError('Unexpected response ' + success.response.status + ' from SCORM engine import job check ')
        , (error) ->
          #something unexpected wrong with request to our API
          $scope.uiState.uploadInProgress = false
          $scope.uiState.uploadError = true
          DebugHelper.logError(error, 'Error trying to check import job for SCORM package ' + $scope.scormPackage['id'])


      handleDragLeave: ($event) ->
        $timeout ->
          $scope.dragover = false

      handleDragOver: ($event) ->
        $timeout ->
          $scope.dragover = true

      hideError: () ->
        $scope.uiState.uploadError = false

      reset: ->
        $scope.file = {}
        $scope.fileName = ""
        $scope.uiState = {}
  ]

  link: ($scope, element, attrs) ->
    $scope.el = element

    element[0].addEventListener('dragleave', $scope.handleDragLeave, false)
    element[0].addEventListener('dragover', $scope.handleDragOver, false)

])
