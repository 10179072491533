/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
ceregoCommon.controller('shareLinkModal', ['$window', '$scope', 'DebugHelper', function($window, $scope, DebugHelper) {
  DebugHelper.register("shareLinkModal", $scope);

  _.assignIn($scope, {
    updateLink(selectedSize) {
      const learnAppLink = $window.location.origin + ($scope.module.module_type === "series" ? Packs.iKnowRoutes.v2_series_learn_path($scope.module.id) : Packs.iKnowRoutes.v2_set_learn_path($scope.module.id));
      return $scope.iframeLink = $("<div>").append($("<iframe>").attr({ width: selectedSize.width, height: selectedSize.height, src: learnAppLink })).html();
    },

    initialize() {
      $scope.module = $scope.modalOptions.module;
      $scope.iframeSizes = [ { label: "768 x 600", width: "768", height: "600"},
        { label: "960 x 700", width: "960", height: "700" },
        { label: "1280 x 800", width: "1280", height: "800" }];

      $scope.selectedSize = $scope.iframeSizes[0];
      $scope.path = $window.location.origin + ($scope.module.modulePath || $window.location.pathname);
      return $scope.updateLink($scope.selectedSize);
    }

  });

  return $scope.initialize();
}
]);
