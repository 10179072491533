import * as angular from 'angular';

export interface IFreeTrialModalService {
  isPartnerExpired: (partner: v3.resource.IPartner) => boolean;
  shouldDisplayFreeTrialExpiredModal: (
    allPartnersExpired: boolean,
    currentProduct: string,
    currentPartner?: v3.resource.IPartner
  ) => boolean;
  displayFreeTrialExpiredModal: () => void;
}

angular.module('cerego.common').service('FreeTrialModalService', [
  '$analytics',
  'ModalService',
  ($analytics, ModalService) => {
    const service: IFreeTrialModalService = {
      isPartnerExpired: partner => {
        const expiresAt = partner.attributes['expires-at'];
        return expiresAt != null && new Date(expiresAt) < new Date();
      },
      shouldDisplayFreeTrialExpiredModal: (allPartnersExpired, currentProduct, currentPartner) => {
        const isLearn = currentProduct === 'learn';
        const currentPartnerIsExpired = currentPartner != null && service.isPartnerExpired(currentPartner);

        return currentPartnerIsExpired && (allPartnersExpired || !isLearn);
      },
      displayFreeTrialExpiredModal: () => {
        ModalService.showModal('free-trial-expired-modal', { noCloseButton: true, excludeController: true });
        $analytics.pageTrack('/freeTrialExpiredModal');
      }
    };

    return service;
  }
]);
