ceregoCommon.factory('SetExportsResource', ['$resource', ($resource) ->
  $resource "",{},
    get:
      method: 'GET'
      isArray: false
      url: APIRoutes.v2_set_export_path(":id")
      params:
        id: "@id"
    create:
      method: 'POST'
      isArray: false
      url: APIRoutes.v2_set_exports_path()
])
