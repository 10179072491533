ceregoCommon.service("NewSetService", ['$rootScope', '$filter', 'SetsResource', 'V3PartnersResource', 'V3ModelHelper', 'UserManager', '$routeParams', 'myPartnersService', '$window', ($rootScope, $filter, SetsResource, V3PartnersResource, V3ModelHelper, UserManager, $routeParams, myPartnersService, $window) ->
  $scope = $rootScope.$new()

  window.debug = window.debug || {}
  window.debug["NewSetService"] = $scope

  _.assignIn $scope,
    title: $filter("translate")("js.module_view.new_set.title")
    routes: Packs.iKnowRoutes
    uiState: {}
    resource: SetsResource
    module_type: "set"

    path: (version) ->
      if version == 4 then Packs.iKnowRoutes.edit_v4_edit_set_path else Packs.iKnowRoutes.edit_v3_create_set_path

    createNewSet: (setType, isTour) ->
      learnVersion = $scope.partner?.attributes['partner-settings']?['learn-version'] ||
        UserManager.currentUser.relationships['primary-partner']?.data?.attributes['partner-settings']?['learn-version']

      switch setType
        when 'set'
          if learnVersion == 3
            return $window.location.href = Packs.iKnowRoutes.new_v2_set_path()
          name = $filter('translate')("js.v4_item_editor.header.untitled_set")
          UserManager.trackSegment('Button Clicked', { assignment_type: 'set' })
        when 'assessment'
          if learnVersion == 3
            throw 'Cannot create assessment in v3'
          name = $filter('translate')("js.v4_item_editor.header.untitled_assessment")
          UserManager.trackSegment('Button Clicked', { assignment_type: 'assessment' })
        when 'survey'
          if learnVersion == 3
            throw 'Cannot create survey in v3'
          name = $filter('translate')("js.v4_item_editor.header.untitled_survey")
          UserManager.trackSegment('Button Clicked', { assignment_type: 'survey' })
        else
          throw 'Invalid setType'

      params = {
        'learn_version': learnVersion,
        'goal_type': setType,
        'name': name,
        'partner_id': $scope.partner && $scope.partner.id
      }

      SetsResource.create {}, params, (result) ->
        queryParameters = if isTour then { tour: true } else {}
        $window.location.href = Packs.iKnowRoutes.edit_v4_edit_set_path(result.response.id, queryParameters)


    initializePartner: (partner) ->
      $scope.partner = partner

    initialize: ->
      UserManager.loadUser().then (user) ->
        if !user.id
          $window.location.href = Packs.iKnowRoutes.v3_signin_path()
        else if $routeParams.parentId
          V3PartnersResource.get(id: $routeParams.parentId, (success) ->
            $scope.initializePartner V3ModelHelper.parse(success)
          ).$promise
        else
          myPartnersService.initialize().then () ->
            $scope.initializePartner myPartnersService.currentProductPartner

  $scope.initialize()
  $scope
])
