/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
ceregoCommon.controller("confirmEmailModal", ['$scope', 'DebugHelper', 'UserManager', 'ModalService', 'EmailsResource', function($scope, DebugHelper, UserManager, ModalService, EmailsResource) {
  DebugHelper.register("confirmEmailModal", $scope);

  return _.assignIn($scope, {
    UserManager,

    manageEmails() {
      $scope.closeModal();
      return ModalService.showModal('user-profile-edit-modal');
    },

    confirmEmail() {
      $scope.confirmingEmail = true;
      return EmailsResource.send_confirmation({}
      , function(success) {
        $scope.confirmingEmail = false;
        return $scope.closeModal();
      }
      , function(error) {
        console.error(error);
        return $scope.confirmingEmail = false;
      });
    }
  }
  );
}
]);
