ceregoCommon.factory("InstructionalContentService", ['$rootScope', '$q', 'DebugHelper', 'ItemsResource', 'V3SetItemsResource', 'V3SeriesItemsResource', 'V3ModelHelper', ($rootScope, $q, DebugHelper, ItemsResource, V3SetItemsResource, V3SeriesItemsResource, V3ModelHelper) ->
  $scope = $rootScope.$new()

  DebugHelper.register("InstructionalContentService", $scope)

  _.assignIn $scope,
    instructionalContent: []

    showItem: (item) ->
      $scope.currentItem = item
      # we expect this to be called by the controller on load, but just in case
      $scope.getInstructionalContent().then ->
        $rootScope.$broadcast("instructionalContent:showItem", item)

    setContext: (context) ->
      $scope.context = context

    addInstructionalContentItem: (item) ->
      $scope.instructionalContent.push(item)

    getInstructionalContent: () ->
      unless $scope.deferred
        $scope.deferred = $q.defer()
        if $scope.context?.module_type == "set"
          V3SetItemsResource.query
            set_id: $scope.context.id
            include:  "sentences,annotations,annotations.document,annotations.image,annotations.sound,annotations.video"
            'filter[type][]': ["InstructionalContent"]
            'page[size]': 200
          , (success) ->
            $scope.instructionalContent = V3ModelHelper.parse(success)
            $scope.deferred.resolve($scope.instructionalContent)
        else if $scope.context?.module_type == "series"
          V3SeriesItemsResource.query
            series_id: $scope.context.id
            include:  "sentences,annotations,annotations.document,annotations.image,annotations.sound,annotations.video"
            'filter[type][]': ["InstructionalContent"]
            'page[size]': 200
          , (success) ->
            $scope.instructionalContent = V3ModelHelper.parse(success)
            $scope.deferred.resolve($scope.instructionalContent)
        else #study all
          $scope.deferred.resolve([])
      $scope.deferred.promise

    hasInstructionalContent: ->
      $scope.instructionalContent?.length > 0

    showInstructionalContent: ->
      $scope.currentItem = null
      $rootScope.$broadcast "instructionalContent:showList"

    exitInstructionalContent: ->
      $rootScope.$broadcast "instructionalContent:hideList"

  $scope
])
