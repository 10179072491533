ceregoCommon.factory('V3OAuthResource', ['$resource', ($resource) ->
  $resource "", {},
    authenticate:
      method: 'POST'
      isArray: false
      url: APIRoutes.authenticate_v3_oauth_index_path()
    delete:
      method: 'DELETE'
      isArray: true
      url: APIRoutes.v3_oauth_path(":provider")
      params:
        provider: "@provider"
])
