ceregoCommon.directive('cgTranscodeBackgroundImage', ['$filter', '$window', ($filter, $window) ->
  ($scope, element, attrs) ->
    $scope.$watch attrs['cgTranscodeBackgroundImage'], (image) ->
      dimensions = attrs.cgSize?.split('x').map((size) -> (parseInt(size, 10)))
      size = if dimensions then Math.max(dimensions[0], dimensions[1]) else 800
      image_url = $window.getTranscodedURL(image, size) || attrs.cgDefaultImage
      return if !image_url

      element.css "background-image", "url(" + image_url + ")"
])
