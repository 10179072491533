ceregoCommon.factory('SetsResource', ['$resource', ($resource) ->
  $resource "",{},
    get:
      method: 'GET'
      isArray: false
      url: APIRoutes.v2_set_path(":id")
      params:
        id: "@id"
    series:
      method: 'GET'
      isArray: false
      url: APIRoutes.series_v2_set_path(":id")
      params:
        id: "@id"
    create:
      method: 'POST'
      isArray: false
      url: APIRoutes.v2_sets_path()
    update:
      method: 'PUT'
      isArray: false
      url: APIRoutes.v2_set_path(":id")
      params:
        id: "@id"
    delete:
      method: 'DELETE'
      isArray: false
      url: APIRoutes.v2_set_path(":id")
      params:
        id: "@id"
    insert_at:
      method: 'PUT'
      isArray: false
      url: APIRoutes.insert_at_v2_series_set_path(":series_id", ":set_id")
      params:
        series_id: "@series_id"
        set_id: "@set_id"
    itemUploads:
      method: 'POST'
      isArray: false
      url: APIRoutes.v2_set_item_uploads_path(":set_id")
      params:
        set_id: "@set_id"
])
