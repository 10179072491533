ceregoCommon.directive('v3ImageBox', [ ->
  restrict: "E"
  scope:
    model: "="
    dynamicHeight: "<?"
    forcedHeightClass: "<?"
    saveFunction: "&?"
    allowFormula: "=?"
    hideTrashcan: "=?"
    noteId: "=?"

  templateUrl: (element, $scope) ->
    Packs.iKnowRoutes.common_v3_templates_path("image_box")

  controller: ['$scope', 'DebugHelper', 'ModalService', ($scope, DebugHelper, ModalService) ->
    DebugHelper.register("v3ImageBox", $scope)

    _.assignIn $scope,
      launchModal: () ->
        ModalService.showModal('image-uploader-modal', { allowFormula: $scope.allowFormula, model: $scope.model, saveFunction: $scope.saveFunction, description: 'Upload your image below.' })

      resetModel: ($event) ->
        $scope.model = { data: { id: null } }
        if $scope.saveFunction
          $scope.saveFunction(updatedImageId: null)
        $event.preventDefault()
        $event.stopPropagation()

    $scope.$on "launchNoteEditModal", (event, opt) ->
      $scope.launchModal() if opt.id == $scope.noteId
  ]

  link: ($scope, element, attrs) ->
    # TODO Implement or delete

])
