ceregoCommon.factory('MySetsResource', ['$resource', ($resource) ->
  $resource "", {},
    query:
      method: 'GET'
      isArray: false
      url: APIRoutes.v2_my_sets_path()
    addToSchedule:
      method: 'POST'
      isArray: false
      url: APIRoutes.add_to_memory_bank_v2_my_set_path(":id")
      params:
        id: "@id"
    removeFromSchedule:
      method: 'DELETE'
      isArray: false
      url: APIRoutes.remove_from_memory_bank_v2_my_set_path(":id")
      params:
        id: "@id"
    addToLibrary:
      method: 'POST'
      isArray: false
      url: APIRoutes.add_to_library_v2_my_set_path(":id")
      params:
        id: "@id"
    removeFromLibrary:
      method: 'DELETE'
      isArray: false
      url: APIRoutes.remove_from_library_v2_my_set_path(":id")
      params:
        id: "@id"
])
