ceregoCommon.directive('cgMoreButton', ['$window', ($window) ->
  restrict: "E"

  link: ($scope, element, attrs) ->
    element.addClass "alt-button alt-button--secondary"

    timer = false

    element.on "mouseover", ->
      element.addClass "expanded"
      if timer
        $window.clearTimeout timer
      timer = $window.setTimeout ->
        element.removeClass "expanded"
      , 1000
])
