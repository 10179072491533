import * as angular from 'angular';
import { ITokenExchangeSessionService } from 'v2/modules/common/token-exchange-session-service';
import { IDebugHelper } from 'v2/modules/core/debug-helper';

export interface ILtiRedirectService extends ng.IScope {
  exchangeTokenAndRedirect: () => void;
}

angular.module('cerego.common').service('LtiRedirectService', [
  '$location',
  '$rootScope',
  'TokenExchangeSessionService',
  (
    $location: ng.ILocationService,
    $rootScope: ng.IRootScopeService,
    TokenExchangeSessionService: ITokenExchangeSessionService
  ) => {
    const $scope = $rootScope.$new() as ILtiRedirectService;

    $scope.exchangeTokenAndRedirect = () => {
      const tempLogintoken = $location.search().temp_login_token;

      if (tempLogintoken) {
        const redirectUrl = $location.search().redirect_url;
        const redirectSearch = $location.search();
        delete redirectSearch.temp_login_token;
        delete redirectSearch.redirect_url;

        TokenExchangeSessionService.exchangeTokenForOAuth(tempLogintoken).finally(() =>
          $location.path(redirectUrl).search(redirectSearch)
        );
      } else {
        throw new Error('Missing temp login token');
      }
    };
    return $scope;
  }
]);
