ceregoCommon.factory('ScormPackagesResource', ['$resource', ($resource) ->
  $resource "",{},
    check_import_job:
      method: 'GET'
      isArray: false
      url: APIRoutes.v3_scorm_package_import_status_path(":id")
      params:
        id: "@id"
    get_launch_link:
      method: 'GET'
      isArray: false
      url: APIRoutes.v3_scorm_package_launch_link_path(":id")
      params:
        id: "@id"
])
