ceregoCommon.factory('PartnerUserRolesResource', ['$resource', ($resource) ->
  $resource "", {},
    add:
      method: 'POST'
      isArray: false
      url: APIRoutes.v3_partner_partner_user_roles_path(":partner_id")
      params:
        partner_id: "@partner_id"
    roles:
      method: 'GET'
      isArray: false
      url: APIRoutes.v3_partner_partner_user_roles_path(":partner_id")
      params:
        partner_id: "@partner_id"
    update:
      method: 'PUT'
      url: APIRoutes.v3_partner_user_role_path(":id")
      params:
        id: "@id"
    remove_role:
      method: 'DELETE'
      url: APIRoutes.v3_partner_user_role_path(":id")
      params:
        id: "@id"
])
