ceregoCommon.service("SentenceConverter", [->
  service =
    text2html: (text, word) ->
      return unless text?
      manual_word = /\*(.*)\*/
      if word
        safe_word = word.replace(/([\*\+\[\]\(\)\$\^])/g, "\\$1")
        exact_match = RegExp("\\b(" + safe_word + ")\\b", "i")
        contains_match = RegExp("\\b(\\w*" + safe_word + "\\w*)\\b", "i")
      replacement = "<b>$1</b>"
      if text.match(manual_word)
        text.replace(manual_word, replacement)
      else if exact_match && text.match(exact_match)
        text.replace(exact_match, replacement)
      else if contains_match && text.match(contains_match)
        text.replace(contains_match, replacement)
      else
        text

    html2markup: (text) ->
      (text || '').replace(/<.?b>/g, '*')
])
