import { createWysiwygQuill, overrideEnterKey } from 'util/quill-helper'

ceregoCommon.directive("cgWysiwyg", ['$window', ($window) ->
  restrict: "E"
  scope:
    placeholder: "@"
    includeLinks: "=?"
    includeLists: "=?"
    includeUnderline: '=?'
    model: "="
    onPaste: "&?"
    autofocus: "=?"
    onChange: "&?"
    onFocus: "&?"
    hideToolbar: "=?"
    autowidth: "=?"
    onEnterKey: "=?"

  templateUrl: Packs.iKnowRoutes.common_v3_templates_path("wysiwyg")

  controller: ['$scope', '$element', 'DebugHelper',
    ($scope, $element, DebugHelper) ->
      DebugHelper.register("cgWysiwyg", $scope)

      _.assignIn $scope, {
        editorChanged: false
        bottomBarHeight: 20
        minHeight: 65
        currentWidth: 0

        updateWidth: ->
          return if $scope.autowidth # if true, obey flex sizing of parent instead of manually setting width on element

          quillElem = $scope.quill.root
          html = quillElem.innerHTML

          quillElem.innerHTML = ''
          quillElem.style.width ='auto'
          quillElem.innerHTML = html
          quillElem.style.width = quillElem.clientWidth + 'px'
          $scope.currentWidth = quillElem.clientWidth
      }
  ]

  link: ($scope, element, attrs) ->
    $scope.quill = createWysiwygQuill(element.children(".quill-editor-container")[0], element.children(".quill-toolbar")[0], $scope.placeholder)
    $scope.updateWidth()

    if $scope.model
      $scope.quill.clipboard.dangerouslyPasteHTML($scope.model)

    element.on 'focusin', (event) ->
      element.addClass("focus")
      if $scope.onFocus
        $scope.onFocus()

    element.on 'focusout', (event) ->
      setTimeout () ->
        if !(element.find(':focus').length)
          element.removeClass("focus")

    angular.element($window).bind 'resize', ->
      $scope.updateWidth()
      $scope.$digest() # manual $digest required as resize event is outside of angular

    $scope.$watch 'model', (newText, oldText) ->
      if newText != oldText
        if $scope.onChange
          $scope.onChange()
        content = newText
        if $scope.quill && !$scope.editorChanged
          if content
            $scope.quill.clipboard.dangerouslyPasteHTML(content)
          else
            $scope.quill.setText('')
        $scope.editorChanged = false

    $scope.quill.on 'text-change', (delta, oldDelta, source) ->
      $scope.onChange() if $scope.onChange

      html = if $scope.quill.root.innerText.trim().length > 0
              $scope.quill.root.innerHTML
            else
              ''

      $scope.$applyAsync () ->
        $scope.editorChanged = true
        $scope.model = html

    if $scope.onPaste
      $scope.quill.root.addEventListener 'paste', () -> $scope.onPaste()

    if $scope.onEnterKey
      overrideEnterKey($scope.quill, () -> $scope.onEnterKey(attrs.model))

    if $scope.autofocus
      angular.element('.ql-editor', element).focus()

])
