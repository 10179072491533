import ImageEditor from 'tui-image-editor'
import icona from 'tui-image-editor/dist/svg/icon-a.svg'
import iconb from 'tui-image-editor/dist/svg/icon-b.svg'
import iconc from 'tui-image-editor/dist/svg/icon-c.svg'
import icond from 'tui-image-editor/dist/svg/icon-d.svg'

ceregoCommon.controller("imageUploaderModal", ['$scope', '$timeout', '$filter', 'DebugHelper', 'V3MediaUploader', 'V3ImagesResource', 'AmplitudeService', ($scope, $timeout, $filter, DebugHelper, V3MediaUploader, V3ImagesResource, AmplitudeService) ->
  DebugHelper.register("imageUploaderModal", $scope)
  STEPS = {
    UPLOAD: 1
    EDIT: 2
    ALTER: 3
  }
  _.assignIn $scope,
    activeModel: {}
    STEPS: STEPS
    stateUI:
      currentStep: STEPS.UPLOAD
    form: {}
    blockingObject:
      block: true


    insertImage: ->
      _.assign($scope.model, $scope.activeModel)

      if $scope.modalOptions.saveFunction
        $scope.modalOptions.saveFunction(updatedImageId: $scope.activeModel.data.id)

      if $scope.form.imageForm.$dirty
        V3ImagesResource.update id: $scope.model.data.id
        , $scope.model
        , (success) ->
          $scope.closeModal()
      else
        $scope.closeModal()

    setCurrentStep: () ->
      if $scope.activeModel.data?.id
        $scope.stateUI.currentStep = STEPS.EDIT
      else
        $scope.stateUI.currentStep = STEPS.UPLOAD

    showAlterView: ($event) ->
      $event.preventDefault()
      $scope.stateUI.currentStep = STEPS.ALTER
      # .tui-image-editor needs to be visible to be properly initialized
      $timeout ->
        # pass translation into tui-image-editor node module
        locale = {
          "Apply": $filter('translate')("js.common.image_uploader_modal.image_editor.apply"),
          "Cancel": $filter('translate')("js.common.image_uploader_modal.image_editor.cancel"),
          "Circle": $filter('translate')("js.common.image_uploader_modal.image_editor.circle"),
          "Crop": $filter('translate')("js.common.image_uploader_modal.image_editor.crop"),
          "Custom": $filter('translate')("js.common.image_uploader_modal.image_editor.custom"),
          "Fill": $filter('translate')("js.common.image_uploader_modal.image_editor.fill"),
          "Rectangle": $filter('translate')("js.common.image_uploader_modal.image_editor.rectangle"),
          "Redo": $filter('translate')("js.common.image_uploader_modal.image_editor.redo"),
          "Reset": $filter('translate')("js.common.image_uploader_modal.image_editor.reset"),
          "Shape": $filter('translate')("js.common.image_uploader_modal.image_editor.shape"),
          "Square": $filter('translate')("js.common.image_uploader_modal.image_editor.square"),
          "Stroke": $filter('translate')("js.common.image_uploader_modal.image_editor.stroke"),
          "Triangle": $filter('translate')("js.common.image_uploader_modal.image_editor.triangle"),
          "Undo": $filter('translate')("js.common.image_uploader_modal.image_editor.undo")
        }
        iconTheme = {}
        iconTheme['menu.normalIcon.path'] = icond
        iconTheme['menu.activeIcon.path'] = iconb
        iconTheme['menu.disabledIcon.path'] = icona
        iconTheme['menu.hoverIcon.path'] = iconc
        iconTheme['submenu.normalIcon.path'] = icond
        iconTheme['submenu.activeIcon.path'] = iconb

        $scope.imageEditor = new ImageEditor(document.querySelector('.tui-image-editor'), {
          includeUI: {
            loadImage: {
              path: $scope.activeModel.data.attributes.url,
              name: "ImageUploaderImage"
            },
            locale: locale
            menu: ['shape', 'crop'],
            theme: iconTheme
          },
          usageStatistics: false
        })
        $scope.imageEditor.ui.shape.options = {stroke: "transparent", fill: "black", strokeWidth: 3}

    saveAlteredImage: ($event) ->
      $event.preventDefault()
      AmplitudeService.logEvent("Image Edited");
      dataURL = $scope.imageEditor.toDataURL()
      type = dataURL.substr(5, dataURL.indexOf(";") - 5)  # Get meta data from dataURL
      data = dataURL.slice(dataURL.indexOf(",") + 1, dataURL.length)
      params = {
        data: data
        content_type: type
        alt_tag: $scope.activeModel.data.attributes["alt-tag"]
        license_id: $scope.activeModel.data.attributes["license-id"]
        orig_url: $scope.activeModel.data.attributes["orig-url"]
        orig_owner: $scope.activeModel.data.attributes["orig-owner"]
      }
      V3MediaUploader(params, "image")
      .then (response) ->
        $scope.activeModel.data = response.data.data
        $scope.stateUI.currentStep = STEPS.EDIT

  $scope.model = $scope.modalOptions.model
  $scope.activeModel = _.cloneDeep($scope.model)

  $scope.setCurrentStep()

  $scope.$watch "activeModel.data", (newVal, oldVal) ->
    if newVal != oldVal
      $scope.setCurrentStep()

])
