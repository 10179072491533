ceregoCommon.factory('GroupsSetsResource', ['$resource', ($resource) ->
  $resource "", {},
    save:
      method: 'POST'
      isArray: false
      url: APIRoutes.v2_group_sets_path(":group_id")
      params:
        group_id: "@group_id"
    update:
      method: 'PUT'
      isArray: false
      url: APIRoutes.v2_group_sets_path(":group_id")
      params:
        group_id: "@group_id"
    remove:
      method: 'DELETE'
      isArray: true
      url: APIRoutes.v2_group_sets_path(":group_id")
      params:
        group_id: "@group_id"
    get:
      method: 'GET'
      isArray: false
      url: APIRoutes.v2_group_sets_path(":group_id")
      params:
        group_id: "@group_id"
])
