ceregoCommon.directive("noteViewer", [->
  restrict: "E"
  scope:
    item: "="
    currentQuiz: "=?"
    facet: "="

  templateUrl: Packs.iKnowRoutes.common_v3_templates_path("note_viewer")

  controller: ['$scope', 'DebugHelper', ($scope, DebugHelper) ->
    DebugHelper.register("noteViewer", $scope)

    _.assignIn $scope,
      uiState: {}

      isFullEmbed: (annotation) ->
        annotation.external_resource_url?.match(/steinhardtapps\.es\.its\.nyu\.edu/) ||
          annotation.external_resource_url?.match(/invis\.io/) ||
            annotation.external_resource_url?.match(/docs\.google\.com/) ||
        annotation.attributes?["external-resource-url"]?.match(/steinhardtapps\.es\.its\.nyu\.edu/) ||
          annotation.attributes?["external-resource-url"]?.match(/invis\.io/) ||
            annotation.attributes?["external-resource-url"]?.match(/docs\.google\.com/)

      annotationImageUrl: (annotation) ->
        annotation.image?.url || annotation.relationships?.image?.data?.attributes?.url

      annotationSoundUrl: (annotation) ->
        annotation.sound?.url || annotation.relationships?.sound?.data?.attributes?.url
        
      annotationVideoUrl: (annotation) ->
        annotation.video?.url || annotation.relationships?.video?.data?.attributes?.url

      setAnnotationsByColumn: ->
        #V3 item

        if $scope.item.type == 'items'
          $scope.annotationsByColumn = _.groupBy($scope.item.relationships.annotations.data || [],"attributes.column")

        else
          if $scope.item.note_type == 'item' || $scope.currentQuiz?.quiz_type == 'PatternStudy'
            $scope.annotationsByColumn = _.groupBy($scope.item.annotations || [],"column")
          else
            $scope.annotationsByColumn = _.groupBy($scope.facet.annotations || [],"column")

      setUiState: ->
        annotations = $scope.item?.annotations || $scope.item.relationships.annotations

        if $scope.item.annotations
          $scope.numColumns = Math.max(Math.max.apply(null, _.map($scope.item.annotations,"column")), 1)
        else
          $scope.numColumns = Math.max(Math.max.apply(null, _.map($scope.item.relationships.annotations.data, (annotation) ->
            annotation.attributes.column)), 1)

        $scope.columns = _.range(1, $scope.numColumns + 1)

        # don't support multiple in viewer at a time right now so find is sufficient

        pdfAnnotation = _.find($scope.item.annotations, (annotation) ->
          annotation.document?.content_type == "application/pdf"
        )

        pdfAnnotation ||= _.find($scope.item.relationships?.annotations.data, (annotation) ->
          annotation.relationships.document.data?.attributes['content-type'] == "application/pdf"
        )

        if pdfAnnotation
          $scope.uiState.isFullBleed = true
          $scope.embedTargetUrl = pdfAnnotation?.document?.url
          $scope.embedTargetUrl ||= pdfAnnotation.relationships.document.data.attributes.url
        else
          $scope.uiState.isFullBleed = false

    $scope.setAnnotationsByColumn()
    $scope.setUiState()
  ]
])
