declare var amplitude: any;

angular.module('cerego.common').directive('stripeLauncher', [
  () => ({
    restrict: 'E',
    scope: {
      course: '=',
      user: '=',
      imageUrl: '=',
      planId: '=',
      type: '='
    },
    templateUrl: Packs.iKnowRoutes.common_v3_templates_path('stripe_launcher'),

    controller: [
      '$scope',
      '$filter',
      '$window',
      'DebugHelper',
      'StripeService',
      'UserManager',
      'PartnerAccountResource',
      'AmplitudeService',
      ($scope, $filter, $window, DebugHelper, StripeService, UserManager, PartnerAccountResource, AmplitudeService) => {
        DebugHelper.register('stripeLauncher', $scope);

        return _.assignIn($scope, {
          $onInit() {
            return UserManager.loadUser().then(user => {
              $scope.user = user;

              return ($scope.stripeHandler = () => {
                AmplitudeService.logEvent($scope.type + '_stripe_click');
                const stripeOptions = {
                  email: $scope.user.attributes.email,
                  panelLabel: $filter('translate')('js.common.payportal.pay'),
                  name: $filter('translate')('js.common.payportal.pay_by_credit_card'),
                  amount: 200000
                };
                return StripeService.configureHandler(stripeOptions).then(token => {
                  const params = {
                    token: token.id,
                    plan_id: $scope.planId
                  };

                  return PartnerAccountResource.create(
                    { partner_id: $scope.user.relationships['pro-partner'].data.id },
                    params,
                    success => $window.location.reload()
                  );
                });
              });
            });
          }
        });
      }
    ]
  })
]);
