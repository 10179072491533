ceregoCommon.directive("payportal", [->
  restrict: "E"
  scope:
    course: "="
    user: "="
    successCallback: "&"
    image: "="
    version: "="
  templateUrl: (element, $scope) ->
    if $scope.version == "3" then Packs.iKnowRoutes.common_v3_templates_path("_new_payportal") else  Packs.iKnowRoutes.common_v3_templates_path("_payportal")

  controller: ['$scope', '$filter', 'DebugHelper', 'StripeService', 'V3CourseUsersResource', 'currencyToSymbolMap', ($scope, $filter, DebugHelper, StripeService, V3CourseUsersResource, currencyToSymbolMap) ->
    DebugHelper.register("payportal", $scope)

    _.assignIn $scope,

      stripeHandler: ->
        stripeOptions = {
          email: $scope.user.attributes.email
          panelLabel: $filter('translate')('js.common.payportal.pay')
          name: $filter('translate')('js.common.payportal.pay_by_credit_card')
          amount: $scope.course.meta.cost
          currency: $scope.course.meta.currency
        }
        StripeService.configureHandler(stripeOptions).then (token) ->
          params =
            token: token.id

          V3CourseUsersResource.update { course_id: $scope.course.id, id: $scope.user.id }, params,
            (success) ->
              $scope.successCallback()

      $onInit: ->
        $scope.paymentAmount = $scope.course.meta.cost/100
  ]
])
