import * as angular from 'angular';
import { IDebugHelper } from 'v2/modules/core/debug-helper';
import { IV3ModelHelper } from 'v2/modules/core/v3-model-helper';

export interface ITokenExchangeSessionService extends ng.IScope {
  oauthToken: string;
  exchangeTokenForOAuth: (tempLoginToken: string) => any;
}

declare global {
  // tslint:disable-next-line:interface-name
  interface Window {
    CeregoAPI: any;
  }
}

angular.module('cerego.common').service('TokenExchangeSessionService', [
  '$rootScope',
  'V3SessionResource',
  'V3ModelHelper',
  ($rootScope: ng.IRootScopeService, V3SessionResource, V3ModelHelper: IV3ModelHelper) => {
    const $scope = $rootScope.$new() as ITokenExchangeSessionService;

    $scope.exchangeTokenForOAuth = tempLoginToken => {
      // TODO if oauth already exists, potentially ignore tempLoginToken
      return V3SessionResource.create({
        temp_login_token: tempLoginToken,
        include: 'oauth2-access-token'
      }).$promise.then(success => {
        const user = V3ModelHelper.parseObject(success).data as v3.resource.IUser;
        const oauthAccessToken = user.relationships['oauth2-access-token'].data as v3.resource.IOauth2AccessToken;
        $scope.oauthToken = oauthAccessToken.attributes.token;
        return (window.CeregoAPI.token = $scope.oauthToken);
      });
    };

    return $scope;
  }
]);
