ceregoCommon.directive('videoUploader', ['MediaUploader', 'V3MediaUploader', 'MediaProcessorService', (MediaUploader, V3MediaUploader, MediaProcessorService) ->
  restrict: "E"
  scope:
    model: "="

  templateUrl: Packs.iKnowRoutes.common_v3_templates_path("video_uploader")

  controller: ['$scope', 'DebugHelper', ($scope, DebugHelper) ->
    DebugHelper.register("videoUploaderScope", $scope)

    $scope.$parent.videoUploaders = $scope.$parent.videoUploaders || []
    $scope.$parent.videoUploaders.push $scope

    _.assignIn $scope,
      file: null
      tabs: []
      stateUI:
        mode: 'default'
        recording: false
      uploadStatus:
        complete: 0
        total: 1
        percentage: 0
        active: false

      setTabs: () ->
        $scope.tabs = [
          {
            slug: "default",
            name: "js.common.video_uploader.upload_a_video"
          }
        ]

        return if _.includes(process.env.RAILS_ENV, 'govcloud')
        # Compatible browsers only; uncomment when pipeline is complete
        if MediaProcessorService.compatibleBrowser() then $scope.tabs.push({
          slug: "record",
          name: "js.common.sound_uploader.tabs.record"
        })

      showTab: (tab) ->
        $scope.stateUI.mode = tab.slug

      onUploadProgress: (progress) ->
        $scope.uploadStatus.total = progress.total
        $scope.uploadStatus.complete = progress.loaded
        $scope.uploadStatus.percentage = Math.ceil((progress.loaded / progress.total) * 100)
        $scope.$emit "videoUploader:uploadProgress", $scope.uploadStatus
      
      attachMedia: (file) ->
        $scope.file = file
        fileURL = URL.createObjectURL(file)
        $scope.$apply ->
          $scope.model.data = 
            id: 1
            attributes:
              url: fileURL

      uploadMedia: () ->
        if !$scope.file
          return
        $scope.uploadStatus.active = true
        params = file: $scope.file

        V3MediaUploader(params, "video", $scope.onUploadProgress)
        .then (response) ->
          $scope.model.data = response.data.data
          $scope.$emit 'videoUploader:uploadComplete', response.data.data
        .catch (error) ->
          $scope.stateUI.error = error.data.errors.title
        .finally (result) ->
          $scope.uploadStatus.active = false

      toggleRecording: ->
        control = $('button.record-media')
        $player = $('#mediaPreview')

        if !$scope.stateUI.recording
          $scope.stateUI.recording = true
          MediaProcessorService.initRecording('video', $player[0])
          control.addClass('Recording')
          $player.removeClass('Hide')
          $player[0].controls = null
        else
          $scope.stateUI.recording = false
          MediaProcessorService.endRecording()
          control.removeClass('Recording')
          $player[0].controls = 'controls'

        return false;

      deleteVideo: ($event) ->
        $scope.model.data = null
        $event.preventDefault()

      showRecording: ({ url, blob, file }) ->
        $('#mediaPreview').attr('src', url)
        $scope.attachMedia(file)

    MediaProcessorService.setCallback($scope.showRecording)
    
    $scope.$on 'videoUploaderModal:uploadMedia', $scope.uploadMedia
    
    $scope.setTabs()
  ]
])
