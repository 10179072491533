// TODO: provide this as an import and not as a global
window.getTranscodedURL = function(image, size) {
  if (!image) {
    return;
  }
  const url = image.url || (image.data && image.data.attributes && image.data.attributes.url);
  if (!url) {
    // sometimes we pass in the path to an image directly
    if (typeof image === 'string') {
      return image;
    }
    return;
  }
  const smallUrl = image['small-url'] || image.small_url || (image.data && image.data.attributes['small-url']);
  const mediumUrl = image['medium-url'] || image.medium_url || (image.data && image.data.attributes['medium-url']);
  const largeUrl = image['large-url'] || image.large_url || (image.data && image.data.attributes['large-url']);

  if (smallUrl && mediumUrl && largeUrl) {
    if (size <= 200) {
      return smallUrl;
    } else if (size <= 800) {
      return mediumUrl;
    } else {
      return largeUrl;
    }
  } else {
    return url;
  }
};
