import stripeLogo from 'images/v2/logos/stripe.png'

ceregoCommon.service("StripeService", ['$q', '$rootScope', ($q, $rootScope) ->
  $scope = $rootScope.$new()

  window.debug = window.debug || {}
  window.debug["StripeService"] = $scope

  _.assignIn $scope,
    token: ""
    checkoutOptions: {}

    initialize: () ->
      deferred = $q.defer()
      if angular.isUndefined(window.StripeCheckout)
        angular.element.getScript "https://js.stripe.com/v2/", () ->
          angular.element.getScript "https://checkout.stripe.com/checkout.js", deferred.resolve
      else
        deferred.resolve()

      deferred.promise

  configureHandler: (options = {}) ->
    _.assignIn($scope.checkoutOptions, options)
    if options.email != ""
        $scope.checkoutOptions.email = options.email
    $scope.checkoutOptions.name = options.name
    $scope.checkoutOptions.currency = options.currency || 'usd'
    if options.description?
      $scope.checkoutOptions.description = options.description
    $scope.checkoutOptions.amount = options.amount
    $scope.checkoutOptions.email = options.email
    $scope.checkoutOptions.panelLabel = options.panelLabel
    deferred = $q.defer()

    $scope.initialize().then () ->
      $scope.checkout = StripeCheckout.configure
        image: stripeLogo
        key: STRIPE_PUBLISHABLE_KEY
        panelLabel: $scope.checkoutOptions.panelLabel
        allowRememberMe: false

        token: (token) ->
          $scope.token = token
          deferred.resolve(token)

      $scope.checkout.open
        name: $scope.checkoutOptions.name
        description: $scope.checkoutOptions.description
        amount: $scope.checkoutOptions.amount
        currency: $scope.checkoutOptions.currency
        email: $scope.checkoutOptions.email

    deferred.promise
])
