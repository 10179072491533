import 'angular-material'

window.ceregoToast = angular.module("cerego.toast", [
  "ngMaterial"
])
.config(["$mdToastProvider", ($mdToastProvider) ->
  $mdToastProvider.addPreset('simpleToast',
    options: ->
      templateUrl: Packs.iKnowRoutes.toast_v3_templates_path("simple_toast")
      controller: 'simpleToastCtrl'
      locals:
        type: 'confirm'
        message: 'message'
  )
]).service('toastHelper', ['$rootScope', 'DebugHelper', '$mdToast', ($rootScope, DebugHelper, $mdToast) ->
  $scope = $rootScope.$new()
  DebugHelper.register("toastHelper", $scope)

  $scope.showToast = (name, options) ->
    options = _.defaults(options, position: "bottom right")
    $mdToast.show($mdToast[name](options))
    false

  $scope
]).controller('simpleToastCtrl', ['$scope', 'DebugHelper', 'type', 'message', ($scope, DebugHelper, type, message) ->
  DebugHelper.register("simpleToastCtrl", $scope)
  $scope.type = type
  $scope.message = message
])
