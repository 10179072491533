import 'sound_manager'

ceregoCommon.directive('crgSoundPlayer', ['$timeout', 'localStorageService', ($timeout, localStorageService) ->
  scope:
    crgSoundPlayer: "@"

  template: "<a href='' aria-hidden=true></a><a href='' class='visually-hidden'>Click to play this sound</a>"

  controller: ['$scope', 'DebugHelper', ($scope, DebugHelper) ->
    DebugHelper.register("crgSoundPlayer", $scope)
  ]

  link: (scope, element, attrs) ->
    element.addClass("ui360").attr("soundId", "sound" + scope.$id)
    scope.$watch "crgSoundPlayer", ->
      return if !scope.crgSoundPlayer
      angular.element("a", element).attr( { href : scope.crgSoundPlayer } )
      $timeout ->
        scope.$emit("registerSoundHotkey", scope.crgSoundPlayer )
        options = {}
        settings = localStorageService.get('learn_app_settings')
        if settings?.effectVolume
          options['effectVolume'] = settings.effectVolume
        threeSixtyPlayer.debouncedInit(options)
])
