ceregoCommon.factory('SeriesCollaboratorsResource', ['$resource', ($resource) ->
  $resource "",{},
    query:
      method: 'GET'
      isArray: false
      url: APIRoutes.v3_series_contributors_path(":series_id")
      params:
        series_id: "@series_id"
    delete:
      method: 'DELETE'
      isArray: false
      url: APIRoutes.v3_series_contributor_path(":series_id",":id")
      params:
        series_id: "@series_id"
        id: "@id"
    create:
      method: 'POST'
      isArray: false
      url: APIRoutes.v3_series_contributors_path(":series_id")
      params:
        series_id: "@series_id"
])
