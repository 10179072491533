ceregoCommon.factory('UserCsvUploadsResource', ['$resource', ($resource) ->
  $resource "", {},
    get:
      method: 'GET'
      url: APIRoutes.v3_user_csv_upload_path(":id")
      params:
        id: "@id"
    parse_background:
      method: 'POST'
      url: APIRoutes.v3_user_csv_upload_parse_background_path(":user_csv_upload_id")
      params:
        user_csv_upload_id: "@user_csv_upload_id"
    index:
      method: 'GET'
      isArray: false
      url: APIRoutes.v3_partner_user_csv_uploads_path(':partner_id')
])
