import * as angular from 'angular';
import { IDebugHelper } from 'v2/modules/core/debug-helper';
import { IFilterCore } from 'v2/modules/core/filters';

export interface IGoalMathHelper extends ng.IScope {
  levelToDaysConversion: (levelValue: number) => number;
  daysToLevelConversion: (daysvalue: number) => number;
  estimateRangeLower: (levelValue: number) => number;
  isValidLevel: (levelValue: number) => boolean;
  isValidDays: (daysValue: number) => boolean;
}

interface IFilter extends IFilterCore {
  (name: 'levelRoundDown'): (level: number) => number;
}

angular.module('cerego.common').service('GoalMathHelper', [
  '$rootScope',
  '$filter',
  'DebugHelper',
  ($rootScope: ng.IRootScopeService, $filter: IFilter, DebugHelper: IDebugHelper) => {
    const $scope = $rootScope.$new() as IGoalMathHelper;
    DebugHelper.register('GoalMathHelper', $scope);

    const MIN_LEVEL = 0;
    const MAX_LEVEL = 4.0;
    const DVALUE = 2; // Commence magical numbers from Iain
    const EVALUE = 1.2;
    const CONST1 = 0.1234;
    const CONST2 = 0.7526;
    const CONST3 = 1.7414;

    $scope.levelToDaysConversion = levelValue => {
      return Math.max(Math.round(EVALUE * (Math.exp((Number(levelValue) - CONST1) / CONST2) - CONST3) + DVALUE), 2);
    }; // edge case at very low level values

    $scope.estimateRangeLower = levelValue => {
      return Math.ceil(0.8 * ($scope.levelToDaysConversion(levelValue) - 2));
    };

    $scope.daysToLevelConversion = daysValue => {
      return Number($filter('levelRoundDown')(CONST1 + CONST2 * Math.log((daysValue - DVALUE) / EVALUE + CONST3)));
    };

    $scope.isValidLevel = levelValue => {
      return (
        levelValue >= MIN_LEVEL &&
        levelValue <= MAX_LEVEL &&
        levelValue === Number($filter('levelRoundDown')(levelValue))
      );
    };

    $scope.isValidDays = daysValue => {
      return daysValue === Number(daysValue) && $scope.isValidLevel($scope.daysToLevelConversion(daysValue));
    };

    return $scope;
  }
]);
