ceregoCommon.factory('EmailsResource', ['$resource', ($resource) ->
  $resource "", {},
    create:
      method: 'POST'
      isArray: false
      url: APIRoutes.v2_my_emails_path()
    delete:
      method: 'DELETE'
      isArray: false
      url: APIRoutes.v2_my_email_path(":id")
      params:
        id: "@id"
    make_primary:
      method: 'PUT'
      isArray: false
      url: APIRoutes.make_primary_v2_my_email_path(":id")
      params:
        id: "@id"
    confirm_email:
      method: 'POST'
      isArray: false
      url: APIRoutes.v2_my_email_confirmations_path()
    send_confirmation:
      method: 'POST'
      isArray: false
      url: APIRoutes.send_confirmation_v2_my_emails_path()
])
