# TODO: use the actual library in the future: https://github.com/bengourley/currency-symbol-map
ceregoCommon.constant("currencyToSymbolMap",
  'AED': 'د.إ'
  'AFN': '؋'
  'ALL': 'L'
  'AMD': '֏'
  'ANG': 'ƒ'
  'AOA': 'Kz'
  'ARS': 'ARS$'
  'AUD': 'AU$'
  'AWG': 'ƒ'
  'AZN': '₼'
  'BAM': 'KM'
  'BBD': '$'
  'BDT': '৳'
  'BGN': 'лв'
  'BHD': '.د.ب'
  'BIF': 'FBu'
  'BMD': 'BM$'
  'BND': 'BN$'
  'BOB': '$b'
  'BRL': 'R$'
  'BSD': 'BS$'
  'BTC': '฿'
  'BTN': 'Nu.'
  'BWP': 'P'
  'BYR': 'p.'
  'BZD': 'BZ$'
  'CAD': 'CA$'
  'CDF': 'FC'
  'CHF': 'CHF'
  'CLP': 'CLP$'
  'CNY': '¥'
  'COP': 'COP$'
  'CRC': '₡'
  'CUC': 'CUC$'
  'CUP': '₱'
  'CVE': 'CVE$'
  'CZK': 'Kč'
  'DJF': 'Fdj'
  'DKK': 'kr'
  'DOP': 'RD$'
  'DZD': 'دج'
  'EEK': 'kr'
  'EGP': '£'
  'ERN': 'Nfk'
  'ETB': 'Br'
  'ETH': 'Ξ'
  'EUR': '€'
  'FJD': 'FJ$'
  'FKP': '£'
  'GBP': '£'
  'GEL': '₾'
  'GGP': '£'
  'GHC': '₵'
  'GHS': 'GH₵'
  'GIP': '£'
  'GMD': 'D'
  'GNF': 'FG'
  'GTQ': 'Q'
  'GYD': 'GY$'
  'HKD': 'HK$'
  'HNL': 'L'
  'HRK': 'kn'
  'HTG': 'G'
  'HUF': 'Ft'
  'IDR': 'Rp'
  'ILS': '₪'
  'IMP': '£'
  'INR': '₹'
  'IQD': 'ع.د'
  'IRR': '﷼'
  'ISK': 'kr'
  'JEP': '£'
  'JMD': 'J$'
  'JOD': 'JD'
  'JPY': '¥'
  'KES': 'KSh'
  'KGS': 'лв'
  'KHR': '៛'
  'KMF': 'CF'
  'KPW': '₩'
  'KRW': '₩'
  'KWD': 'KD'
  'KYD': 'KY$'
  'KZT': 'лв'
  'LAK': '₭'
  'LBP': '£'
  'LKR': '₨'
  'LRD': 'LR$'
  'LSL': 'M'
  'LTC': 'Ł'
  'LTL': 'Lt'
  'LVL': 'Ls'
  'LYD': 'LD'
  'MAD': 'MAD'
  'MDL': 'lei'
  'MGA': 'Ar'
  'MKD': 'ден'
  'MMK': 'K'
  'MNT': '₮'
  'MOP': 'MOP$'
  'MRO': 'UM'
  'MUR': '₨'
  'MVR': 'Rf'
  'MWK': 'MK'
  'MXN': 'MXN$'
  'MYR': 'RM'
  'MZN': 'MT'
  'NAD': 'NA$'
  'NGN': '₦'
  'NIO': 'C$'
  'NOK': 'kr'
  'NPR': '₨'
  'NZD': 'NZ$'
  'OMR': '﷼'
  'PAB': 'B/.'
  'PEN': 'S/.'
  'PGK': 'K'
  'PHP': '₱'
  'PKR': '₨'
  'PLN': 'zł'
  'PYG': 'Gs'
  'QAR': '﷼'
  'RMB': '￥'
  'RON': 'lei'
  'RSD': 'Дин.'
  'RUB': '₽'
  'RWF': 'R₣'
  'SAR': '﷼'
  'SBD': 'SB$'
  'SCR': '₨'
  'SDG': 'ج.س.'
  'SEK': 'kr'
  'SGD': 'SG$'
  'SHP': '£'
  'SLL': 'Le'
  'SOS': 'S'
  'SRD': 'SR$'
  'SSP': '£'
  'STD': 'Db'
  'SVC': 'SVC$'
  'SYP': '£'
  'SZL': 'E'
  'THB': '฿'
  'TJS': 'SM'
  'TMT': 'T'
  'TND': 'د.ت'
  'TOP': 'T$'
  'TRL': '₤'
  'TRY': '₺'
  'TTD': 'TT$'
  'TVD': 'TV$'
  'TWD': 'NT$'
  'TZS': 'TSh'
  'UAH': '₴'
  'UGX': 'USh'
  'USD': '$'
  'UYU': '$U'
  'UZS': 'лв'
  'VEF': 'Bs'
  'VND': '₫'
  'VUV': 'VT'
  'WST': 'WS$'
  'XAF': 'FCFA'
  'XBT': 'Ƀ'
  'XCD': 'XC$'
  'XOF': 'CFA'
  'XPF': '₣'
  'YER': '﷼'
  'ZAR': 'R'
  'ZWD': 'Z$'
)

ceregoCommon.filter('currencyToSymbol', ['currencyToSymbolMap', (currencyToSymbolMap) ->
  (currency) ->
    currency && currencyToSymbolMap[currency.toUpperCase()]
])
