ceregoCommon.controller("userPasswordChangeModal", ['$scope', 'V3UsersResource', 'DebugHelper', ($scope, V3UsersResource, DebugHelper) ->
  DebugHelper.register("userPasswordChangeModal", $scope)

  _.assignIn $scope,
    user_min_password_len: USER_MIN_PASSWORD_LEN
    fields:
      password: null
      password_confirmation: null

    stateUI:
      password:
        hidden: true
        inputType: "password"
      passwordConfirm:
        hidden: true
        inputType: "password"

    updatePassword: ->
      V3UsersResource.update { id: $scope.model.id }
      , $scope.fields
      , (success) ->
        $scope.model.attributes['has-password'] = true
        $scope.closeModal()

    toggleShowPassword: (field) ->
      $scope.stateUI[field].hidden = !$scope.stateUI[field].hidden
      $scope.stateUI[field].inputType = if $scope.stateUI[field].hidden  then "password" else "text"

  $scope.model = $scope.modalOptions.model
  $scope.partner = $scope.model.relationships['user-partner-member-ids'].data[0].relationships.partner
])
