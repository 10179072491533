ceregoCommon.directive('v3SoundBox', [ ->
  restrict: "E"
  scope:
    model: "="
    hideTrashcan: "=?"
    noteId: "=?"

  templateUrl: (element, $scope) ->
    Packs.iKnowRoutes.common_v3_templates_path("sound_box")

  controller: ['$scope', 'DebugHelper', 'ModalService', ($scope, DebugHelper, ModalService) ->
    DebugHelper.register("v3SoundBox", $scope)

    _.assignIn $scope,
      launchModal: () ->
        ModalService.showModal('sound-uploader-modal', { model: $scope.model, description: 'Upload your sound below.' })

      resetModel: () ->
        $scope.model = {}

    $scope.$on "launchNoteEditModal", (event, opt) ->
      $scope.launchModal() if opt.id == $scope.noteId
  ]
])
