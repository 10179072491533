ceregoCommon.directive("cgInputFocus", ['$window', ($window) ->
  restrict: "AE"

  #must use in combination with .basic-toggle
  link: ($scope, element, attrs) ->
    element.on "focusin", (e) ->
      element.closest(".basic-toggle").addClass("active")
      e.stopPropagation()

    element.on "focusout", (e) ->
      $window.setTimeout ->
        angular.element(".basic-toggle").removeClass("active")
      , 1000
])
