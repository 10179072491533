ceregoCommon.factory('SeriesResource', ['$resource', ($resource) ->
  $resource "",{},
    get:
      method: 'GET'
      isArray: false
      url: APIRoutes.v2_series_path(":id")
      params:
        id: "@id"
    sets:
      method: 'GET'
      isArray: false
      url: APIRoutes.v2_series_sets_path(":id")
      params:
        id: "@id"
    update:
      method: 'PUT'
      isArray: false
      url: APIRoutes.v2_series_path(":id")
      params:
        id: "@id"
    delete:
      method: 'DELETE'
      isArray: false
      url: APIRoutes.v2_series_path(":id")
      params:
        id: "@id"
])
