ceregoCommon.directive('imageUploader', ['MediaUploader', 'V3MediaUploader', 'AmplitudeService', (MediaUploader, V3MediaUploader, AmplitudeService) ->
  restrict: "E"
  scope:
    onDrop: "&?"
    model: "="
    mode: "="
    imageWidth: "="
    imageHeight: "="
    customTitle: "="
    uploadOnly: "="
    version: "="
    allowFormula: "=?"
  templateUrl: (element, $scope) ->
    if $scope.version == "3" then Packs.iKnowRoutes.common_v3_templates_path("image_uploader") else  Packs.iKnowRoutes.v2_template_path("image_uploader")

  controller: ['$scope', '$timeout', 'DebugHelper', 'UploaderService', ($scope, $timeout, DebugHelper, UploaderService) ->
    DebugHelper.register("imageUploaderScope", $scope)

    UploaderService.imageUploader = $scope
    $scope.$parent.imageUploaders = $scope.$parent.imageUploaders || []
    $scope.$parent.imageUploaders.push $scope

    _.assignIn $scope,
      stateUI: {
      }
      dragover: false
      imageUpload:
        percentage: 0
        active: false

      # V3 only
      setTabs: () ->
        $scope.tabs = [
          {
            slug: "default",
            name: "js.common.image_uploader.tabs.default"
          },
          {
            slug: "url",
            name: "js.common.image_uploader.tabs.url"
          },
          {
            slug: "flickr",
            name: "js.common.image_uploader.tabs.flickr"
          }
        ]

        if $scope.allowFormula
          $scope.tabs.push({
            slug: "formula",
            name: "js.common.image_uploader.tabs.formula"
          })

      generateFormulaImage: (elementId) ->
        AmplitudeService.logEvent("generate_formula_image");
        svg = document.getElementById(elementId).getElementsByTagName('svg')[0]
        img = new Image()
        canvas = document.createElement('canvas')

        image_src = $scope.getDataUriForSVG(svg)
        img.src = image_src
        svg.insertAdjacentElement('afterend', img)

        $scope.resizeCanvas(svg, canvas, 1600)
        context = canvas.getContext('2d')

        img.onload = ->
          context.drawImage(img,0,0, canvas.width, canvas.height)
          png_base_64_data = canvas.toDataURL('image/png')
          $scope.uploadImageFromBase64(png_base_64_data, 'image/png')

      getDataUriForSVG: (svg) ->
        xml = new XMLSerializer().serializeToString(svg);
        svg_base_64_data = btoa(xml);
        b64start = 'data:image/svg+xml;base64,';
        data_uri = b64start + svg_base_64_data;
        return data_uri

      resizeCanvas: (svg, canvas, maxSize) ->
        svg_rect = svg.getBoundingClientRect()
        aspect_ratio = svg_rect['width'] / svg_rect['height']
        canvas.width = maxSize
        canvas.height = maxSize / aspect_ratio

      getMathJaxFormula: (elementId) ->
        MathJax.Hub.Queue(["Typeset", MathJax.Hub, elementId])
        MathJax.Hub.Queue([$scope.generateFormulaImage, elementId])

      onImageDrop: ($event, model) ->
        if $scope.onDrop
          model = event.originalEvent.dataTransfer.getData("text/uri-list")
          $scope.onDrop()

      # TODO: fix - this doesn't currently get called
      onImageUploadProgress: (progress) ->
        $scope.$apply ->
          $scope.imageUpload.percentage = Math.round(100 * progress.loaded / progress.total)

      toggleImageMode: (mode) ->
        if mode == $scope.imageUpload.mode
          $scope.imageUpload.mode = 'default'
        else
          $scope.imageUpload.mode = mode

      setImageTab: (tab) ->
        $scope.imageUpload.mode = tab.slug

      uploadImageFromBase64: (data_uri, content_type) ->
        params = {
          data_uri: data_uri,
          content_type: content_type
        }

        $scope.uploadImage params

      uploadImageFromFile: ($file) ->
        $scope.uploadImage
          file: $file

      uploadImageFromUrl: ($event, $url, $orig_owner, $license_id) ->
        url = $url || $scope.imageUpload.url
        $event.preventDefault()
        params = { url: url }
        if $orig_owner && $license_id
          params.orig_owner = $orig_owner
          params.license_id = $license_id
        $scope.uploadImage params

      uploadImage: (params) ->
        if $scope.version == 3
          $scope.V3ImageUpload(params)
        else
          $scope.V2ImageUpload(params)

      # TODO: Turn this file to be only V3
      V3ImageUpload: (params) ->
        $scope.imageUpload.active = true
        V3MediaUploader(params, "image", $scope.onImageUploadProgress)
        .then (response) ->
          $scope.model.data = response.data.data
        .catch (error) ->
          $scope.stateUI.error = error.data.errors[0].title
        .finally (response) ->
          $scope.imageUpload.active = false

      V2ImageUpload: (params) ->
        $scope.imageUpload.active = true
        MediaUploader(params, "image", $scope.onImageUploadProgress)
        .success (json) ->
          $scope.$apply ->
            $scope.model.image = json.response.image
            $scope. imageUpload.active = false
        .error (error) ->
          $scope.$apply ->
            $scope.imageUpload.active = false

      deleteImage: ($event) ->
        if $scope.version == 3
          $scope.model.data = null
        else
          $scope.model.image = null
        $event.preventDefault()

      handleDragLeave: ($event) ->
        $timeout ->
          $scope.dragover = false

      handleDragOver: ($event) ->
        $timeout ->
          $scope.dragover = true

    $scope.imageUpload.mode = $scope.mode || 'default'

    $scope.$watch "imageWidth", ->
      $scope.width  = Math.round($scope.imageWidth  || 300)

    $scope.$watch "imageHeight", ->
      $scope.height = Math.round($scope.imageHeight || 150)

    $scope.$on "imageUploader:reset", ->
      $scope.toggleImageMode('default')

    $scope.setTabs()
  ]

  link: ($scope, element, attrs) ->
    element.on "dragenter", (event) ->
      !_.includes(event.originalEvent.dataTransfer.types,"text/uri-list")

    element.on "dragover", (event) ->
      !_.includes(event.originalEvent.dataTransfer.types,"text/uri-list")

    element[0].addEventListener('dragleave', $scope.handleDragLeave, false)
    element[0].addEventListener('dragover', $scope.handleDragOver, false)

    element.on "drop", (event) ->
      $scope.onImageDrop(event, $scope.model)

      url = event.originalEvent.dataTransfer.getData("text/uri-list")
      if url && url.length > 0
        $scope.uploadImageFromUrl(event, url, null, null)
        false
      else
        true
])
