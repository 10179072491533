ceregoCommon.service("PassageHighlightingService", [->
  service =
    passageWithHighlightedNode: (node, text) ->
      text.substring(0, node.offset) + "<b>" + node.cloze_text + "</b>" + text.substring(node.offset + node.length, text.length)

    passageWithBlankNode: (node, text) ->
      # blanks = node.cloze_text.replace(/[^\s]/g, "_&nbsp;").replace(/\s/g, "&nbsp;&nbsp;")
      text ||= node.passage_text
      blanks = "______"
      text.substring(0, node.offset) + "<b aria-hidden=true>" + blanks + "</b>" + "<span class='visually-hidden'> Blank </span>" + text.substring(node.offset + node.length, text.length)

    passageWithHighlights: (nodes, text) ->
      for node in _.sortBy(nodes, (n) -> 0 - n.offset)
        text = service.passageWithHighlightedNode(node, text)
      text

  service
])
