window.ceregoCommon = angular.module("cerego.common", [
  "cerego.core"
  "cerego.translate"
  "cerego.toast"
  "cerego.timeout"
])

# replace this with explicit import
req = require.context('./common/resources', false)
req.keys().forEach(req)

require('../../v3/modules/common/resources/partner-account-resource')
require('../../v3/modules/common/resources/v3-instructional-presentations-resource.js')
require('../../v3/modules/common/resources/v3_sets_resource.js')
require('../../v3/modules/common/resources/v3-course-sets-resource.js')
require('../../v3/modules/common/resources/v3_series_resource.js')
require('../../v3/modules/common/resources/tips-resource.js')
require('../../v3/modules/common/resources/v3-oauth-resource.js')
require('../../v3/modules/common/directives/wysiwyg.js')
require('../../v3/modules/common/helpers/goal-math-helper.ts')
require('../../v3/modules/common/services/set-learn-url-service.ts')
require('../../v3/modules/common/services/react-navigation-service.ts')
require('../../v3/modules/common/services/page-tracking-service.ts')
require('../../v3/modules/common/services/free-trial-modal-service.ts')
require('../../v3/modules/common/services/amplitude-service.ts')

require('./common/filters.js')

require('tui-image-editor/dist/tui-image-editor.css')
require('./cerego-toast.js')
require('./cerego-timeout.ts')

require('./common/combining-marks-service.js')
require('./common/currency-symbol-map.js')
require('./common/dynamic_attributes.js')
require('./common/external-link-helper.js')
require('./common/file_reader.js')
require('./common/image-uploader-modal.js')
require('./common/instructional-content-service.js')
require('./common/item-uploader.js')
require('./common/lti-service.js')
require('./common/lti-redirect-service.ts')
require('./common/module-context-service.js')
require('./common/new-set-service.js.coffee')
require('./common/nps-service.js')
require('./common/pagination-service.js')
require('./common/passage-highlighting-service.js')
require('./common/user-password-change-modal.js')
require('./common/quiz_name_service.js')
require('./common/region_highlighting_service.js')
require('./common/sentence-converter.js')
require('./common/sortable-indexing-service.js')
require('./common/sound-uploader-modal.js')
require('./common/video-uploader-modal.js')
require('./common/stripe_service.js')
require('./common/study-items-service.js')
require('./common/time-helper.js')
require('./common/token-exchange-session-service.ts')
require('./common/uploader-service.js')
require('./common/v2-group-context-service.js')
require('./common/v3-image-box.js')
require('./common/v3-sound-box.js')
require('./common/v3-video-box.js')
require('./common/v3-toggle.js')
require('./common/window_helper.js')
require('./common/media-processor-service.ts')

# replace these with explicit import

req = require.context('./common/directives', false, /\.js$/)
req.keys().forEach(req)
require('./common/directives/stripe-launcher.ts')

req = require.context('./common/modals', false, /\.js$/)
req.keys().forEach(req)
