ceregoCommon.factory('GroupsResource', ['$resource', ($resource) ->
  $resource "", {},
    query:
      method: 'GET'
      isArray: false
      url: APIRoutes.v2_partner_groups_path(":partner_id")
      params:
        partner_id: "@partner_id"
    save:
      method: 'POST'
      isArray: false
      url: APIRoutes.v2_partner_groups_path(":partner_id")
      params:
        partner_id: "@partner_id"
    delete:
      method: 'DELETE'
      isArray: false
      url: APIRoutes.v2_partner_group_path(":partner_id", ":id")
      params:
        partner_id: "@partner_id"
        id: "@id"
    update:
      method: 'PUT'
      isArray: false
      url: APIRoutes.v2_partner_group_path(":partner_id", ":id")
      params:
        partner_id: "@partner_id"
        id: "@id"
    get_for_partner:
      method: 'GET'
      isArray: false
      url: APIRoutes.v2_partner_group_path(":partner_id", ":id")
      params:
        partner_id: "@partner_id"
        id: "@id"
])
