ceregoCommon.directive('soundUploader', ['MediaUploader', 'V3MediaUploader', 'MediaProcessorService', (MediaUploader, V3MediaUploader, MediaProcessorService) ->
  restrict: "E"
  scope:
    model: "="
    version: "="

  templateUrl: (element, $scope) ->
    if $scope.version == "3" then Packs.iKnowRoutes.common_v3_templates_path("sound_uploader") else Packs.iKnowRoutes.v2_template_path("sound_uploader")

  controller: ['$scope', 'DebugHelper', ($scope, DebugHelper) ->
    DebugHelper.register("soundUploaderScope", $scope)

    $scope.$parent.soundUploaders = $scope.$parent.soundUploaders || []
    $scope.$parent.soundUploaders.push $scope

    _.assignIn $scope,
      file: null
      tabs: []
      stateUI:
        mode: "default"
        recording: false
      uploadStatus:
        complete: 0
        total: 1
        percentage: 0
        active: false

      setTabs: () ->
        $scope.tabs = [
          {
            slug: "default",
            name: "js.common.sound_uploader.tabs.default"
          }
        ]

        # Compatible browsers only include Chromium-based, Firefox, and Safari
        if MediaProcessorService.compatibleBrowser() then $scope.tabs.push({
          slug: "record",
          name: "js.common.sound_uploader.tabs.record"
        })
      
      showTab: (tab) ->
        $scope.stateUI.mode = tab.slug

      onUploadProgress: (progress) ->
        $scope.uploadStatus.total = progress.total
        $scope.uploadStatus.complete = progress.loaded
        $scope.uploadStatus.percentage = Math.ceil((progress.loaded / progress.total) * 100)
        $scope.$emit "soundUploader:uploadProgress", $scope.uploadStatus

      attachMedia: (file) ->
        $scope.file = file
        fileURL = URL.createObjectURL(file)
        $scope.$apply ->
          $scope.model.data = 
            id: 1
            attributes:
              url: fileURL

      uploadMedia: () ->
        if !$scope.file
          return
        $scope.uploadStatus.active = true
        params = file: $scope.file

        if $scope.version == 3
          $scope.V3SoundUpload(params)
        else
          $scope.V2SoundUpload(params)

      V3SoundUpload: (params) ->
        $scope.model.data = null # init model data
        V3MediaUploader(params, "sound", $scope.onUploadProgress)
        .then (response) ->
          $scope.model.data = response.data.data
          $scope.$emit 'soundUploader:uploadComplete', response.data.data
        .catch (error) ->
          $scope.stateUI.error = error.data.errors[0].title
        .finally (result) ->
          $scope.uploadStatus.active = false

      # TODO: Remove me eventually when v2 is dead
      V2SoundUpload: (params) ->
        MediaUploader({ file: params }, "sound", $scope.onUploadProgress)
        .success (json) ->
          $scope.$apply ->
            $scope.model.sound = json.response.sound
            $scope.uploadStatus.active = false
          $scope.$emit 'soundUploader:uploadComplete', response.data.data
        .error (error) ->
          $scope.stateUI.error = error.data.errors[0].title
          $scope.$apply ->
            $scope.uploadStatus.active = false

      deleteSound: ($event) ->
        if $scope.version == 3
          $scope.model.data = null
        else
          $scope.model.sound = null
        $event.preventDefault()

      toggleRecording: ->
        $control = $('button.record-media');
        if !$scope.stateUI.recording
          $scope.stateUI.recording = true
          MediaProcessorService.initRecording('audio')
          $control.addClass('Recording')
        else
          $scope.stateUI.recording = false
          MediaProcessorService.endRecording()
          $control.removeClass('Recording')

        return false;

      showRecording: ({ file, blob, url }) ->
        $('#mediaPreview').removeClass('Hide')
          .attr('src', url)
        $scope.attachMedia(file);

    MediaProcessorService.setCallback($scope.showRecording)

    $scope.$on 'soundUploaderModal:uploadMedia', $scope.uploadMedia

    $scope.setTabs()
  ]
])
