ceregoCommon.directive("searchableList", [->
  restrict: "E"
  templateUrl: Packs.iKnowRoutes.common_v3_templates_path("searchable_list")

  scope:
    serviceOptions: "=?"
    service: "=?"
    searchHeading: "@?"
    cardHeading: "@?"
    placeholderText: "=?"
    onLoad: "&?" # Must return the active tab which will be set after the function resolves
    tabs: "=?"
    tabShortcuts: "=?"
    hideSearch: "=?"
    editableTileFunctions: "=?"
    selectable: "=?"
    allowSelectEverything: "=?"
    selectEverythingTranslateSlug: "=?"
    isLti: "=?"
    resourceTemplate: "=?"
    tileTemplate: "=?"
    allowTileListSwitcher: "=?"
    resourceAction: "=?"
    emptyTemplate: "=?"
    loadingTemplate: "=?"
    forbiddenTemplate: "=?"
    customOrderings: "=?"
    resourceActions: "=?"
    resourceUrl: "&?"
    showModal: "=" # TODO is there a better way to make showModal available inside searchableList?
    styles: "=?"

  controller: ['$scope', '$q', '$filter', '$timeout', '$location', '$document', '$window', 'DebugHelper', 'paginationService', 'myPartnersService', 'localStorageService', 'UserManager', 'CourseContextService', 'SiteNavigationService', 'reactNavigationService', ($scope, $q, $filter, $timeout, $location, $document, $window, DebugHelper, paginationService, myPartnersService, localStorageService, UserManager, CourseContextService, SiteNavigationService, reactNavigationService) ->
    DebugHelper.register("searchableList", $scope)

    _.assignIn $scope,
      # only gets used if a sort is specified as draggable
      # may want to pass the type of sort to the resource to generalize,
      # but not an issue yet
      sortableOptions:
        cursor: "move"
        forcePlaceholderSize: true
        placeholder: "item-card-drag-placeholder"
        'ui-floating': false
        axis: 'y'
        stop: (e, ui) ->
          $scope.handleDropEvent(e, ui)

      # saved state for sort on each tab, used for inner searches with multiple tabs
      # load saved sort state (sort column + direction) when we switch tabs
      sortStates: {}

      searchOptions:
        query: ""
        sort: {}

      defaultOptions:
        'page[number]': 1
        sort: "name"
        query: ""

      routes: Packs.iKnowRoutes

      defaultResourceImage: $scope.service.defaultResourceImage

      CourseContextService: CourseContextService
      SiteNavigationService: SiteNavigationService

      stateUI: {
        loading: true
        showResourceDropdown: {}
        direction: 'desc'
        forbidden: false
      }

      orderings: [
        name: 'js.filters.name'
        direction:
          desc: "name"
          asc: "-name"
        default_direction: "desc"
      ]

      handleDropEvent: (e, ui) ->
        if !_.isUndefined(ui.item.sortable.dropindex) #undefined if left in place
          position_delta = ui.item.sortable.dropindex - ui.item.sortable.index
          if $scope.stateUI.direction == "asc"
            position_delta = position_delta * -1
          resource = ui.item.scope().resource
          $scope.stateUI.loading = true
          $scope.service.updateResources(resource, position_delta, $scope.activeTab.slug).then ->
            $scope.stateUI.loading = false # do we need to do anything else?

      toggleSelected: (resource, $event) ->
        $scope.service.everythingSelected = false
        resource.selected = !resource.selected

        $scope.allSelected = _.every($scope.service.resources[$scope.activeTab.slug].data, { selected: true })
        $scope.anySelected = !_.isEmpty(_.filter($scope.service.resources[$scope.activeTab.slug].data, { selected: true }))
        $event.preventDefault()
        $event.stopPropagation()

      toggleAllSelected: () ->
        $scope.service.everythingSelected = false
        resources = $scope.service.resources[$scope.activeTab.slug].data
        if $scope.anySelected && !$scope.allSelected
          $scope.anySelected = false
          _.map(resources, (resource) ->
            resource.selected = false
          )
        else
          $scope.allSelected = !$scope.allSelected
          $scope.anySelected = $scope.allSelected
          _.map(resources, (resource) ->
            resource.selected = $scope.allSelected
          )

      toggleEverythingSelected: () ->
        $scope.service.everythingSelected = !$scope.service.everythingSelected

      toggleResourceDropdown: ($event, resourceId) ->
        $event.preventDefault()
        $event.stopPropagation()
        newState = !$scope.stateUI.showResourceDropdown[resourceId]
        $scope.stateUI.showResourceDropdown = {}
        $scope.stateUI.showResourceDropdown[resourceId] = newState

      clearSelections: () ->
        for resource in $scope.service.resources[$scope.activeTab?.slug]?.data
          resource.selected = false
        $scope.service.everythingSelected = false
        $scope.allSelected = false
        $scope.anySelected = false

      setActiveTab: (slug) ->
        $scope.clearSelections()
        oldTabSlug = $scope.activeTab.slug
        $scope.activeTab = _.find($scope.tabs, slug: slug)
        $scope.service.activeTab = slug
        if $scope.useSavedSortStates
          $scope.applyCustomOrderings()

          # save the sort + desc/asc state on current slug
          # load the saved sort state on new slug
          $scope.sortStates[oldTabSlug] = {
            direction: $scope.stateUI.direction
            sort: $scope.searchOptions.sort
          }
          if $scope.sortStates[slug]
            $scope.stateUI.direction = $scope.sortStates[slug].direction
            $scope.searchOptions.sort = $scope.sortStates[slug].sort
          else
            $scope.searchOptions.sort = {}
            $scope.resetSort()

      populateMissingOptions: (options)->
        options = _.defaults(options, $scope.serviceOptions)
        if !options.query && $scope.searchOptions.query != ""
          options.query = $scope.searchOptions.query
        if !options.sort && $scope.searchOptions.sort.direction[$scope.stateUI.direction]
          options.sort = $scope.searchOptions.sort.direction[$scope.stateUI.direction]
        options = _.defaults(options, $scope.defaultOptions)

        if options.sort && $scope.searchOptions.sort.disabled
          options['sort'] = "" # dont override 'relevance' sort

        options

      getPageForTab: (options) ->
        $scope.allSelected = false
        $scope.anySelected = false
        $scope.service.everythingSelected = false
        unless $scope.useSavedSortStates
          options = $scope.populateMissingOptions(options)

        $scope.service.getPaginatedResults(options).then (resources) ->
          for resource in resources.data
            resource.selected = false
          $scope.service.resources[options.tab] = resources
          currentPage = options['page[number]']
          totalPages = resources.meta?['total-pages']
          $scope.service.resources[options.tab].currentPage = currentPage
          # calculate the first index by calculating how many items we've skipped over
          skippedItems = $scope.defaultOptions['page[size]'] * (currentPage - 1)
          $scope.service.resources[options.tab].firstIndex = skippedItems + 1
          $scope.service.resources[options.tab].lastIndex = skippedItems + resources.data.length

          if options.tab == $scope.activeTab.slug
            $scope.activeTab.empty ||= $scope.emptyTemplate

          $scope.service.resources[options.tab].pages = paginationService.getClassAndPage(currentPage, totalPages)

          _.find($scope.tabs, slug: options.tab).isEmpty = $scope.service.resources[options.tab].data.length == 0
        , (error) ->
          if error.status == 403 # Currently only used for course users who need to pay
            $scope.hideSearch = true
            $scope.stateUI.forbidden = true

      setOrderingAndSearch: (order) ->
        $scope.setOrdering(order)
        $scope.searchResources()

      setOrdering: (order) ->
        $scope.searchOptions.sort = order
        $scope.stateUI.direction = order?.default_direction || "desc"
        $scope.stateUI.draggable = order?.draggable
        $scope.sortableOptions.disabled = !order?.draggable

      toggleSortDirection: ($event) ->
        $event.preventDefault()
        $scope.stateUI.direction = if $scope.stateUI.direction == 'asc' then 'desc' else 'asc'
        # on an inner search card, dont re-search all the other tabs on toggle sort
        $scope.searchResources()

      toggleMenu: () ->
        document.getElementById("sort-by-dropdown").classList.toggle("hover")

      searchResources: (options = {}) ->
        $scope.stateUI.loading = true
        # search one tab if we are only sorting + the sorts are different for each tab
        # (except for query search, which searches everything)
        if $scope.useSavedSortStates && !$scope.searchOptions.query && !options.initializing
          tabsToSearch = [$scope.activeTab]
        # on initialize, search all tabs
        else
          tabsToSearch = $scope.tabs
        tabPromises = _.map tabsToSearch, (tab) ->

          # saved sort states only searches all three tabs on initialize
          # use sort nil on savedSortStates -- paginated service should determine default sort
          unless options.initializing && ($scope.useSavedSortStates || $scope.service.defaultOptions?.sort)
            sort = $scope.searchOptions.sort.direction[$scope.stateUI.direction]
          serviceOptions =
            tab: tab.slug
            sort: sort
            query: $scope.searchOptions.query
          $scope.getPageForTab(serviceOptions)

        $q.allSettled(tabPromises).then (responses) ->
          $scope.stateUI.loading = false
          if $scope.onLoad
            activeTab = $scope.onLoad(searchQuery:$scope.searchOptions.query)
            $scope.setActiveTab(activeTab.slug)

      # applies available orderings for UI
      applyCustomOrderings: ->
        if $scope.customOrderings
          # some orderings only show for certain tabs
          $scope.orderings = _.reject($scope.customOrderings, (customOrdering) ->
            # if a custom ordering has only_for_tab defined, exclude it if it doesnt match current tab
            (customOrdering.only_for_tab && customOrdering.only_for_tab != $scope.activeTab.slug)
          )

      hideResourceDropdown: ->
        $scope.$apply ->
          $scope.stateUI.showResourceDropdown = {}

      hideLtiCtas: -> #I hate myself
        if $scope.isLti
          for tab in $scope.tabs
            for cta in tab.ctas
              if cta.noLti
                cta.styling = cta.styling + " ng-hide"

      dropDownResourceAction: ($event, func, options) ->
        $event.preventDefault()
        $event.stopPropagation()
        func(options)

      setCurrentViewMode: (viewMode) ->
        localStorageService.set('searchableListTileOrListView', viewMode)
        $scope.stateUI.currentViewMode = viewMode

      resetSort: () ->
        if _.isEmpty($scope.searchOptions.query)
          ordering = $scope.orderings[0]
        else
          ordering = {
            name: "js.filters.relevance"
            direction:
              desc: ""
              asc: ""
            disabled: true
          }
        $scope.setOrdering(ordering)

      resetSortAndSearchResources: () ->
        $scope.resetSort()
        $scope.searchResources()

      # Partner people controller specific
      goToUserProfile: (userId) ->
        partner = myPartnersService.currentProductPartner
        isV4 = partner.attributes?['partner-settings']?['learn-version'] == 4
        if isV4
          reactNavigationService.navigateToUserProfile(userId)
        else
         $window.location = "/partners/#{partner.id}/users/#{userId}"

      courseRowClick: (courseId) ->
        $location.path Packs.iKnowRoutes.v3_learn_course_path(courseId);

    $scope.debouncedSearchResources = _.debounce($scope.resetSortAndSearchResources, 200)

    # 3 view modes on searchable list -- assignment tiles, large tiles, and list view
    if $scope.tileTemplate && $scope.resourceTemplate
      $scope.pageSize = 6
      # default to listView (not tileView)
      $scope.stateUI.currentViewMode = localStorageService.get('searchableListTileOrListView') || "listView"
    else if $scope.styles?.assignmentTileable
      $scope.pageSize = 12
      $scope.stateUI.currentViewMode = "assignmentTileView"
    else if $scope.styles?.courseTileable
      $scope.pageSize = 12
      $scope.stateUI.currentViewMode = "courseTileView"
    # list view is default template
    else
      $scope.pageSize = 15
      $scope.stateUI.currentViewMode = "listView"
    $scope.defaultOptions['page[size]'] = $scope.pageSize

    $scope.$on "searchableList:clearSelections", () ->
      $scope.clearSelections()

    $scope.$on "searchableList:markDirty", () ->
      $scope.clearSelections()
      $scope.stateUI.loading = true

    $scope.$on "searchableList:reloadResources", () ->
      $scope.clearSelections()
      $scope.stateUI.loading = true
      $timeout($scope.searchResources, 1000)

    $scope.$on "searchableList:setActiveTab", (event, params) ->
      $scope.setActiveTab(params.tab)

    angular.element("body").on "click", $scope.hideResourceDropdown

    $scope.$on '$destroy', ->
      angular.element("body").off "click", $scope.hideResourceDropdown

    $scope.activeTab ||= $scope.tabs[0]
    $scope.hideLtiCtas()

    # some searchable lists have sorts that apply to all tabs
    # but this allows us to save sort state *between* each tab
    $scope.useSavedSortStates = $scope.styles?.showSearchInsideList && $scope.tabs.length > 1

    $scope.applyCustomOrderings()
    $scope.resetSort()
    $scope.searchResources(initializing: true)
  ]

])
