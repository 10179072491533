ceregoCommon.factory('TransliterationDistractorsResource', ['$resource', ($resource) ->
  $resource "", {},
    create:
      method: 'POST'
      isArray: false
      url: APIRoutes.v2_facet_transliteration_distractors_path(":facet_id")
      params:
        facet_id: "@facet_id"
    save:
      method: 'PUT'
      isArray: false
      url: APIRoutes.v2_transliteration_distractor_path(":id")
      params:
        id: "@id"
    query:
      method: 'GET'
      isArray: false
      url: APIRoutes.v2_facet_transliteration_distractors_path(":facet_id")
      params:
        facet_id: "@facet_id"
    delete:
      method: 'DELETE'
      isArray: false
      url: APIRoutes.v2_transliteration_distractor_path(":id")
      params:
        id: "@id"
])
