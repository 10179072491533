ceregoCommon.factory('MyGroupsResource', ['$resource', ($resource) ->
  $resource "", {},
    query:
      method: 'GET'
      isArray: false
      url: APIRoutes.v2_my_groups_path()
    get:
      method: 'GET'
      isArray: false
      url: APIRoutes.v2_my_group_path(":id")
      params:
        id: "@id"
])
