ceregoCommon.service('V2GroupContextService', ['$rootScope', '$routeParams', '$q', 'DebugHelper', 'myPartnersService', 'GroupsResource', 'GroupsSetsResource', 'GroupsSeriesResource', 'MyGroupsResource', 'SiteNavigationService', ($rootScope, $routeParams, $q, DebugHelper, myPartnersService, GroupsResource, GroupsSetsResource, GroupsSeriesResource, MyGroupsResource, SiteNavigationService) ->
  $scope = $rootScope.$new()
  DebugHelper.register("V2GroupContextService", $scope)

  _.assignIn $scope,
    groups: []

    fetchModules: ->
      unless $scope.group.deferred
        $scope.group.deferred = $q.defer()
        setsLoaded = false
        seriesLoaded = false

        # Load sets & series in parallel
        # TODO: combine this into one call
        GroupsSetsResource.get
          group_id: $scope.group.id
        , (success) ->
          $scope.group.sets = success.response
          setsLoaded = true
          $scope.group.deferred.resolve($scope.group) if seriesLoaded
        , $scope.group.deferred.reject

        GroupsSeriesResource.get
          group_id: $scope.group.id
        , (success) ->
          $scope.group.series = success.response
          seriesLoaded = true
          $scope.group.deferred.resolve($scope.group) if setsLoaded
        , $scope.group.deferred.reject

      $scope.group.deferred.promise

    fetchGroups: ->
      unless $scope.deferred
        $scope.deferred = $q.defer()

        if !myPartnersService.currentProductPartner || myPartnersService.currentProductPartner.attributes['account-type'] == "plus"
          MyGroupsResource.query { with_partner_info: true }, (success) ->
            $scope.groups = _.filter success.response, (group) ->
              if $routeParams["groupIds[]"] && $routeParams["groupIds[]"].length > 0
                groupIds = [].concat($routeParams["groupIds[]"])
                _.includes(groupIds,String(group.id))
              else
                true
            $scope.deferred.resolve($scope.groups)
          , (error) ->
            if error.status == 401
              $scope.groups = []
              $scope.deferred.resolve($scope.groups)
            else
              $scope.deferred.reject(error)
        else
          GroupsResource.query
            partner_id: myPartnersService.currentProductPartner.id
          , (success) ->
            $scope.groups = success.response
            $scope.deferred.resolve($scope.groups)
          , $scope.deferred.reject              

      $scope.deferred.promise

    initialize: ->
      # TODO: combine these three into one call (user, partner, groups)
      myPartnersService.initialize().then () ->
        $scope.fetchGroups().then ->
          if myPartnersService.currentProductPartner
            $scope.managedGroups = _.filter $scope.groups, (g) ->
              g.permissions && g.permissions.editable && g.partner && g.partner.id == myPartnersService.currentProductPartner.id
          else
            $scope.managedGroups = []

          $scope.myGroups = _.filter(_.difference($scope.groups, $scope.managedGroups), is_admin: false)
          $scope.group = _.find($scope.groups, id: parseInt($routeParams.groupId || -1)) || $scope.groups[0]
          SiteNavigationService.setContext($scope.group, 'group')

          if $scope.group
            $scope.fetchModules()
          else
            $scope.group

  myPartnersService.$watch "currentProductPartner", (newVal, oldVal) ->
    $scope.partner = newVal

  $scope
])
