import Quill from 'quill';

const Parchment = Quill.import('parchment');
const Inline = Parchment.query('inline');
const Link = Quill.import('formats/link');
const Keyboard = Quill.import('modules/keyboard');

Inline.tagName = 'G';
Quill.register(Inline, true);

Link.sanitize = url => {
  // add https:// to links without them
  if (!url.match(/^[a-zA-Z]+:\/\//)) {
    url = 'https://' + url;
  }
  return url;
};

export const createWysiwygQuill = (containerElem, toolbarElem, placeholder) => {
  const options = {
    modules: {
      formula: false,
      toolbar: toolbarElem,
      keyboard: {
        bindings: {
          tab: false
        }
      }
    },
    theme: 'snow',
    placeholder
  };
  return new Quill(containerElem, options);
};

export const overrideEnterKey = (quill, handlerFunction) => {
  delete quill.keyboard.bindings[13];
  quill.keyboard.addBinding({
    key: Keyboard.keys.ENTER,
    handler: handlerFunction
  });
};
