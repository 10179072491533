ceregoCommon.service("ExternalLinkHelper", ['$rootScope', 'DebugHelper', ($rootScope, DebugHelper) ->
  $scope = $rootScope.$new()
  DebugHelper.register("ExternalLinkHelper", $scope)

  _.assignIn $scope,
    CREATE_INCOMPLETE_GUIDE: 'https://support.cerego.com/hc/en-us/articles/115005202623'
    CREATE_TRANSFER_GUIDE: 'https://support.cerego.com/hc/en-us/articles/115004689446-New-Create-Transfer-Guide'
    CREATE_V3_GUIDE: 'https://support.cerego.com/hc/en-us/articles/115005153386'
    HELP_CENTER: 'https://support.cerego.com'
    REFERRAL_LINK_A: 'https://www.cerego.com/referral/lp-edu1?utm_source=prod&utm_medium=cta-lp-refer&utm_campaign=edu-referral&utm_content=gandg'
    REFERRAL_LINK_B: 'https://www.cerego.com/referral/lp-edu2?utm_source=prod&utm_medium=cta-lp-refer&utm_campaign=edu-referral&utm_content=ig40'

    getGuideSection: (section, subtype) ->
      switch section
        when 'inform'
          '#inform'
        when 'retain'
          '#retain'
        when 'apply'
          '#apply'
        when 'question_and_answers'
          '#qa'
        when 'associations'
          '#flashcards'
        when 'vocabulary'
          '#vocab'
        when 'passages'
          '#passages'
        when 'regions'
          '#regions'
        when 'sequences'
          '#sequences'
        when 'patterns'
          '#patterns'
        when 'application_questions'
          '#scenarios'
        when 'instructional_contents'
          switch subtype
            when 'instructional_embed'
              '#media'
            when 'instructional_page'
              '#page'
            when 'instructional_document'
              '#pdf'
        else
          ''

  $scope
])
